<template>
	<div class="gameItemBanner__img">
		<img class="aniTemBg" src="/images/gameItem/P10/P10_bannerbg.png" />
		<img class="aniStar" src="/images/gameItem/P10/P10_banneritem1.png" />
		<img class="aniTem1" src="/images/gameItem/P10/P10_banneritem2.png" />
		<img class="aniTem2" src="/images/gameItem/P10/P10_banneritem3.png" />
		<img class="aniTemEffect1" src="/images/gameItem/P10/P10_banneritem6.png" />
		<img class="aniTemEffect2" src="/images/gameItem/P10/P10_banneritem5.png" />
		<img class="aniTemEffect3" src="/images/gameItem/P10/P10_banneritem6.png" />
		<!-- <img class="aniTemEffect4" src="/images/gameItem/P10/P10_banneritem4.png" /> -->
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["langConfig"]),
		selectedItem(){
			return this.langList[this.selectedIndex]
		}
	},
	data() {
		return {}
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesHeartbeat(1.05);
@include keyframesBgAni();
//animation動畫寫在這兒
.aniTemBg{
	width: 100%;
	@include bgAni;
}
// 右上光芒
.aniTemEffect1{
  position: absolute;
  top: 10%;
	left: 58%;
	width: 10%;
	opacity: 0;
	$animations: (
		ballLight 5s 6.5s infinite linear,
	);
	@include multiple_animations($animations);
}
// 左上光芒
.aniTemEffect2{
	position:absolute;
	top: 23%;
	left: 25%;
  width: 12%;
	opacity: 0;
	$animations: (
		lightEffect 3s 2.8s infinite linear,
	);
	@include multiple_animations($animations);
}
// 右下光芒
.aniTemEffect3{
	position:absolute;
	top: 33%;
	left: 70%;
  width: 13%;
	opacity: 0;
	$animations: (
		lightEffect 2.5s 2.5s infinite linear,
	);
	@include multiple_animations($animations);
}
// // 左下光芒
// .aniTemEffect4{
// 	position:absolute;
// 	top: 49%;
// 	left: 16%;
//   width: 15%;
// 	// opacity: 0;
// 	$animations: (
// 		rotateHeartBeat 3s 1.2s infinite linear,
// 	);
// 	// @include multiple_animations($animations);
// }
// 紅球
.aniTem1{
	position:absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	$animations: (
		customPop .6s .7s forwards,
    ballMove2 5s 1.3s infinite linear
	);
	@include multiple_animations($animations);
}
// 綠球
.aniTem2{
	position:absolute;
	top: 0;
	left:0;
	width: 100%;
	opacity: 0;
	$animations: (
		customPop .6s .7s forwards,
    ballMove1 5s 1.3s infinite linear
	);
	@include multiple_animations($animations);
}
// 星星
.aniStar{
  position: absolute;
  left: 23%;
	top: 6%;
	width: 68%;
	opacity: 0;
	$animations: (
		customPop .7s 1s forwards,
	);
	@include multiple_animations($animations);
}
@keyframes lightEffect {
  0%{
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  20%{
    opacity: 1;
    transform: scale(1) rotate(-45deg);
  }
  50%{
    opacity: 0;
    transform: scale(0) rotate(-90deg);
  }
}
@keyframes ballMove1 {
	0%{
		opacity: 1;
		transform: scale(1) translate(0,0);
	}
	50%{
		opacity: 1;
		transform: scale(1.05) translate(0.5%,-0.5%);
	}
	100%{
		opacity: 1;
		transform: scale(1) translate(0,0);
	}
}
@keyframes ballMove2 {
	0%{
		opacity: 1;
		transform: scale(1) translate(0,0);
	}
	50%{
		opacity: 1;
		transform: scale(1.05) translate(-0.5%,0.5%);
	}
	100%{
		opacity: 1;
		transform: scale(1) translate(0,0);
	}
}
@keyframes ballLight {
  0%{
    opacity: 0;
    transform: scale(0) rotate(0deg) translate(0,0);
  }
  10%{
    opacity: 1;
    transform: scale(1) rotate(-45deg);
  }
  20%{
    opacity: 0;
    transform: scale(0) rotate(-90deg) translate(0.5%,-0.5%);
  }
	100%{
		opacity: 0;
		transform: scale(0) rotate(-90deg) translate(0,0);
	}
}
@keyframes customPop{
  0%{
    opacity: 0;
    transform:scale(0);
  }
  60%{
    opacity: 1;
    transform:scale(1.2);
  }
  80%{
    transform:scale(1);
    opacity: 1;
  }
	100%{
    transform:scale(1);
    opacity: 1;
  }
}
</style>

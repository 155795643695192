<template>
	<div>
		<div class="side_bar none">
			{{ langConfig.Filter }}
			<icon name="filter" />
		</div>
		<h3>{{ langConfig.Theme }}</h3>
		<ul class="filter_list">
			<li v-for="(item, k) in typeList" :key="k">
				<div class="squared">
					<label :for="item.key">
						<input
							type="radio"
							:id="item.key"
							:value="item.key"
							:checked="selectedType === item.key"
							@change="filterType"
						/>
						<label class="fake"></label>
						<span>{{ langConfig[item.name] }}</span>
					</label>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	props: {
		typeList: Array,
		selectedType:String,
	},
	computed: {
		...mapState(["langConfig"]),
	},
	methods: {
		filterType(e) {
			console.log("e",e.target.value);
			const value = e.target.value
			this.$emit("filterType", value);
		},
	},
};
</script>

<style lang="scss" scoped></style>

<template>
    <div class="HomeMainGame mainBlock">
        <h2>{{langConfig.FeatureGame}}</h2>
        <ul>
            <li v-for="(item,i) in imgList" :key="i" @click="goList(item.key)">
                <img :src="`images/idx/featuredGame/hola.png`" class="hola">
                <img v-for="(img,m) in item.img" :key="m" :src="`images/idx/featuredGame/${item.title}/${img}.png`" :class="img">
                <img :src="`images/idx/featuredGame/${item.title}/${item.type}_text.png`" class="title">
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data(){
        return{
            imgList: [
                {
                    key: 'slot',
                    type: 'S',
                    title: 'Slot',
                    img: [
                        'S_board',
                        'S_coin',
                        'S_glow1',
                        'S_glow2',
                        'S_roller',
                        'S_star1',
                        'S_star2',
                        'S_star3',
                        'S_777',
                        'S_stick',
                    ],
                },
                {
                    key: 'mut',
                    type: 'M',
                    title: 'Multi',
                    img: [
                        'M_coin1',
                        'M_coin2',
                        'M_coin3',
                        'M_cup',
                        'M_dice1',
                        'M_dice2',
                        'M_dice3',
                        'M_dish',
                        'M_light',
                        'M_start1',
                        'M_start2',
                        'M_start3',
                    ],
                },
                {
                    key: 'cas',
                    type: 'C',
                    title: 'casual',
                    img: ['C_card', 'c_penguin', 'C_star1', 'C_star2', 'C_star3', 'C_star4'],
                },
            ]
        }
    },
    methods:{
        goList(key){
            this.$router.push(`/game?id=${key}`)
        }
    },
    computed:{
		...mapState(["langConfig"]),
    }
}
</script>

<style lang="scss">
@import "@/scss/rwd.scss";
@mixin linearBG($color1,$color2) {
    background: linear-gradient(to bottom, $color1, $color2);
}
@mixin keyframesAnima($name,$tran1:0,$tran2:0,$tran3:0,$tran4:0,$scale1:1,$scale2:1,$rotate1:0,$rotate2:0){
    @keyframes #{$name}{
        0% {
            transform:translate( $tran1, $tran2 ) scale($scale1) rotate($rotate1);
        }
        50% {
            transform:translate( $tran3, $tran4 ) scale($scale2) rotate($rotate2);
        }
        100% {
            transform:translate( $tran1, $tran2 ) scale($scale1) rotate($rotate1);
        }
    }
}
.HomeMainGame{
    ul{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include gameItemPic-width(){
            flex-wrap: wrap;
        }
        li{
            position: relative;
            width: 22%;
            cursor: pointer;
            min-width: 280px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 170px;
            padding-bottom: .8%;
            margin: 10px auto;
            @include gameItemPic-width(){
                justify-content: center;
                margin: 6% auto;
            }
            &:first-child {
                @include linearBG(#6600ff,#cc00ff);
            }
            &:nth-child(2){
                @include linearBG(#126196,#00ffc8);
            }
            &:nth-child(3){
                @include linearBG(#db3038,#ff88eb);
            }
            img{
                position: absolute;
                max-width: 100%;
            }
            .hola{
                width: 100%;
                max-width: inherit;
                margin: auto;
                top: 30px;
                bottom: 0;
            }
            .title{
                z-index: 9;
            }
            .S_777 {
                top: -8%;
                left: 15%;
            }
            .S_board {
                bottom: 2%;
                right: 15%;
            }
            .S_stick {
                top: -18%;
                right: 13%;
                animation: S_stick 6s infinite ease-in;
            }
            .S_roller {
                top: 0;
                right: 12%;
            }
            .S_glow1 {
                bottom: 16%;
                right: 20%;
                animation: S_glow 4s infinite ease-out;
            }
            .S_glow2 {
                bottom: 10%;
                right: 27%;
                animation: S_glow 4s infinite 2s ease-out;
            }
            .S_coin {
                top: 5%;
                animation: S_coin 4s infinite ease-out;
            }
            .S_star1 {
                bottom: 8%;
                left: 13%;
                animation: S_star 3s infinite ease-out;
            }
            .S_star3 {
                top: -15%;
                left: 20%;
                animation: S_star 4s infinite ease-out;
            }
            .S_star2 {
                bottom: 20%;
                right: 15%;
                animation: S_star 2s infinite ease-out;
            }
            @keyframes S_stick {
                0% { transform: rotate(0); }
                15% { transform: rotate(-160deg); }
                80% { transform: rotate(0); }
            }
            @keyframes S_coin {
                0% { transform: scale(1); }
                50% { transform: scale(1.3); }
                100% { transform: scale(1); }
            }
            @keyframes S_star {
                0% { transform: rotate(0); }
                100% { transform: rotate(360deg); }
            }
            @keyframes S_glow {
                0% { opacity: 0; }
                50% { opacity: 1; }
                100% { opacity: 0; }
            }
            //M
            .M_cup{
                animation:M_cup 5s infinite ease-out;
                @include keyframesAnima(M_cup, -30px, -40px,-25px, -30px,1,.9,-15deg);
                z-index: 5;
                zoom: 1.2;
            }
            .M_dish{
                animation:M_dish 5s infinite ease-out;
                @include keyframesAnima(M_dish, 20px, 15px,0,0,1,.8);
            }
            .M_dice1{
                z-index: 1;
                animation:M_dice1 5s infinite ease-in;
                @include keyframesAnima(M_dice1, 70px, -90px,40px, -60px,1,.7);
            }
            .M_dice2{
                z-index: 2;
                animation:M_dice2 5s infinite ease-in;
                @include keyframesAnima(M_dice2, 90px, 0px,50px, -10px,1,.6);
            }
            .M_dice3{
                z-index: 1;
                animation:M_dice3 5s infinite ease-out;
                @include keyframesAnima(M_dice3, -110px, -10px,-70px, -5px,1,.6);
            }
            .M_start1{
                z-index: 1;
                top: 25%;
                right: 25%;
                animation:M_start 4s infinite ease-out;
            }
            .M_start2{
                z-index: 1;
                top: 42%;
                right: 41%;
                animation:M_start 4s infinite ease-out;
            }
            .M_start3{
                top: 53%;
                right: 32%;
                z-index: 1;
                animation:M_start 2s infinite ease-out;
            }
            @keyframes M_start {
                0% {
                    transform:rotate(360deg);
                    }
                100% {
                    transform:rotate(0);
                }
            }
            .M_coin1{
                top: 38%;
                right: 1%;
                z-index: 1;
                animation:M_coin 4s infinite ease-out;
            }
            .M_coin2{
                bottom:-3%;
                left: 1%;
                z-index: 2;
                animation:M_coin 3s infinite ease-out;
            }
            .M_coin3{
                top: -11%;
                left: 11%;
                z-index: 1;
                animation:M_coin 4s infinite ease-out;
            }
            @keyframes M_coin {
                0% {
                    transform: translateY(0)
                }
                50% {
                    transform: translateY(10px)
                }
                100% {
                    transform: translateY(0)
                }
            }
            //C
            .c_penguin{
                animation:c_penguin2 2s infinite ease-out;
                @include gameItemPic-width(){
                    animation:c_penguin 2s infinite ease-out;
                }
            }
            @keyframes c_penguin2 {
                0% {
                    transform:translate( 0, -17px );
                }
                50% {
                    transform:translate( 2px, 0 ) scale3d(.8,.8,.8);
                }
                80% {
                   transform:translate(0, -17px );
                }
                100% {
                   transform:translate(0, -17px );
                }
            }
            @keyframes c_penguin {
                0% {
                    transform:translate( 0, -25px );
                }
                50% {
                    transform:translate( 8px, 0 ) scale3d(.8,.8,.8);
                }
                80% {
                   transform:translate(0, -25px );
                }
                100% {
                   transform:translate(0, -25px );
                }
            }
            .C_card{
                bottom: -8%;
            }
            .C_star2{
                bottom: 29%;
                right: 5%;
                animation:M_coin 4s 1s infinite ease-out;
            }
            .C_star1{
                top:1%;
                right: 3%;
                animation:M_coin 3s 1s infinite ease-out;
            }
            .C_star3{
                top:10%;
                left: 6%;
                animation:M_coin 5s infinite ease-out;
            }
            .C_star4{
                bottom:20%;
                left: 9%;
                animation:M_coin 3s infinite ease-out;
            }
        }
    }
}
</style>
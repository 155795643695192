<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/TEX/TEX_bannerbg.png" />
        <img class="aniTemitem5" src="/images/gameItem/TEX/TEX_banneritem5.png" />
        <img class="aniTemitem4" src="/images/gameItem/TEX/TEX_banneritem4.png" />
        <img class="aniTemitem3" src="/images/gameItem/TEX/TEX_banneritem3.png" />
        <img class="aniTemitem2" src="/images/gameItem/TEX/TEX_banneritem2.png" />
        <img class="aniTemitem6" src="/images/gameItem/TEX/TEX_banneritem6.png" />
        <img class="aniTemitem7" src="/images/gameItem/TEX/TEX_banneritem7.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["langConfig"]),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
.aniTemBg{
    width: 100%;
    @include bgAni
}
.aniTemdealer{
    position:absolute;
    width: 45%;
    top:50px;
    left: 35%;
    @include bgAni
}
.aniTemitem2{
    position:absolute;
    left: 10%;
    top: 5%;
    opacity: 0;
    animation: bgLineLight 6s 1.5s alternate infinite ;
}
.aniTemitem3{
    position:absolute;
    right: 21%;
    top: 23%;
    width: 10%;
    opacity: 0;
    animation: bgLineLight 2s 1.5s alternate infinite ;
}
.aniTemitem4{
    position:absolute;
    right: 20.5%;
    top: 33%;
    width: 3.5%;
    opacity: 0;
    animation: bgLineLight 1s 1.5s alternate infinite ;
}
.aniTemitem5{
    position:absolute;
    right: 14.5%;
    top: 32.3%;
    width: 5.3%;
    opacity: 0;
    animation: bgLineLight 1.1s 1.5s alternate infinite ;
}
.aniTemitem6{
    position:absolute;
    left: 0;
    top: 0;
    width: 90%;
    transform-origin: top left;
    opacity: 0;
    @include keyframesBgAni;
    $animations: (
        bgPop 0.5s 0.3s forwards,
        showLight 1s 1s infinite linear alternate-reverse 
    );
    @include multiple_animations($animations);
}
.aniTemitem7{
    position: absolute;
    right: 22%;
    bottom: 5%;
    width: 42%;
    opacity: 0;
    opacity: 0;
    @include bgAni(0.5s,0.6s);
}
@keyframes bgLineLight{
    0%{
        opacity: 0;
    }
    10%{
        opacity: 0.8;
    }
    30%{
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    100%{
        opacity: 0.8;
    }
}
@keyframes showLight{
    0%{
        opacity: 1;
        transform:rotate(-10deg);
    }
    30%{
        opacity: 0.2;
    }
    70%{
        opacity: 1;
        transform:rotate(-3deg);
    }
    85%{
        opacity: 0.1;
    }
    100%{
        opacity: 1;
        transform:rotate(0deg);
    }
}

</style>

<template>
    <div>
        <section class="priv_banner"></section>
        <section class="priv_main">
            <div class="paragraph">
                <h2 class="title">{{langConfig.AMLPolicyTitle}}</h2>
                <p>Last updated: 14 November 2023</p>
                <div class="content">
                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_1}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_1}} 
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com.</a>
                    </p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_2_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_2_2}}
                    </p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_3_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_3_2}}
                    </p>
                    <p>
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_4}}
                    </p>
                    <p>
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_5}}
                    </p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_6_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_1_listItem_6_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_1_listItem_7}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_1_listItem_8}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_1_listItem_9}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_1_listItem_10}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_2_listItem_1}}</p>
                    <ul>
                        <li>{{langConfig.AntiMoneyLaunderingContent_2_listItem_1_1}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_2_listItem_1_2}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_2_listItem_1_3}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_2_listItem_1_4}}</li>
                    </ul>
                    <p>{{langConfig.AntiMoneyLaunderingContent_2_listItem_2}}</p>

                    <p class="zoneTitle">
                        {{langConfig.AntiMoneyLaunderingContent_3}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                    </p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_3_listItem_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_3_listItem_1_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_3_listItem_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_3_listItem_3}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_4}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_4_listItem_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_4_listItem_1_2}}
                    </p>

                    <p class="zoneTitle" style="color:black">{{langConfig.AntiMoneyLaunderingContent_5}}</p>
                    <p class="groupTitle" style="font-style: italic;">{{langConfig.AntiMoneyLaunderingContent_5_listItem_1}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_5_listItem_1_1}}</p>
                    <p class="groupTitle" style="font-style: italic;">{{langConfig.AntiMoneyLaunderingContent_5_listItem_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_5_listItem_2_1}}</p>
                    <p class="groupTitle" style="font-style: italic;">{{langConfig.AntiMoneyLaunderingContent_5_listItem_3}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_5_listItem_3_1}}</p>
                    

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_6}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_6_listItem_1}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_6_listItem_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_6_listItem_3}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_6_listItem_4}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_6_listItem_5}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_7}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_7_listItem_1}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_7_listItem_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_7_listItem_3}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_7_listItem_4}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_7_listItem_5}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_8}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_8_listItem_1}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_8_listItem_2}}</p>
                    <ul>
                        <li>{{langConfig.AntiMoneyLaunderingContent_8_listItem_2_1}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_8_listItem_2_2}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_8_listItem_2_3}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_8_listItem_2_4}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_8_listItem_2_5}}</li>
                    </ul>
                    <p>{{langConfig.AntiMoneyLaunderingContent_8_listItem_3}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_8_listItem_4}}</p>
                    <p>
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_8_listItem_5}}
                    </p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_9}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_9_listItem_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_9_listItem_1_2}}
                    </p>
                    <ul>
                        <li>{{langConfig.AntiMoneyLaunderingContent_9_listItem_1_1_1}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_9_listItem_1_1_2}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_9_listItem_1_1_3}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_9_listItem_1_1_4}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_9_listItem_1_1_5}}</li>
                    </ul>
                    <p>{{langConfig.AntiMoneyLaunderingContent_9_listItem_2}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_10}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_10_listItem_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_10_listItem_1_2}}
                    </p>
                    <p class="groupTitle">{{langConfig.AntiMoneyLaunderingContent_10_listItem_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_10_listItem_2_1}}</p>
                    <p class="groupTitle">{{langConfig.AntiMoneyLaunderingContent_10_listItem_3}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_10_listItem_3_1}}</p>
                    <p class="groupTitle">{{langConfig.AntiMoneyLaunderingContent_10_listItem_4}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_10_listItem_4_1}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_11}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_11_listItem_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com.</a>
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_11_listItem_2_1}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_11_listItem_3_1}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_11_listItem_4_1}}</p>
                    <p style="font-style: italic;" class="groupTitle">{{langConfig.AntiMoneyLaunderingContent_11_listItem_5}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_11_listItem_5_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_11_listItem_5_1_2}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_11_listItem_5_1_3}}
                    </p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_11_listItem_6_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_11_listItem_6_1_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_11_listItem_7_1}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_12}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_0}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_1}}</p>
                    <p>
                       <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_1_1_1}}
                       <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_1_1_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2}}</p>
                    <p>
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_2_1}}
                    </p>
                    <ul>
                        <li>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2_1_1}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2_1_2}}</li>
                    </ul>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_2_2_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_2_2_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2_3}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2_4}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2_5}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2_6}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_2_7}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_2_8_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_2_8_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_3}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_3_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_12_listItem_3_1_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_12_listItem_3_2}}</p>

                    <p class="zoneTitle">
                        {{langConfig.AntiMoneyLaunderingContent_13}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">http://www.ubetoday.com</a>
                    </p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_13_listItem_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">http://www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_13_listItem_1_2}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_13_listItem_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_13_listItem_3}}</p>
                    <ul>
                        <li>{{langConfig.AntiMoneyLaunderingContent_13_listItem_3_1}}</li>
                        <li>{{langConfig.AntiMoneyLaunderingContent_13_listItem_3_2}}</li>
                    </ul>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_14}}</p>
                    <p>
                        {{langConfig.AntiMoneyLaunderingContent_14_listItem_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com.</a>
                    </p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_15}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_15_listItem_1}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_15_listItem_2}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_15_listItem_3}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_16}}</p>
                    <p>
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_16_listItem_1}}
                    </p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_16_listItem_2}}</p>
                    <ul>
                        <li style="list-style: none;">{{langConfig.AntiMoneyLaunderingContent_16_listItem_2_1}}</li>
                        <li style="list-style: none;">{{langConfig.AntiMoneyLaunderingContent_16_listItem_2_2}}</li>
                    </ul>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_17}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_17_listItem_1}}</p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_18}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_18_listItem_1}}</p>
                    <p>
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.AntiMoneyLaunderingContent_18_listItem_2}}
                    </p>

                    <p class="zoneTitle">{{langConfig.AntiMoneyLaunderingContent_19}}</p>
                    <p>{{langConfig.AntiMoneyLaunderingContent_19_listItem_1}}</p>
                    <ul>
                        <li>
                            {{langConfig.AntiMoneyLaunderingContent_19_listItem_1_1}}
                            <a alt="" href="mailto:cherryfun@cherry.fun" target="_blank">cherryfun@cherry.fun</a>
                        </li>
                    </ul>
                    <p>{{langConfig.AntiMoneyLaunderingContent_19_listItem_2}}</p>
                    <ul>
                        <li>
                            {{langConfig.AntiMoneyLaunderingContent_19_listItem_2_1}}
                            <a alt="" href="mailto:cherryfun@cherry.fun" target="_blank">cherryfun@cherry.fun</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.priv_banner{
	height: 465px;
	background-image: url("/images/privacy.png");
    background-size: cover;
    background-position: center center;
}
.priv_main{
    width: 70%;
    margin: 30px auto;
    max-width: 1400px;
    @include pads-width{
        width: 85%;
    }
    .paragraph{
        padding: 15px 0;
    }

    .title{
        font-size: 34px;
        color: #0b0ee0;
        margin-bottom: 15px;
        margin-top: 5%;
    }

    h1 {
        text-align: center;
    }
    .content{
        display: flex;
        flex-direction: column;
    }
    p {
        margin: 15px 10px 0 0;
        color: #031029;
        font-size: 18px;
        font-weight: 1000;
        line-height: 40px;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin: 20px 15px 0 0;
        }
        @include phone-width(){
            font-size: 18px;
            margin: 20px 10px 0 0;
            line-height: 30px;
        }

        a {
           color: #031029;
           font-family: auto;
        }
    
    }

    .zoneTitle{
       font-size: 24px;
       color: #0b0ee0;

       a {
        color: #0b0ee0;
       }
    }
    .groupTitle{
       font-size: 20px;
       color: #5457f5;
       
    }
    li {
        font-size: 18px;
        font-weight: 800;
        line-height: 40px;
        margin-left: 40px;
        list-style: disc;
        font-family: auto;
        color: black;
       
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin-left: 20px;
        }
        @include phone-width(){
            font-size: 18px;
            width: 90%;
            margin: 10px 0 10px 20px;
            line-height: 30px;
        }
        a {
            color: black;
        }
    }
}
</style>
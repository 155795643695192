<template>
  <div id="app">
    <DefaultTemplate />
    <loading v-if='showLoading'  />
    <div class="back"  @click="goTop" @touchstart.prevent="goTop" >
      <Icon :name="'up'"/>
      <span>TOP</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DefaultTemplate from '@/components/DefaultTemplate'
import loading from '@/components/Loading'
import Icon from '@/components/Icon.vue';
export default {
  created(){
    this.$store.commit('updateGameList',this.$gameList)
    this.$store.dispatch("getLanguage");
  },
  computed: {
    ...mapState(["gameList"]),
    loadResource(){
      let arr = []
      let idx = this.gameList.map(vo=> `/images/idx/banner/${vo.id}.jpg`)
      let icon = this.gameList.map(vo=> `/images/gameIcon/${vo.id}.png`)
      let lobby = this.gameList.map(vo=> `/images/gameLobby/${vo.id}.jpg`)
      arr = [...idx, ...icon, ...lobby]
      return arr
    },
    loadPCBanner(){
      return this.gameList.map(vo=> `/images/gameBanner/${vo.id}.jpg`)
    },
    loadMobileBanner(){
      return this.gameList.map(vo=> `/images/gameBanner/M/${vo.id}.jpg`)
    },
    loadList(){
      let banner = this.clientWidth > 760 ? this.loadPCBanner : this.loadMobileBanner
      return [...this.loadResource, ...banner]
    }
  },
  data(){
    return{ 
      showLoading:true,
      loadCount:0,
      clientWidth:0
    }
  },
  components:{
    DefaultTemplate,
    loading,
    Icon
  },
 
  methods:{
    preloadImage(){
      this.loadList.forEach(url=>{
        const img = new Image()
        img.addEventListener('load',this.loadDone)
        img.addEventListener('error',this.loadDone)
        img.src = url
      })
    },
    goTop(){
			let backBtn = document.querySelector('.back')
      backBtn.classList.add('active')
			window.scrollTo({top: 0, behavior: 'smooth'});
		},
    loadDone(){
      this.loadCount++
      if(this.loadCount>4){
        this.showLoading = false
      }
    }
  },
  mounted(){
    this.clientWidth=document.body.clientWidth
    window.onresize=()=>{
      this.clientWidth=document.body.clientWidth
    }
    this.preloadImage();
    window.addEventListener('scroll',() => {
			let backBtn = document.querySelector('.back')
			if(backBtn){
				if(window.scrollY/document.body.offsetHeight > 0.1){
          backBtn.classList.add('fadeIn')
				}else{
          backBtn.classList.remove('fadeIn')
          backBtn.classList.remove('active')
				}
			}
		})
  },
  beforeCreate () {
    if(localStorage.getItem('cherryFun_lang')=="en"){
      document.title = 'Cherry Fun - Have Cherry Have Fun'
    }else{
       document.title = '伽立方 - Have Cherry Have Fun'
    }
  },
 
}
</script>
<style lang="scss">
@import "./font/font.css";
@import "./scss/common.scss";
@import "./scss/anicss.scss";
@import "@/scss/rwd.scss";
#preloadedImages {
	width: 0px;
	height: 0px;
	display: none;
}

.back{
		position: fixed;
    cursor: pointer;
		bottom: 50px;
		right: 0;
		width: 94px;
		height: 84px;
		line-height: 50px;
		text-align: center;
    z-index: 13;
    opacity: 0.9;
    background-color:rgba(0,0,0,.2);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    color: #fff;
    transform: translateX(100%);
    transition: transform .3s ease,background-color .3s ease;
    i{
      position: absolute;
      top: 0%;
      left: 42%;
      font-size: 32px;
    }
    span{
      position: absolute;
      bottom: 7%;
      left: 42%;
      font-size: 30px;
      font-family: 'DINCondensed';
    }
    &.fadeIn{
      transform: translateX(0);
    }
    &:hover{
      background-color: #cf1e2c;
    }
    &.active{
      background-color: #cf1e2c;
    }
    @include phone-width{
      width: 60px;
      height: 55px;
      i{
        top: -20%;
        left: 40%;
        font-size: 20px;
      }
      span{
        bottom: -10%;
        left:38%;
        font-size: 22px;
      }
    }
}
</style>
<template>
  <div class="enthusAni" :class="{'aniPlay':clientWidth<=768 || playAniItem==='Responsibility'}">
    <img src="images/comAbout/respon/respon_shadow.png" class="aniShadow" alt="">
    <img src="images/comAbout/respon/respon_main.png" class="aniMain" alt="">
    <img src="images/comAbout/respon/respon_ps5.png" class="aniPs5" alt="">
    <img src="images/comAbout/respon/respon_light.png" class="aniLight" alt="">
    <img src="images/comAbout/respon/respon_tv.png" class="aniScreen" alt="">
    <img src="images/comAbout/respon/respon_music_1.png" class="aniMusic1" alt="">
    <img src="images/comAbout/respon/respon_music_2.png" class="aniMusic2" alt="">
  </div>
</template>
<script>
export default {
  props:['playAniItem','clientWidth'],
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "../scss/anicss.scss";
@include keyframesBgAni(1.1);
@include keyframesUpDown(5px);
// 基本定位
img{
  position: absolute;
  // width: 120%;
  left: -12%;
  top: -8%;
  @include pads-width{
    top: 0;
  }
}
.aniLight{
  transform: rotate(10deg);
  opacity: 0;
}
.aniMusic1{
  opacity: 0;
  transform-origin: 70% 80%;
}
.aniMusic2{
  opacity: 0;
  transform-origin: 80% 70%;

}
// 動畫樣式
.aniPlay{
  .aniMain{ // 企鵝
    $animations:(
      penguinJump .8s infinite,
    );
    @include multiple_animations($animations);
  }
  .aniLight{ // 光
    $animations:(
      flashing 3s infinite linear,
    );
    @include multiple_animations($animations); 
  }
  .aniMusic1{ // 音符1
    $animations:(
      bgPop .6s forwards,
      music 1.8s .6s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniMusic2{ // 音符2
    $animations:(
      bgPop .6s forwards,
      music 1.3s .6s infinite linear,
    );
    @include multiple_animations($animations);
  }

}
@keyframes penguinJump {
  0%{
    transform: translateY(0);
  }
  40%{
    transform: translateY(-10px);
  }
  60%{
    transform: translateY(0);
  }
}

@keyframes flashing{
  0% { 	opacity: 0.5;}
  90% {opacity: 1;}
  94% {opacity: 0;}
  96% {opacity: 1;}
}

@keyframes music{
  0%{
    transform: translateY(0);
  }
  20%{
    transform: translateY(-5px);
  }
  30%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(-10px);
  }
  70%{
    transform: translateY(2px);
  }
  90%{
    transform: translateY(0);
  }
}
</style>
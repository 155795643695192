import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Icon from './components/Icon.vue'
import VueSplide from '@splidejs/vue-splide';
import VueCarousel from "vue-carousel";
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
Vue.config.productionTip = false
Vue.use( VueSplide );
Vue.use(VueCarousel);

Vue.component('Icon', Icon)

const getGameList = () => {
  fetch(`/gameList.json`)
  .then(response => {
      return response.json();
  })
  .then(data => {
    Vue.prototype.$gameList = data
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  });
}

getGameList()



<template>
<div class="comAboutEnthus">
    <div v-show="this.clientWidth>768">
        <ul class="enthusList">
            <li v-for="(item,i) in enthusList" :key="i" :data-index="i" @mouseenter="showContent($event,i,item.name)" @mouseleave="hideContent($event,i)">
                <component :is="`Enthus${item.name}Animation`" :playAniItem="playAniItem" :clientWidth="clientWidth"/>
                <h4>{{langConfig[item.name]}}</h4>
            </li>
        </ul>
        <div class="hoverContent" v-if="isShow">
            <div class="container">
                <h4>{{langConfig[nowTitle]}}</h4>
                <p>{{langConfig[nowContent]}}</p>
            </div>
        </div>
    </div>
    <div v-show="this.clientWidth<=768">
        <splide :options="options">
            <splide-slide v-for="(item,i) in enthusList" :key="i" :data-index="i">
                <div class="mobileMain" v-if="!item.showContent">
                    <component :is="`Enthus${item.name}Animation`" :playAniItem="playAniItem" :clientWidth="clientWidth"/>
                    <h4>{{langConfig[item.name]}}</h4>
                </div>
                <div class="mobileContent" v-else :style="`background-image:url('/images/comAbout/${item.img}/${item.img}.png')`">
                    <h4>{{langConfig[`${item.name}Title`]}}</h4>
                    <p>{{langConfig[item.content]}}</p>
                </div>
            </splide-slide>
        </splide>
    </div>
</div>
</template>
<script>
import EnthusTeamAnimation from '@/components/EnthusTeamAnimation.vue'
import EnthusMotivationAnimation from '@/components/EnthusMotivationAnimation.vue'
import EnthusKnowledgeAnimation from '@/components/EnthusKnowledgeAnimation.vue'
import EnthusResponsibilityAnimation from '@/components/EnthusResponsibilityAnimation.vue'
import EnthusLoveAnimation from '@/components/EnthusLoveAnimation.vue'
import { mapState } from "vuex";
export default {
    props:['clientWidth'],
    components:{
        EnthusTeamAnimation,
        EnthusMotivationAnimation,
        EnthusKnowledgeAnimation,
        EnthusResponsibilityAnimation,
        EnthusLoveAnimation
    },
    data() {
        return {
            enthusList:[
                {name:'Team',img:'team',content:'TeamContent',showContent:false},
                {name:'Motivation',img:'motivation',content:'MotivationContent',showContent:false},
                {name:'Knowledge',img:'knowledge',content:'KnowledgeContent',showContent:false},
                {name:'Responsibility',img:'respon',content:'ResponsibilityContent',showContent:false},
                {name:'Love',img:'love',content:'LoveContent',showContent:false}
            ],
            isShow:false,
            nowTitle:'',
            nowContent:'',
            playAniItem:''
        }
    },
    computed:{
        ...mapState(["langConfig"]),
        options(){
            return {
                focus:'center',
                type: 'slide',
                padding:{
                    top:'1rem',
                    bottom:'1rem',
                    left:'5rem',
                    right:'5rem'
                },
                gap:'3rem',
                perPage:1,
                fixedWidth: '60%',
                fixedHeight:'320px',
                arrows:false,
                classes:{
                    page: 'splide__pagination__page page'
                },
                breakpoints:{
                    375:{
                        padding:{
                            left:'110px'
                        }
                    },
                    320: {
                        padding:{
                            left:'135px'
                        }
                    }
                }
            }
        }
    },
    methods: {
        showContent(e,index,name){
            this.isShow = true
            this.playAniItem = name
            this.nowTitle = `${this.enthusList[index].name}Title`
            this.nowContent = this.enthusList[index].content
            let list = e.target.parentNode.children
            list.forEach(item => {
                if(Number(item.dataset.index) !== index){
                    item.classList.add('hoverEffect')
                }
            })
        },
        hideContent(e,index){
            this.isShow = false
            this.playAniItem = ''
            this.nowTitle = ''
            this.nowContent = ''
            let list = e.target.parentNode.children
            list.forEach(item => {
                if(Number(item.dataset.index) !== index){
                    item.classList.remove('hoverEffect')
                }
            })
        },
    },
    mounted(){
        this.$nextTick(() => {
            const dom = document.getElementsByClassName('splide__slide')
            dom.forEach(item => {
                item.addEventListener('click', (e) => {
                    let clickTarget = e.target.dataset.index
                    this.enthusList[clickTarget].showContent = !this.enthusList[clickTarget].showContent
                })
            })
        })
    }
}
</script>
<style lang="scss" >
@import "@/scss/rwd.scss";
.hoverEffect{
    opacity: 0.5;
}
.comAboutEnthus{
    background-color: #ddd;
    padding: 100px 0 50px 0;
    margin: 15px 0;
    position: relative;
    @include pads-width{
        background-color: transparent;
        margin: 0 auto;
        padding: 0;
    }
    h3{
        text-align: center;
        font-size: 28px;
        color: #333333;
        opacity: 0.5;
        margin-bottom: 70px;
        @include pads-width(){
            margin-bottom: 0;
        }
    }
    .enthusList{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        li{
            *{
                pointer-events: none;
            }
            width: 195px;
            height: 256px;
            margin: 0 3%;
            margin-bottom: 60px;
            background-color: #fff;
            border-radius: 10px;
            position: relative;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            transition: 0.1s;
            &:hover{
				transform: translateY(-5px);
            }
            h4{
                position: absolute;
                width: 100%;
                bottom: 10px;
                font-size: 20px;
                color: #333333;
                opacity: 0.5;
                text-align: center;
            }
        }
    }
    .hoverContent{
        width: 100%;
        min-height: 150px;
        background-color: rgba(0,0,0,.5);
        color: #fff;
        position: absolute;
        bottom: 0;
        backdrop-filter: blur(5px);
        pointer-events: none; // 不會影響hover
        padding: 15px 0;
        .container{
            width: 85%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h4{
                font-size: 20px;
                margin: 10px 0;
            }
            p{
                margin: 10px 0;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
    .splide{
        padding: 0 0 40px 0;
        &__track{
            padding: 10px 0;
        }
        .splide__list{
            .splide__slide{
                *{
                    pointer-events: none;
                }
                cursor: pointer;
                background-color: #fff;
                // width: 195px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 256px;
                border-radius: 10px;
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
                h4{
                    margin: 5px 0;
                    font-size: 20px;
                    color: rgba(51, 51, 51, 0.5);
                    text-align: center;
                }
                .mobileMain{
                    width: 100%;
                }
                .mobileContent{
                    padding: 8px 6%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.4);
                    background-repeat: no-repeat;
                    background-position: 80px 130px;
                    background-size: 230px;
                    background-blend-mode: multiply;
                    border-radius: 10px;
                    opacity: 0.6;
                    h4{
                        text-align: left;
                        color: #fff;
                    }
                    p{
                        font-size:16px;
                        padding: 5px 0;
                        color: #fff;
                        line-height: 28px;
                    }
                }
            }
        }
        .splide__pagination__page.page{
            border-radius: 50%;
            height: 10px;
            width: 10px;
            &::before{
                content: '';
                position: absolute;
                top: -2px;
                left: -2px;
                border-radius: 50%;
                width: 12px;
                height: 12px;
                border: 1px solid transparent;
            }
            &.is-active{
                background: #D00D1E;
                position: relative;
                &::before{
                    border-color: #D00D1E;
                }
            }
        }
    }
}
</style>
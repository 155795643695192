<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/VN/VN_bannerbg.png" />
        <img class="aniTem3" src="/images/gameItem/VN/VN_banneritem3.png" />
        <img class="aniTem1" src="/images/gameItem/VN/VN_banneritem1.png" />
        <img class="aniTem5" src="/images/gameItem/VN/VN_banneritem5.png" />
        <img class="aniTem5_1" src="/images/gameItem/VN/VN_banneritem5.png" />
        <img class="aniTem5_2" src="/images/gameItem/VN/VN_banneritem5.png" />
        <img class="aniTem5_3" src="/images/gameItem/VN/VN_banneritem5.png" />
        <img class="aniTem5_4" src="/images/gameItem/VN/VN_banneritem5.png" />
        <img class="aniTem5_5" src="/images/gameItem/VN/VN_banneritem5.png" />
        <img class="aniTem2" src="/images/gameItem/VN/VN_banneritem2.png" />
        <img class="aniTem4" src="/images/gameItem/VN/VN_banneritem4.png" />
        <img class="aniTem4_1" src="/images/gameItem/VN/VN_banneritem4.png" />
        <img class="aniTem4_2" src="/images/gameItem/VN/VN_banneritem4.png" />
        <img class="aniTem6" src="/images/gameItem/VN/VN_banneritem6.png" />
        <img class="aniTem7" src="/images/gameItem/VN/VN_banneritem6.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["langConfig"]),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesUpDown(5%);
//animation動畫寫在這兒
.aniTemBg{
    width: 100%;
    @include bgAni
}
.aniTem1{
    position:absolute;
    left: 33%;
    width: 43%;
    top: 48%;
    opacity: 0;
    $animation: (
        tableUp 2s 0.8s forwards,
        upDown 3.2s 2.5s linear infinite alternate-reverse
    );
    @include multiple_animations($animation);
}
.aniTem2{
    position:absolute;
    left: 29%;
    width: 43%;
    top: 15%;
    opacity: 0;
    $animation: (
        ballDown 2s 0.8s forwards,
        ballScale 8s 2.5s linear infinite alternate-reverse
    );
    @include multiple_animations($animation);
}
.aniTem3{
    position:absolute;
    width: 100%;
    left: 0%;
    opacity: 0;
    $animation: (
        bgLight 2s 0.8s forwards,
    );
    @include multiple_animations($animation);
}
.aniTem4{
    position:absolute;
    left: 47%;
    top: 37%;
    opacity: 0;
    $animation: (
        light 10s 3s linear infinite,
    );
    @include multiple_animations($animation);
}
.aniTem4_1{
    position:absolute;
    left: 37%;
    top: 58%;
    opacity: 0;
    width: 40px;
    $animation: (
        light 15s 3s linear infinite,
    );
    @include multiple_animations($animation);
}
.aniTem4_2{
    position:absolute;
    left: 66%;
    top: 42%;
    width: 30px;
    opacity: 0;
    $animation: (
        light 7s 3s linear infinite,
    );
    @include multiple_animations($animation);
}
.aniTem5{
    position:absolute;
    right: 20%;
    top: 32%;
    opacity: 0;
    animation: star 8s 2s ease-in-out infinite ;
}
.aniTem5_1{
    position:absolute;
    left: 21%;
    top: 28%;
    opacity: 0;
    width: 20px;
    animation: star 10s 2.5s ease-in-out infinite ;
}
.aniTem5_2{
    position:absolute;
    left: 31%;
    top: 18%;
    opacity: 0;
    width: 25px;
    animation: star 9s 1.5s ease-in-out infinite ;
}
.aniTem5_3{
    position:absolute;
    left: 14%;
    bottom: 46%;
    opacity: 0;
    animation: star 15s 3s ease-in-out infinite ;
    transform:rotate(120deg);
}
.aniTem5_4{
    position:absolute;
    right: 32%;
    top: 19%;
    width: 25px;
    opacity: 0;
    animation: star 15s 3s ease-in-out infinite ;
    transform:rotate(20deg);
}
.aniTem5_5{
    position:absolute;
    left: 23%;
    bottom: 26%;
    width: 25px;
    opacity: 0;
    animation: star 8s 2s ease-in-out infinite ;
    transform:rotate(120deg);
}
.aniTem6{
    position: absolute;
    left: 15%;
    top: 9%;
    transform-origin:0 0;
    opacity: 0;
    width: 60%;
    $animations: (
        spotlight 10s 2.5s infinite linear ,
    );
    @include multiple_animations($animations);
}
.aniTem7{
    position: absolute;
    left: 84%;
    top: 16%;
    transform-origin:0 0;
    opacity: 0;
    width: 50%;
    $animations: (
        spotlight2 18s 2.5s infinite linear ,
    );
    @include multiple_animations($animations);
}
@keyframes light{
    0%{
        opacity: 0;
        transform:rotate(0deg);
    }
    25%{
        opacity: 0;
    }
    50%{
        opacity: 0.8;
        transform:rotate(-180deg);
    }
    75%{
        opacity: 0;
    }
    100%{
        opacity: 0;
        transform:rotate(-360deg);
    }
}
@keyframes ballDown{
    0%{
        top:-10%;
    }
    100%{
        top:15%;
        opacity: 1;
    }
}
@keyframes bgLight{
    0%{
        top:-10%;
    }
    100%{
        top:-5%;
        opacity: 1;
    }
}
@keyframes ballScale{
    0%{
        transform:scale(1)
    }
    50%{
        transform:scale(0.95)
    }
    100%{
        transform:scale(1)
    }
}

@keyframes tableUp{
    0%{
        top:60%;
    }
    100%{
        top:45%;
        opacity: 1;
    }
}
@keyframes star{
    0%{
        opacity: 0;
    }
    60%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes spotlight{
    0%{
    opacity: 0.8;
        transform:rotate(0deg);
    }
    25%{
    opacity: 1;
        transform:rotate(30deg);
    }
    50%{
    opacity: 0.8;
        transform:rotate(0deg);
    }
    75%{
        transform:rotate(30deg);
    }
    100%{
    opacity: 1;
        transform:rotate(0deg);
    }
}
@keyframes spotlight2{
    0%{
    opacity: 0.7;
        transform:rotate(0deg) scaleX(-1);
    }
    25%{
    opacity: 1;
        transform:rotate(-30deg) scaleX(-1);
    }
    50%{
    opacity: 0.7;
        transform:rotate(0deg) scaleX(-1);
    }
    75%{
    opacity: 1;
        transform:rotate(-30deg) scaleX(-1);
    }
    100%{
    opacity: 0.7;
        transform:rotate(0deg) scaleX(-1);
    }
}
</style>

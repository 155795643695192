<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/KF/KF_bannerbg.png" />
        <img class="aniTemitem2" src="/images/gameItem/KF/KF_banneritem2.png" />
        <img class="aniTemitem1" src="/images/gameItem/KF/KF_banneritem1.png" />
        <img class="aniTemitem3" src="/images/gameItem/KF/KF_banneritem3.png" />
        <img class="aniTemitem4" src="/images/gameItem/KF/KF_banneritem4.png" />
        <img class="aniTemitem6" src="/images/gameItem/KF/KF_banneritem6.png" />
        <img class="aniTemitem5" src="/images/gameItem/KF/KF_banneritem5.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['lang']),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
.aniTemBg{
    width: 100%;
    @include bgAni;
}
.aniTemitem1{
    position:absolute;
    width: 35%;
    bottom: 8%;
    left: 15%;
    transform-origin: left bottom;
    @include bgAni(0.5s,0.5s);
}
.aniTemitem2{
    position:absolute;
    width: 42%;
    bottom: 2%;
    right: 15%;
    @include bgAni(0.5s,1s);
}
.aniTemitem3{
    position:absolute;
    width: 43%;
    top: -10%;
    left: 32%;
    right: 0;
    opacity: 0;
    @include keyframesBgAni;
    @include keyframesUpDown(15px);
    $animations: (
        bgPop 0.5s 1.5s forwards,
        upDown 2s 2s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem4{
    position:absolute;
    width: 45%;
    bottom: 4%;
    right: 9%;
    @include bgAni(0.5s,1s);
}
.aniTemitem5{
    position:absolute;
    width: 80%;
    opacity: 0;
    animation: leafLeft 2s 2s ease-in-out infinite ;
}
.aniTemitem6{
    position:absolute;
    width: 80%;
    opacity: 0;
    animation: leafRight 2s 3s ease-in-out infinite ;
}
@keyframes leafLeft{
    0%{
        opacity: 0;
        top: 3%;
        left: 3%;
    }
    60%{
        opacity: 0.6;
    }
    100%{
        opacity: 0.0;
        top: 35%;
        left:10%;
    }
}
@keyframes leafRight{
    0%{
        opacity: 0;
        top: 3%;
        right: 3%;
    }
    60%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.0;
        top: 35%;
        right:10%;
    }
}
</style>

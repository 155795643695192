<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/LH/LH_bannerbg.png" />
        <img class="aniTemitem3" src="/images/gameItem/LH/LH_banneritem3.png" />
        <img class="aniTemitem2" src="/images/gameItem/LH/LH_banneritem2.png" />
        <img class="aniTemitem1" src="/images/gameItem/LH/LH_banneritem1.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['lang']),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
.aniTemBg{
    width: 100%;
    @include bgAni;
}
.aniTemitem1{
    position:absolute;
    width: 95%;
    bottom: 5%;
    margin: 0 auto;
    $animations: (
        playerIn 1.5s 0.3s forwards,
        playerAni 0.5s 1.6s infinite alternate
    );
    @include multiple_animations($animations);
}
.aniTemitem2{
    position:absolute;
    width: 36%;
    bottom: 42%;
    margin: 0 auto;
    $animations: (
        referee 2s 0.3s forwards,
        refereePlay 1s 2s infinite alternate,
    );
    @include multiple_animations($animations);
}
.aniTemitem3{
    position:absolute;
    width: 60%;
    left: 0;
    right: 0;
    bottom: 50%;
    margin: 0 auto;
    opacity: 0;
    @include keyframesBgAni;
    @include keyframesUpDown(15px);
    $animations: (
        bgPop 0.5s 0.3s forwards,
        upDown 0.5s 0.5s infinite linear
    );
    @include multiple_animations($animations);
}

@keyframes referee{
    0%{
        left: 0;
    }
    100%{
        left: 32%;
    }
}
@keyframes refereePlay{
    0%{left: 32%;}
    20%{left: 31%;}
    40%{left: 30%;}
    50%{left: 31%;}
    60%{left: 32%;}
    80%{left: 31%;}
    100%{left: 30%;}
}
@keyframes playerIn{
    0%{
        right: -50%;
    }
    100%{
        right: 15%;
    }
}
@keyframes playerAni{
    0%{
        right:10%;
    }
    100%{
        right: -5%;
    }
}
</style>

<template>
    <div>
        <section class="priv_banner"></section>
        <section class="priv_main">
            <div class="paragraph">
                <h2 class="title">{{langConfig.TermsAndConditions}}</h2>
                <p>Last updated: 14 November 2023</p>
                <div class="content">
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_1_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_1_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_1_3}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_2_1}}</p>
                 
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_8}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_9}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_10}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_11}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_12}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_3_13}}</p>
               
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_4}}</p>
                    <ul>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_1}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_2}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_3}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_4}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_5}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_6}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_7}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_4_4_8}}</li>
                    </ul>
                    <p>{{langConfig.TermsAndConditionsContent_4_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_8}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_9}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_10}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_11}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_12}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_13}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_4_14}}</p>
               
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_8}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_9}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_10}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_5_11}}</p>
                 
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_8}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_9}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_10}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_6_11}}</p>
               
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_7_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_7_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_7_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_7_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_7_5}}</p>
            
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_8}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_8_8}}</p>
            
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_9}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_9_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_9_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_9_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_9_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_9_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_9_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_9_7}}</p>
              
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_10}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_10_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_10_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_10_3}}</p>
              
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_11}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_11_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_11_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_11_3}}</p>
                
                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_12}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_8}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_9}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_10}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_11}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_12}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_12_13}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_13}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_13_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_13_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_13_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_13_4}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_14}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_14_1}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_15}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_15_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_15_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_15_3}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_16}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_16_1}}</p>
                    <ul>
                        <li>{{langConfig.TermsAndConditionsContent_16_1_1}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_16_1_2}}</li>
                        <li>{{langConfig.TermsAndConditionsContent_16_1_3}}</li>
                    </ul>
                    <p>{{langConfig.TermsAndConditionsContent_16_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_16_3}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_17}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_17_1}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_18}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_18_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_18_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_18_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_18_4}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_19}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_19_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_19_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_19_3}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_20}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_6}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_7}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_8}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_9}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_10}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_11}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_12}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_13}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_20_14}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_21}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_21_1}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_22}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_22_1}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_22_2}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_22_3}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_22_4}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_22_5}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_22_6}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_23}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_23_1}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_24}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_24_1}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_25}}</p>
                    <p>{{langConfig.TermsAndConditionsContent_25_1}}</p>

                    <p class="zoneTitle">{{langConfig.TermsAndConditionsContent_26}}</p>
                    <p>
                        {{langConfig.TermsAndConditionsContent_26_1_1}}
                        <span>{{langConfig.TermsAndConditionsContent_26_1_2}}</span>
                        {{langConfig.TermsAndConditionsContent_26_1_3}}
                    </p>
                    <p>
                        {{langConfig.TermsAndConditionsContent_26_2_1}}
                        <span>{{langConfig.TermsAndConditionsContent_26_2_2}}</span>
                        {{langConfig.TermsAndConditionsContent_26_2_3}}
                    </p>
                    <p>
                        {{langConfig.TermsAndConditionsContent_26_3_1}}
                        <span>{{langConfig.TermsAndConditionsContent_26_3_2}}</span>
                        {{langConfig.TermsAndConditionsContent_26_3_3}}
                    </p>
                    <p>
                        {{langConfig.TermsAndConditionsContent_26_4_1}}
                        <span>{{langConfig.TermsAndConditionsContent_26_4_2}}</span>
                        {{langConfig.TermsAndConditionsContent_26_4_3}}
                    </p>
                    <p>
                        {{langConfig.TermsAndConditionsContent_26_5_1}}
                        <span>{{langConfig.TermsAndConditionsContent_26_5_2}}</span>
                        {{langConfig.TermsAndConditionsContent_26_5_3}}
                    </p>
                    <p>
                        {{langConfig.TermsAndConditionsContent_26_6_1}}
                        <span>{{langConfig.TermsAndConditionsContent_26_6_2}}</span>
                        {{langConfig.TermsAndConditionsContent_26_6_3}}
                    </p>
                    <p>
                        {{langConfig.TermsAndConditionsContent_26_7_1}}
                        <span>{{langConfig.TermsAndConditionsContent_26_7_2}}</span>
                        {{langConfig.TermsAndConditionsContent_26_7_3}}
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.priv_banner{
	height: 465px;
	background-image: url("/images/privacy.png");
    background-size: cover;
    background-position: center center;
}
.priv_main{
    width: 70%;
    margin: 30px auto;
    max-width: 1400px;
    @include pads-width{
        width: 85%;
    }
    .paragraph{
        padding: 15px 0;
    }
    .title{
        font-size: 34px;
        color: #333333;
        margin-bottom: 15px;
        margin-top: 5%;
    }
    .content{
        display: flex;
        flex-direction: column;
    }
    
    p {
        margin: 20px 10px 0 0;
        color: #031029;
        font-size: 18px;
        font-weight: 1000;
        line-height: 40px;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin: 20px 15px 0 0;
        }
        @include phone-width(){
            font-size: 18px;
            margin: 20px 10px 0 0;
            line-height: 30px;
        }
        span {
            font-size: 20px;
            font-weight: 2000;
        }
    }
    .zoneTitle{
       font-size: 24px;
       color: #0b0ee0;

       a {
        color: #0b0ee0;
       }
    }
    li {
        font-size: 18px;
        line-height: 40px;
        list-style: disc;
        margin-left: 5%;
        color: black;
        font-family: auto;
        font-weight: 800;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin-left: 26px;
        }
        @include phone-width(){
            font-size: 18px;
            width: 90%;
            margin: 10px 0 10px 20px;
            line-height: 30px;
        }
    }
}
</style>
<template>
   <div class="gameItemBanner__img">
            <img class="aniTemBg" src="/images/gameItem/A016/A016_bannerbg.png" />
           <img  class="aniItem02" src="/images/gameItem/A016/A016_banneritem2.png" />
          
            <div class="whiteSheep">
                <img  class="aniItem06" src="/images/gameItem/A016/A016_banneritem6.png" />
                <img  class="aniItem05" src="/images/gameItem/A016/A016_banneritem5.png" />
                <img  class="aniItem07" src="/images/gameItem/A016/A016_banneritem7.png" />
                <img  class="aniItem01" src="/images/gameItem/A016/A016_banneritem3.png" />
                <img  class="aniItem04" src="/images/gameItem/A016/A016_banneritem4.png" />
            </div>
              <img class="aniTemMain" src="/images/gameItem/A016/A016_banneritem1.png" />
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
      ...mapState(['lang']),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;

@mixin sheep($name,$orgtop:10%,$upTop:-10%,$deg:10deg){
  @keyframes #{$name} {
  0%{
     top: $orgtop;
     transform: rotate(0);
  }
  
  30%{
    top: $upTop;
    transform: rotate($deg);
  }
  45%{
     top: $orgtop;
     transform: rotate(0);
  }
  }
}

@keyframes REar{
  0%{
    left: 52%;
    top:21%;
    transform: rotate(0);
  }
  
  30%{
     top: -3%;
     left: 63%;
     transform: rotate(50deg);
  }
  45%{
    left: 52%;
    top:21%;
    transform: rotate(0);
  }
}
@keyframes LEar{
  0%{
    left: 23%;
    top:10%;
    transform: rotate(0);
  }
 
  30%{
     top: -15%;
     left: 20%;
     transform: rotate(-50deg);
  }
  45%{
    left: 23%;
    top:10%;
    transform: rotate(0);
  }
}



//animation動畫寫在這兒
.aniTemBg{
       width: 100%;
       @include bgAni
  }

//柵欄
.aniTemMain{
       position:absolute;
       right: 10%;
        bottom: 10%;
       width: 80%;
       animation: pale 0.3s 2s linear forwards;
       opacity: 0;
}


//山羊
.aniItem02{
  position:absolute;
  right: 10%;
  top: 15%;
  width: 30%;
  opacity: 0;
  
  $animations: (
        bgPop 0.3s 1s linear forwards,
  );
  @include multiple_animations($animations);
}


//白羊

.whiteSheep{
    position:absolute;
       width: 50%;
       height: 100%;
       bottom: 0%;
       left: 13%;
      opacity: 0;
     transform: scaleY(0.9);
      $animations: (
        bgPop 0.3s 1.5s linear forwards,
         sheepG 1.5s 3s ease-in-out infinite,
      );
  @include multiple_animations($animations);
}
.aniItem01{
    position:absolute;
    left: 0%;
    top: 10%;
    width: 89%;
    transform-origin: right center;
  
  @include sheep(sheep);
    $animations: (
        sheep 1.5s 3s ease-in-out infinite,
    );
    @include multiple_animations($animations);
}

//尾巴
.aniItem06{
  position:absolute;
  right:5%;
  top:24%;
  width: 30%;
  transform-origin: left ;
 @include sheep(sheep1,24%,4%,-10deg);
   $animations: (
        sheep1 1.5s 3s ease-in-out infinite,
    );
    @include multiple_animations($animations);
}

//右耳
.aniItem04{
  position:absolute;
  left: 52%;
  top:21%;
  width: 22%;
  transform-origin: left top;
  animation: REar 1.5s 3s ease-in-out infinite;
}
//左耳
.aniItem05{
  position:absolute;
  left: 23%;
  top:10%;
  width: 15%;
   transform-origin: right top;
  animation: LEar 1.5s 3s ease-in-out infinite;
}
//影子
.aniItem07{
  position:absolute;
  bottom: 25%;
  left: 30%;
  width: 50%;
  opacity: 0;
    $animations: (
        bgPop 0.3s 1.5s linear forwards,
        sheepShadow 1.5s 3s ease-in-out infinite,
      );
  @include multiple_animations($animations);
}

@keyframes sheepShadow{
  0%{
     width: 50%;
  }
  30%{
     width: 40%;
  }
  45%{
    width: 50%;
  }
}

@keyframes sheepG{
  0%{
    transform: scaleY(0.9);
  }
  30%{
    left: 10%;
    transform: scaleY(1.1);
  }
  45%{
     left: 13%;
    transform: scaleY(1);
  }
  90%{
     left: 13%;
    transform: scaleY(1);
  }
}


@keyframes pale{
       0%{
          opacity: 0;
           bottom: 100%;
           transform: scale(1);
       }
       80%{
          bottom:-10%;
          transform: scale(1.5);
       }
       100%{
            opacity: 1;
            bottom: 10%;
            transform: scale(1);
       }
}

</style>

<template>
    <div class="gameItemBanner__img">
      
        <img class="aniTem4" src="/images/gameItem/SL_A012/sea3.png" />
        <img class="aniTem5" src="/images/gameItem/SL_A012/octopus.png" />
        <img class="aniTem3" src="/images/gameItem/SL_A012/sea2.png" />
        <img class="aniTem2" src="/images/gameItem/SL_A012/sea1.png" />
        <img class="aniTem1" src="/images/gameItem/SL_A012/boat.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["langConfig"]),
        selectedItem() {
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@import "@/scss/rwd.scss";
@include keyframesUpDown(10px);
@keyframes aniTem5 {
    0% {
        transform: translateY(0)
    }
    50% {
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
    }
}
@keyframes aniTem1 {
    0% {
        top: 0%;
    }
    100% {
        top: 5%;
        opacity: 1;
    }
}
@keyframes aniTem2 {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
   
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes aniTem3 {
    0% {
        // bottom: 23.5%;
        right: 10%;
    }
    // 75% {
    //     bottom: 23.5%;
    // }
    100% {
        right: 6%;
        // bottom: 22%;
        opacity: 1;
    }
}
@keyframes aniTem4 {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(50px);
    }
    100% {
        transform: translateY(10px);
        opacity: 1;
    }
}
.gameItemBanner__img{
    height: 480px;
    @include pads-width{
        height: 240px;
    }
}
.aniTem1 {
    position: absolute;
    opacity: 0;
    width: 65%;
    $animation: (aniTem1 1.5s .5s forwards, upDown 1.5s 2s infinite);
    @include multiple_animations($animation);
}

.aniTem2 {
    position: absolute;
    opacity: 0;
    width: 56%;
    right: 5%;
    bottom: 0%;
    $animation: (aniTem2 1.5s 1s forwards,upDown 1.5s 2.5s infinite );
    @include multiple_animations($animation);
   
}

.aniTem3 {
    position: absolute;
    opacity: 0;
    width: 60%;
    bottom: 10%;
    $animation: (aniTem3 1s 2.2s forwards,upDown 1.5s 3.2s infinite );
    @include multiple_animations($animation);
}

.aniTem4 {
    position: absolute;
    opacity: 0;
    width: 73%;
    top: 5%;
    right: 7%;
    $animation: (aniTem4 .8s 2.7s forwards,upDown 1.5s 3.5s infinite );
    @include multiple_animations($animation);
}

.aniTem5 {
    position: absolute;
    opacity: 0;
    width: 65%;
    right: 10%;
    top: 11%;
    $animation: (aniTem5 1s 2.7s forwards,upDown 1.5s 3.7s infinite );
    @include multiple_animations($animation);
}

</style>

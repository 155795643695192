<template>
    <div>
        <section class="priv_banner"></section>
        <section class="priv_main">
            <div class="paragraph">
                <h2 class="title">{{langConfig.RespectsYourPrivacy}}</h2>
                <div class="content">
                    <p>{{langConfig.RespectsYourPrivacyContent}}</p>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.InfoCollection}}</h2>
                <div class="content">
                    <p>{{langConfig.InfoCollectionContent_1}}</p>
                    <ul>
                        <li>{{langConfig.InfoCollectionContent_1_listItem_1}}</li>
                        <li>{{langConfig.InfoCollectionContent_1_listItem_2}}</li>
                        <li>{{langConfig.InfoCollectionContent_1_listItem_3}}</li>
                        <li>{{langConfig.InfoCollectionContent_1_listItem_4}}</li>
                        <li>{{langConfig.InfoCollectionContent_1_listItem_5}}</li>
                        <li>{{langConfig.InfoCollectionContent_1_listItem_6}}</li>
                        <li>{{langConfig.InfoCollectionContent_1_listItem_7}}</li>
                    </ul>
                    <p>{{langConfig.InfoCollectionContent_2}}</p>
                    <ul>
                        <li>{{langConfig.InfoCollectionContent_2_listItem_1}}</li>
                        <li>{{langConfig.InfoCollectionContent_2_listItem_2}}</li>
                        <li>{{langConfig.InfoCollectionContent_2_listItem_3}}</li>
                    </ul>
                    <p>{{langConfig.InfoCollectionContent_3}}</p>
                    <ul>
                        <li>{{langConfig.InfoCollectionContent_3_listItem_1}}</li>
                        <li>{{langConfig.InfoCollectionContent_3_listItem_2}}</li>
                        <li>{{langConfig.InfoCollectionContent_3_listItem_3}}</li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.PersonalInfo}}</h2>
                <div class="content">
                    <p>{{langConfig.PersonalInfoContent}}</p>
                    <ul>
                        <li>{{langConfig.Name}}</li>
                        <li>{{langConfig.Tel}}</li>
                        <li>{{langConfig.Email}}</li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.HowToUseInfo}}</h2>
                <div class="content">
                    <p>{{langConfig.HowToUseInfoContent_1}}</p>
                    <ul>
                        <li>{{langConfig.HowToUseInfoContent_1_listItem_1}}</li>
                        <li>{{langConfig.HowToUseInfoContent_1_listItem_2}}</li>
                        <li>{{langConfig.HowToUseInfoContent_1_listItem_3}}</li>
                        <li>{{langConfig.HowToUseInfoContent_1_listItem_4}}</li>
                        <li>{{langConfig.HowToUseInfoContent_1_listItem_5}}</li>
                        <li>{{langConfig.HowToUseInfoContent_1_listItem_6}}</li>
                    </ul>
                    <p>{{langConfig.HowToUseInfoContent_2}}</p>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.ThirdPartyDisclosure}}</h2>
                <div class="content">
                    <p>{{langConfig.ThirdPartyDisclosureContent_1}}</p>
                    <p>{{langConfig.ThirdPartyDisclosureContent_2}}</p>
                    <p>{{langConfig.ThirdPartyDisclosureContent_3}}</p>
                    <ul>
                        <li>{{langConfig.ThirdPartyDisclosureContent_3_listItem_1}}</li>
                        <li>{{langConfig.ThirdPartyDisclosureContent_3_listItem_2}}</li>
                        <li>{{langConfig.ThirdPartyDisclosureContent_3_listItem_3}}</li>
                        <li>{{langConfig.ThirdPartyDisclosureContent_3_listItem_4}}</li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.Security}}</h2>
                <div class="content">
                    <p>{{langConfig.SecurityContent}}</p>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.ThirdPartySites}}</h2>
                <div class="content">
                    <p>{{langConfig.ThirdPartySitesContent}}</p>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.General}}</h2>
                <div class="content">
                    <p>{{langConfig.GeneralContent}}</p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.priv_banner{
	height: 465px;
	background-image: url("/images/privacy.png");
    background-size: cover;
    background-position: center center;
}
.priv_main{
    width: 70%;
    margin: 30px auto;
    max-width: 1400px;
    @include pads-width{
        width: 85%;
    }
    .paragraph{
        padding: 15px 0;
    }
    .title{
        font-size: 34px;
        color: #333333;
        text-transform: uppercase;
        margin-bottom: 15px;
        margin-top: 5%;
    }
    .content{
        display: flex;
        flex-direction: column;
    }
    p {
        margin: 10px 0;
        color: #666;
        font-size: 18px;
        line-height: 40px;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin: 15px 0;
        }
        @include phone-width(){
            font-size: 18px;
            margin: 10px 0;
            line-height: 30px;
        }
    }
    li{
        font-size: 18px;
        line-height: 40px;
        list-style: disc;
        margin-left: 20px;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin-left: 26px;
        }
        @include phone-width(){
            font-size: 18px;
            width: 90%;
            margin: 10px 0 10px 20px;
            line-height: 30px;
        }
    }
}
</style>
<template>
    <div>
        <section class="priv_banner"></section>
        <section class="priv_main">
            <div class="paragraph">
                <h2 class="title">{{langConfig.KYC}}</h2>
                <p>Last updated: 14 November 2023</p>
                <div class="content">
                    <p>
                        {{langConfig.KYCContent_1_1}}
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.KYCContent_1_2}}
                    </p>
                    <p>{{langConfig.KYCContent_2}}</p>
                    <ul>
                        <li>{{langConfig.KYCContent_2_listItem_1}}</li>
                        <li>{{langConfig.KYCContent_2_listItem_2}}</li>
                        <li>{{langConfig.KYCContent_2_listItem_3}}</li>
                    </ul>
                    <p>{{langConfig.KYCContent_3}}</p>
                    <ul>
                        <li>{{langConfig.KYCContent_3_listItem_1}}</li>
                        <li>{{langConfig.KYCContent_3_listItem_2}}</li>
                        <li>{{langConfig.KYCContent_3_listItem_3}}</li>
                    </ul>
                    <p>{{langConfig.KYCContent_4}}</p>
                    <ul>
                        <li>{{langConfig.KYCContent_4_listItem_1}}</li>
                        <li>{{langConfig.KYCContent_4_listItem_2}}</li>
                        <li>{{langConfig.KYCContent_4_listItem_3}}</li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.GuidelineForTheKYC}}</h2>
                <div class="content">
                    <p>{{langConfig.GuidelineForTheKYCContent_1}}</p>
                    <ul>
                        <li>{{langConfig.GuidelineForTheKYCContent_1_listItem_1}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_1_listItem_2}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_1_listItem_3}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_1_listItem_4}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_1_listItem_5}}</li>
                    </ul>
                    <p>{{langConfig.GuidelineForTheKYCContent_2}}</p>
                    <ul>
                        <li>{{langConfig.GuidelineForTheKYCContent_2_listItem_1}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_2_listItem_2}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_2_listItem_3}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_2_listItem_4}}</li>
                    </ul>
                    <p>{{langConfig.GuidelineForTheKYCContent_3}}</p>
                    <ul>
                        <li>{{langConfig.GuidelineForTheKYCContent_3_listItem_1}}</li>
                        <li>{{langConfig.GuidelineForTheKYCContent_3_listItem_2}}</li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.NotesOnTheKYC}}</h2>
                <div class="content">
                    <p>{{langConfig.NotesOnTheKYCContent_1}}</p>
                    <p>{{langConfig.NotesOnTheKYCContent_2}}</p>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.OtherAML}}</h2>
                <div class="content">
                    <p>{{langConfig.OtherAMLContent_1}}</p>
                    <p>{{langConfig.OtherAMLContent_2}}</p>
                    <ul>
                        <li>{{langConfig.OtherAMLContent_2_listItem_1}}</li>
                        <li>{{langConfig.OtherAMLContent_2_listItem_2}}</li>
                    </ul>
                    <p>{{langConfig.OtherAMLContent_3}}</p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.priv_banner{
	height: 465px;
	background-image: url("/images/privacy.png");
    background-size: cover;
    background-position: center center;
}
.priv_main{
    width: 70%;
    margin: 30px auto;
    max-width: 1400px;
    @include pads-width{
        width: 85%;
    }
    .paragraph{
        padding: 15px 0;
    }
    .title {
        font-size: 34px;
        color: #0b0ee0;
        margin-bottom: 15px;
        margin-top: 5%;

        span {
            background-color: rgb(233, 227, 227);
        }
    }
    .content{
        display: flex;
        flex-direction: column;
    }

    p {
        margin: 20px 10px 0 0;
        color: #031029;
        font-size: 18px;
        font-weight: 1000;
        line-height: 40px;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin: 20px 15px 0 0;
        }
        @include phone-width(){
            font-size: 18px;
            margin: 20px 10px 0 0;
            line-height: 30px;
        }

        a {
            color: #031029;
            font-family: auto;
        }
        
    }

    li {
        font-size: 18px;
        line-height: 40px;
        font-weight: 800;
        margin-left: 20px;
        list-style: none;
        color: black;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin-left: 26px;
        }
        @include phone-width(){
            font-size: 18px;
            width: 90%;
            margin: 10px 0 10px 20px;
            line-height: 30px;
        }
    }
}
</style>
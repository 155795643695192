<template>
   <div class="gameItemBanner__img">
      <img class="aniTemBg" src="/images/gameItem/BJA/BJA_bannerbg.png" />
      <img class="aniTemPoker1" src="/images/gameItem/BJA/BJA_banneritem3.png" />
      <img class="aniTemPoker2" src="/images/gameItem/BJA/BJA_banneritem4.png" />
      <img class="aniTemDealer" src="/images/gameItem/BJA/BJA_banneritem2.png" />
      <img class="aniTemLogo" src="/images/gameItem/BJA/BJA_banneritem1.png" />
      <img class="aniTemCoin1" src="/images/gameItem/BJA/BJA_banneritem5.png" />
      <img class="aniTemCoin2" src="/images/gameItem/BJA/BJA_banneritem6.png" />
      <img class="aniTemCoin3" src="/images/gameItem/BJA/BJA_banneritem7.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
      ...mapState(['lang']),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;
@include keyframesUpDown;
@include keyframesHeartbeat(1.05);
.aniTemBg{
    width: 100%;
    @include bgAni;
}
.aniTemLogo{
    position:absolute;
    bottom: 0;
    left:0;
    right:0;
    margin:auto;
    width: 65%; 
    opacity:0; 
    $animations: (
      bgPop 0.5s 0.5s forwards,
      heartbeat 4s 1s ease-in infinite
    );
    @include multiple_animations($animations); 
}
.aniTemDealer{
    position:absolute;
    bottom: 20%;
    left:0;
    right:0;
    margin:auto;
    width: 60%;  
    opacity: 0; 
    animation: upToDown 0.5s 1s forwards;  
}
.aniTemPoker1{
    position:absolute;
    top:31%;
    left: 10%;
    width: 30%;     
    opacity: 0;
    transform-origin: right bottom;
    $animations: (
      rotateLeft 1s 1.5s forwards,
      rotateLeft2 3s 2.5s infinite alternate-reverse
    );
    @include multiple_animations($animations); 
    // animation:rotateLeft 2s 1.5s infinite alternate-reverse;
}
.aniTemPoker2{
    position:absolute;
    top:31%;
    right:15%;
    width: 30%;     
    opacity:0;
    transform-origin: left bottom;
    $animations: (
      rotateRight 1s 1.5s forwards,
      rotateRight2 3s 2.5s infinite alternate-reverse
    );
    @include multiple_animations($animations); 
}
.aniTemCoin1{
    position:absolute;
    opacity: 0;
    top:14%;
    right: 23%;
    width: 12%;     
    $animations: (
      downToUp 0.5s 1s forwards,
      upDown 3s 2s ease-in infinite
    );
    @include multiple_animations($animations);   
}
.aniTemCoin2{
    position:absolute;
    opacity: 0;
    top:6%;
    right: 27%;
    width: 12%;     
    $animations: (
      downToUp 0.5s 1s forwards,
      upDown 2.8s 1.5s ease-in infinite
    );
    @include multiple_animations($animations);
}
.aniTemCoin3{
    position:absolute;
    opacity: 0;
    top:12%;
    right: 15%;
    width: 12%; 
    $animations: (
      downToUp 0.5s 1s forwards,
      upDown 3.2s 1.8s ease-in infinite
    );
    @include multiple_animations($animations);     
}

@keyframes downToUp{
  from{
    opacity: 0;
    transform: translateY(80px);
  }

  to{ 
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes upToDown {
	from {
		opacity: 0;
		transform: translateY(-50px);
	}

	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes rotateLeft {
	from {
		opacity: 0;
		transform: rotate(45deg);
	}

	to {
		opacity: 1;
		transform: rotate(0px);
	}
}

@keyframes rotateLeft2 {
	from {
		transform: rotate(5deg);
	}

	to {
		transform: rotate(0px);
	}
}

@keyframes rotateRight {
	from {
		opacity: 0;
		transform: rotate(-45deg);
	}

	to {
		opacity: 1;
		transform: rotate(0px);
	}
}

@keyframes rotateRight2 {
	from {
		transform: rotate(-5deg);
	}

	to {
		transform: rotate(0px);
	}
}


</style>

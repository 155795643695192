<template>
    <div>
        <ComAbout :clientWidth="clientWidth"/>
    </div>
</template>
<script>
import ComAbout from '@/components/ComAbout.vue'
export default {
    components:{
        ComAbout,
    },
    data() {
        return {
            clientWidth: ''
        }
    },
    mounted() {
        this.clientWidth=document.body.clientWidth
        window.onresize=()=>{
            this.clientWidth=document.body.clientWidth
        }
    },
}
</script>
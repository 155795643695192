<template>
   <div class="gameItemBanner__img">
            <img class="aniTemBg" src="/images/gameItem/A006/A006_bannerbg.png" />
            <img class="aniTem3" src="/images/gameItem/A006/A006_banneritem3.png" />
            <img class="aniTem4" src="/images/gameItem/A006/A006_banneritem4.png" />
            <img class="aniTem5" src="/images/gameItem/A006/A006_banneritem5.png" />
            <img class="aniTem1" src="/images/gameItem/A006/A006_banneritem1.png" />
            <img class="aniTem2" src="/images/gameItem/A006/A006_banneritem2.png" />
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
       ...mapState(["langConfig"]),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
//animation動畫寫在這兒
@include keyframesHeartbeat(1.05);
.aniTemBg{
       width: 100%;
       @include bgAni
}
.aniTem1{
       position:absolute;
       top: 37%;
       width: 60%;
       opacity: 0;
       $animation: (
              aniTem1 0.5s 1s forwards,
              heartbeat 1.2s 2s infinite
       );
       @include multiple_animations($animation);
}
@keyframes aniTem1{
    0%{
       left: 10%;
    }
    100%{
       left: -2%;
       opacity: 1;
    }
}
.aniTem2{
       position:absolute;
       top: 0;
       width: 50%;
       opacity: 0;
       $animation: (
              aniTem2 0.5s 1s forwards,
              heartbeat 1.2s 1.5s infinite
       );
       @include multiple_animations($animation);
}
@keyframes aniTem2{
    0%{
       right: 20%;
    }
    100%{
       right: 10%;
       opacity: 1;
    }
}
.aniTem3{
       position:absolute;
       top: 0;
       opacity: 0;
       width: 19%;
       @include keyframesRotate360(15deg);
       $animation: (
              aniTem3 0.5s 1.5s forwards,
              rotate360 .6s 1.8s infinite alternate-reverse
       );
       @include multiple_animations($animation);
}
@keyframes aniTem3{
    0%{
       opacity: 0;
       left: 22%;
    }
    100%{
       left: 15%;
       opacity: 1;
    }
}
.aniTem4{
       position:absolute;
       top: 40%;
       opacity: 0;
       width: 14%;
       $animation: (
              aniTem4 .5s 1.5s forwards,
              rotate360 .4s 1.6s infinite alternate-reverse
       );
       @include multiple_animations($animation);
}
@keyframes aniTem4{
       0%{
              opacity: 0;
              right: 10%;
       }
       100%{
              right: -2%;
              opacity: 1;
       }
}
.aniTem5{
       position:absolute;
       bottom: 7%;
       left: 56%;
       opacity: 0;
       width: 13.5%;
       animation: aniTem5 .6s 1.5s forwards;
       $animation: (
              aniTem5 1s 1.5s forwards,
              rotate360 .5s 1.8s infinite alternate-reverse
       );
       @include multiple_animations($animation);
}
@keyframes aniTem5{
       0%{
              bottom: 10%;
       }
       100%{
              opacity: 1;
       }
}
</style>
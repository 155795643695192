<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/YZ/YZ_bannerbg.png" />
        <img class="aniTem1" src="/images/gameItem/YZ/YZ_banneritem1.png" />
        <img class="aniTem2" src="/images/gameItem/YZ/YZ_banneritem2.png" />
        <img class="aniTem3" src="/images/gameItem/YZ/YZ_banneritem3.png" />
        <img class="aniTem4" src="/images/gameItem/YZ/YZ_banneritem4.png" />
        <img class="aniTem5" src="/images/gameItem/YZ/YZ_banneritem5.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
         ...mapState(["langConfig"]),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesUpDown;

.aniTemBg {
    width: 100%;
    @include bgAni;
}

.aniTem1 {
    position: absolute;
    left: 19%;
    width: 50%;
    top: 8%;
    z-index: 3;
    opacity: 0;
    @include keyframesBgAni;
    animation: bgPop 0.7s 0.8s forwards;
}

.aniTem2 {
    position: absolute;
    top: 20%;
    right: 28%;
    width: 10%;
    z-index: 1;
    opacity: 0;
    $animations: (bgPop 1.7s 1.5s forwards, upDown 2.6s 3.2s infinite);
    @include multiple_animations($animations);
}

.aniTem3 {
    position: absolute;
    top: 39%;
    left: 18%;
    width: 13%;
    z-index: 2;
    opacity: 0;
    $animations: (bgPop 1.2s 1.5s forwards, upDown 2.6s 2.7s infinite);
    @include multiple_animations($animations);
}

.aniTem4 {
    position: absolute;
    bottom: 22%;
    right: 20%;
    width: 13%;
    z-index: 4;
    opacity: 0;
    $animations: (bgPop 0.7s 1.5s forwards, upDown 2.6s 2.2s infinite);
    @include multiple_animations($animations);
}

.aniTem5 {
    position: absolute;
    left: 16%;
    top: 2%;
    width: 70%;
    opacity: 0;
    @include keyframesRotate360(360deg);
    $animations: (bgPop 0.7s 1.5s forwards, rotate360 32s 2.2s infinite linear);
    @include multiple_animations($animations);
}
</style>

<template>
  <div  class="gameItemBg">
    <div class="gameItemBanner">
      <h1>DRINK ME HOME</h1>
      <GameItemSLA005Animation />
    </div>
    <div class="gameItemTitle">
      <img :src="`/images/gameIcon/${gameType}.png`" />
      <h2>{{ langConfig.SL_A005 }}<label class="SL">SL</label></h2>
    </div>
    <div class="gameItemContent">{{ langConfig.SL_A005content }}</div>
    <div class="gameItemPic">
     <div class="gameItemPic__list">
       <GameItemPopUp :gameType="gameType" />
       <div class="secTitle">{{ langConfig.Compatible }}
         <ul class="icon">
          <li><img src="/images/icon_iOS.png" />iOS</li>
          <li><img src="/images/icon_andriod.png" />Andriod</li>
          <li><img src="/images/icon_h5.png" />HTML5</li>
        </ul>
       </div>
        <div class="secTitle">{{ langConfig.Language }}
          <ul>
            <li><img src="/images/flag/China.png" /></li>
            <li><img src="/images/flag/France.png" /></li>
            <li><img src="/images/flag/Germany.png" /></li>
            <li><img src="/images/flag/India.png" /></li>
            <li><img src="/images/flag/Itanly.png" /></li>
            <li><img src="/images/flag/Gray/Japan_gray.png" /></li>
            <li><img src="/images/flag/Gray/Korea_gray.png" /></li>
            <li><img src="/images/flag/Gray/Thai_gray.png" /></li>
            <li><img src="/images/flag/UK.png" /></li>
            <li><img src="/images/flag/Vietnam.png" /></li>
          </ul>
       </div>
     </div>
     <div class="gameItemPic__phone">
        <img class="iphoneX" src="/images/iphoneX.png" />
        <img class="phoneBg" :src="`/images/gameItem/${gameType}/${gameType}_phonebg.png`" />
      </div>
    </div>
    <GameItemHotGame :gameType="gameType"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GameItemSLA005Animation from "@/components/GameItemSLA005Animation.vue";
import GameItemPopUp from "@/components/GameItemPopUp.vue";
import GameItemHotGame from "@/components/GameItemHotGame.vue";

export default {
  components: {
    GameItemSLA005Animation,
    GameItemPopUp,
    GameItemHotGame
  },
  computed:{
    ...mapState(["langConfig"]),
  },
  data(){
    return{
      gameType:'SL_A005',
    }
  },
}
</script>

<template>
  <div class="enthusAni" :class="{'aniPlay':clientWidth<=768 || playAniItem==='Love'}">
    <img src="images/comAbout/love/love_ground.png" class="aniGround" alt="">
    <img src="images/comAbout/love/love.png" class="aniMain" alt="">
    <img src="images/comAbout/love/love_L_bubble.png" class="aniBubbleL" alt="">
    <img src="images/comAbout/love/love_L_bubble_D.png" class="aniBubbleL_D" alt="">
    <img src="images/comAbout/love/love_R_bubble.png" class="aniBubbleR" alt="">
    <img src="images/comAbout/love/love_R_bubble_D.png" class="aniBubbleR_D" alt="">
    <img src="images/comAbout/love/love_effect_1.png" class="aniEffect1" alt="">
    <img src="images/comAbout/love/love_effect_2.png" class="aniEffect2" alt="">
    <img src="images/comAbout/love/love_effect_3.png" class="aniEffect3" alt="">
    <img src="images/comAbout/love/love_effect_4.png" class="aniEffect4" alt="">
  </div>
</template>
<script>
export default {
  props:['playAniItem','clientWidth'],
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "../scss/anicss.scss";
@include keyframesHeartbeat(1.02);
// 基本定位

img {
  position: absolute;
  left: -10%;
  top: -8%;
  @include pads-width{
    top: 0;
  }
}
.aniBubbleL_D,
.aniBubbleR_D,
.aniEffect1,.aniEffect2,.aniEffect3,.aniEffect4{
  opacity: 0;
}
// 動畫樣式
.aniPlay{
  .aniMain{ // 人物
    $animations:(
      shake 1.5s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniBubbleL{ // 左邊氣泡
    $animations:(
      heartbeat 1s infinite linear,
      shake 1.5s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniBubbleL_D{ // 左邊氣泡落下
    $animations:(
      bubbleDrop 1.2s  infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniBubbleR{ // 右邊氣泡
    $animations:(
      heartbeat 1s infinite linear,
      shake 1.5s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniBubbleR_D{ // 右邊氣泡落下
    $animations:(
      bubbleDrop 1.5s  infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect1{ // effect
    $animations:(
      effect 1.8s  infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect2{
    $animations:(
      effect 1.8s 0.4s  infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect3{
    $animations:(
      effect 1.8s 0.8s  infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect4{
    $animations:(
      effect 1.8s 1.2s  infinite linear,
    );
    @include multiple_animations($animations);
  }
}
// 左右晃動
@keyframes shake {
  30%{
    transform:rotate(0)
  }
  40%{
    transform:rotate(1.5deg)
  }
  50%{
    transform: rotate(-1.5deg);
  }
  60%{
    transform:rotate(1.5deg)
  }
  70%{
    transform: rotate(-1.5deg);
  }
  80%{
    transform:rotate(0)
  }
}
// 氣泡落下
@keyframes bubbleDrop{
  0%{
    opacity: 0;
  }
  10%{
    opacity: 0.5;
  }
  20%{
    opacity: 1;
  }
  40%{
    transform: translateY(0);
  }
  80%{
    transform: translateY(80px);
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
// 吵雜聲
@keyframes effect{
  0%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
</style>
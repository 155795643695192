<template>
<div>
	<transition name="fade">
		<headerItem v-if="!scrollFlag" :clazz="'normal'" :selectedIndex='selectedIndex' @chgSelectedIndex='chgSelectedIndex' :langList='langList' :logo="1" />
	</transition>
	<transition name="fade" >
		<headerItem v-if="scrollFlag" :clazz="'active'" :selectedIndex='selectedIndex' @chgSelectedIndex='chgSelectedIndex' :langList='langList' :logo="2" />
	</transition>
</div>
			
</template>

<script>
import headerItem from "@/components/HeaderItem.vue";
export default {
  components: { headerItem },
    watch: {
        $route: {
            handler:function(to){
                this.scrollFlag = false
                const name = to.name
                this.nowComp = name
                if(to.name.includes("GameItem")){
                    this.scrollFlag = true
                }
            },
            immediate: true
		},
    },
	data() {
		return {
            scrollFlag: false,
			nowComp:'',
			selectedIndex: 0,
			langList: [
				{ key: "en", name: "ENGLISH", imgSrc: "/UK" },
				{ key: "zh-cn", name: "简体中文", imgSrc: "/China" },
				{ key: "zh-tw", name: "繁體中文", imgSrc: "/Taiwan" },
			],
		};
	},
	methods: {
		chgSelectedIndex(index){
			this.selectedIndex = index;
		},
		getlanguage(val){
				switch (val) {
		case "en":
		case "en-us":
			document.title = 'Cherry Fun - Have Cherry Have Fun'
			localStorage.setItem('cherryFun_lang',this.langList[0].key) // 存入localStorage
			this.selectedIndex = 0;
			this.$store.commit("updatedLang", localStorage.getItem('cherryFun_lang'));
			this.$store.dispatch("getLanguage");
			return 
		case "zh-cn":
			document.title = '伽立方 - Have Cherry Have Fun'
			localStorage.setItem('cherryFun_lang',this.langList[1].key) // 存入localStorage
			this.selectedIndex = 1;
			this.$store.commit("updatedLang", localStorage.getItem('cherryFun_lang'));
			this.$store.dispatch("getLanguage");
			return 
		case "zh-tw":
			document.title = '伽立方 - Have Cherry Have Fun'
			localStorage.setItem('cherryFun_lang',this.langList[2].key) // 存入localStorage
			this.selectedIndex = 2;
			this.$store.commit("updatedLang", localStorage.getItem('cherryFun_lang'));
			this.$store.dispatch("getLanguage");
			return 
		default:
			localStorage.setItem('cherryFun_lang',this.langList[0].key) // 存入localStorage
			this.selectedIndex = 0;
			this.$store.commit("updatedLang", localStorage.getItem('cherryFun_lang'));
			this.$store.dispatch("getLanguage");
			document.title = 'Cherry Fun - Have Cherry Have Fun'
			return 
		}
		}
	},
	mounted() {
		window.addEventListener("scroll", () => {
            const distance = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
            if(!this.nowComp.includes('GameItem')){
				this.scrollFlag = distance > 80
            }
		});
		if(localStorage.getItem('cherryFun_lang')){
			
			this.getlanguage(localStorage.getItem('cherryFun_lang'))
		}else{
			var sys = "";
			sys = (navigator.language||navigator.userLanguage||navigator.browserLanguage||navigator.systemLanguage).toLowerCase();
			this.getlanguage(sys)
		}
	
	},
};
</script>

<style lang="scss" >
@import "@/scss/rwd.scss";
header {
		@include pads-width(){
			padding: 10px 25px;
		}
    color: #fff;
    top: 0;
    width: 100%;
	position: fixed;
	z-index: 100;
	&.normal{
		&::before{
			content: '';
			width: 100%;
			position:absolute;
			width: 100%;
			height: 200px;
			top: 0;
			left: 0;
			pointer-events: none;
			background:url('/images/blackmask.png') repeat-x;
		}
		.headercenter{
			.mobmenu{
				padding: 0;
				margin-top: 5px;
				.mobmenuIcon{
					width: 35px;
					height: 30px;
				}
				
			}
		}
	}
	.headercenter{
		width: 95%;
		max-width: 1600px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		position: relative;

	.logo {
        width: 180px;
		padding: 5px;
		cursor: pointer;
		position: relative;
		z-index: 10;
	}


	.menu {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 1;
		// padding: 0px 100px 0px 30%;
		@include pads-width(){
			display: none;
		}
		li {
			cursor: pointer;
			position: relative;
			text-transform: uppercase;
			padding:  0  20px;
			&::after {
				content: "";
				position: absolute;
				bottom: -13px;
				left: 50%;
				margin-left: -15px;
				width: 30px;
				height: 3px;
				background-color: #fff;
				display: none;
			}
			&:hover {
				opacity: 1;
				&::after {
					display: block;
				}
			}
		}
	}

	.mobmenu{
		display: none;
		@include pads-width(){
			display: block;
		}
		position: absolute;
		right: 0px;
		top: 10px;
		width: 40px;
		height: 40px;
		cursor: pointer;
		z-index: 11;
		box-sizing: border-box;
		padding: 8px 5px ;
		border-radius: 5px;
		.mobmenuIcon{
			display: block;
			position: relative;
			width: 30px;
			height: 25px;
			span{
				background: #fff;
				display: block;
				position: absolute;
				transition: all .4s ease;
				opacity: 1;
				&:first-child{
				width: 15%;
					height: 4px;
				border-radius: 5px;
				top: 0;
				left: 0;
				}
				&:nth-child(2){
					width: 78%;
					height: 4px;
					top: 0;
					right: 0;
					border-radius: 5px;
					
				}
				&:nth-child(3){
					width: 78%;
					height: 4px;
						top: 50%;
					margin-top: -2px;
					left: 0;
					border-radius: 5px;
				}
				&:nth-child(4){
					width: 15%;
					height: 4px;
					top: 50%;
					margin-top: -2px;
					right: 0;
					border-radius: 5px;
				}
				&:nth-child(5){
					width: 100%;
					height: 4px;
					bottom: 0;
					right: 0;
					border-radius: 5px;
				}
			}
		}
	}
	.mobmenuShow{
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		right: 0;
		
		opacity: 0;
		transform-origin: top right;
		padding: 5%;
		z-index: 11;
		&.oenpmenubg{
			background: #fff;
			animation: menubg 0.1s 0s ease-in forwards;
		}
		&.closemenubg{
			background: #fff;
			animation: closemenubg 0.1s 0s ease-in  forwards;
		}
		img{
			max-width: 180px;
		}
		.close{
			width: 50px;
			height: 50px;
			position: absolute;
			right: 30px;
			top: 25px;
			background:#cf1e2c;
			border-radius: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			opacity: 0;
			animation: closeMove 0.2s 0.3s ease-in forwards;
			font-size: 24px;
		}
		&::before{
			content: '';  		
			width: 100%;
			height: 100vh;
			position: absolute;
			pointer-events: none;
			background: url(/images/cherry.png) no-repeat;
			background-position: bottom 12% right 5%;
			opacity: 0;
			animation: menuMove 0.5s 1s  forwards;
			background-size: 45%;
		}
		ul{
			width: 90%;
			margin: 30% auto;
			font-size: 24px;
			line-height: 50px;
			color: #000;
			opacity: 0;
			animation: menuMove 0.5s 0.5s ease-in forwards;
			li{
				&:hover,
				&:active{
					color: #d00d20;
					padding-left: 10px;
				}
			}
			&.mobLang{
				width: 100%;
				position:absolute;
				left: 0;
				bottom: 0;
				text-align: center;
				margin: 0;
				padding: 15%;
				height: 50vh;
				overflow-y: scroll;
				background: #f5f5f5eb;
				animation: none;
				opacity: 1;
				animation: showlan 0.2s ease-in forwards;

				&.closeshowlan{
					animation: closeshowlan 0.2s ease-in forwards;
				}
			}
		}
	}
	}
}

.selector {
	@include pads-width(){
			display: none;
	}
	width: 150px;
	height: 30px;
	border: 1px solid #eee;
	// border-radius: 5px 5px 0 0;
	border-radius: 5px;
	position: relative;
	// border-bottom:0px;
	padding: 5px 10px 0;
	margin-right: 20px;
	cursor: pointer;
	.v-icon{
		position: absolute;
		right: 10px;
		&.global{
			left: 10px;
			font-size: 16px;
			color: rgb(224, 224, 224);
			pointer-events: none;
		}
	}
	img {
		width: 20px;
		&.arrow {
			width: 16px;
		}
	}
	span {
		padding: 0 30px;
		font-size: 14px;
		pointer-events: none;
	}
	.select_list {
		position: absolute;
		border: 1px solid #fff;
		width: 150px;
		padding-bottom: 10px;
		left: -1px;
		top: 29px;
		border-radius: 0 0 5px 5px;
		border-top: none;
		li {
			padding: 8px 10px;
			display: flex;
			align-items: center;
			&:hover {
				background: #f5f5f5;
				color: #575757;
			}
		}
	}
}

.active {
	height: 60px;
	background: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
	color: #999;
	.headercenter{
		margin-top: 5px;
	.logo {
		width: 150px;
	}
	.menu {
		li {
			font-size: 16px;
			padding: 0 20px;
			opacity: 1;
			&:hover {
				color: #000;
			}
			&::after {
				background-color: #000;
			}
		}
	}
	.select_list {
		background: #fff;
	}
	.mobmenu{
		top: 5px;
		background: #cf1e2c;
	}
	}
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s;
}
.fade-enter, .fade-leave-to{
	transform: translateY(-40px);
    opacity: 0;
}


@keyframes menubg {
	0%{
		opacity: 0;
		transform: scale(0);
		width: 0px;
		height: 0px;
		border-radius: 0px;
	}
	90%{
		width: 500px;
		height: 500px;
		border-radius:0px  0px  0px 500px;
	}
	100%{
		opacity: 1;
		border-radius: 0px;
		transform: scale(1);
	}
}

@keyframes closemenubg {
	0%{
		opacity: 1;
		border-radius: 0px;
		transform: scale(1);
	}
	90%{
		width: 500px;
		height: 500px;
		border-radius:0px  0px  0px 500px;
	}
	100%{
		opacity: 0;
		transform: scale(0);
		width: 0px;
		height: 0px;
		border-radius: 0px;
	}
}

@keyframes menuMove {
	0%{
		opacity: 0;
		width: 100%;
	}
	100%{
		opacity: 1;
		width: 90%;
	}
	
}

@keyframes closeMove {
	0%{transform: scale(0);	opacity: 0;}
	100%{
		transform: scale(1);opacity: 1;
	}
}

@keyframes showlan {
	0%{transform: scaleY(0);	opacity: 0;}
	100%{
		transform: scaleY(1);opacity: 1;
	}
}
@keyframes closeshowlan {
	0%{transform: scaleY(1);	opacity: 1;}
	100%{
		transform: scaleY(0);opacity: 0;
	}
}

</style>

<template>
	<footer>
		<div class="footerCenter">
		<img class="logo" src="/images/cherrylogo_2.png" alt="" />
		<div class="site_map">
			<ul>
                <li class="title" @click="changeSiteMapFlg">{{langConfig.SiteMap}}<icon :name="`${siteMapFlg?'minus':'plus'}` " v-if="this.screenWidth<1000"/></li>
				<template v-if="siteMapFlg">
				<li v-for="(vo, k) in siteMap" :key="k" @click="goPage(vo.path)">
                   {{langConfig[vo.name]}}
                </li>
				</template>
			</ul>
			<ul >
                <li class="title" @click="changeAboutUsFlg">{{langConfig.AboutUs}}<icon :name="`${aboutUsFlg?'minus':'plus'}`" v-if="this.screenWidth<1000" /></li>
				<template v-if="aboutUsFlg">
                <li v-for="(xo, j) in aboutUs" :key="j" @click="goPage(xo.path)">
                  {{langConfig[xo.name]}}
                </li>
				</template>
            </ul>
			<ul >
                <li class="title" @click="changePrivacyFlg">{{langConfig.Privacy}}<icon :name="`${privacyFlg?'minus':'plus'}`" v-if="this.screenWidth<1000" /></li>
				<template v-if="privacyFlg">
                <li v-for="(po, j) in privacy" :key="j"  @click="goPage(po.path)">
                 {{langConfig[po.name]}}
                </li>
				</template>
            </ul>
			<ul class="certificate">
				<li class="title">{{langConfig.SecurityGameIntegrity}}</li>
				<ul class=" licensing ">
					<li v-for="vo in security" :key='vo'>
						<img :src="`/images/${vo}.png`" alt="">
					</li>
				</ul>
				<!-- <div class="certificate">
					<img v-for="vo in security" :key='vo' :src="`/images/${vo}.png`" alt="">
				</div> -->
			</ul>
		</div>
		<div class="soical">
			<!-- <ul class="contact">
				<li v-for="(item, i) in soicalMedia" :key="i">
					<img :src="`/images/${item.src}.png`" alt="" />
				</li>
			</ul> -->
			<p>COPYRIGHT © <span>{{ new Date().getFullYear() }}</span> CHERRY FUN™ ALL RESERVED</p>
		</div>
		</div>
	</footer>
</template>

<script>
import { mapState } from "vuex";
export default {
	data() {
		return {
			screenWidth: document.body.clientWidth,
			// soicalMedia: [
			// 	{ src: "youtube", href: "/" },
			// 	{ src: "twitter", href: "/" },
			// 	{ src: "facebook", href: "/" },
			// ],
			siteMap: [
				{ name: "Home", path: "/" },
				{ name: "Game", path: "/game" },
				{ name: "Company", path: "/com" },
			],
			aboutUs: [
				{ name: "Licensing", path: "https://itechlabs.com/certificates/cherryfun/RNG_Certificate_UK_CherryFunNV_15Jan21.pdf" },
				{ name: "ContactUs", path: "mailto:business@cherry.fun" },
			],
			privacy:[
				{ name: "PrivacyPolicy", path: "/priv" },
				{ name: "AMLPolicy", path: "/aml_policy" },
				{ name: "KYCPolicy", path: "/kyc_policy" },
				{ name: "ResponsibleGaming", path: "/responsible_gaming" },
				{ name: "SelfExclusionPolicy", path: "/self_exclusion_policy" },
				{ name: "TermsConditions", path: "/terms_conditions" },
				{ name: "UnderageGamingPolicy", path: "/underage_gaming_policy" },
			],
			security:['18+','iTech','RNG','SSL'],
			aboutUsFlg:false,
			privacyFlg:false,
			siteMapFlg:false,
		};
	},
	computed:{
		...mapState(["langConfig"]),
	},
    methods:{
        goLink(path){
            window.open([path])
        },
        goPage(path){

			if(path.includes("http")||path.includes("@")){
				window.open(path)
			}else{
				this.$router.push(path).catch(()=>{});
			}
		},
		changeAboutUsFlg(){
			this.aboutUsFlg =!this.aboutUsFlg;
		},
		changePrivacyFlg(){
			this.privacyFlg =!this.privacyFlg;
		},
		changeSiteMapFlg(){
			this.siteMapFlg =!this.siteMapFlg;
		},
	},
	mounted(){
		if(this.screenWidth>540){
			this.aboutUsFlg=true;
			this.privacyFlg=true;
			this.siteMapFlg=true;
		}
	},
	watch: {
    $route: {
      immediate: true,
      handler: function() {
        const that = this;
        window.onresize = () => {
        return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        })();
      };
      }
    }
  }
	
};
</script>

<style lang="scss" scoped>
@import "@/scss/rwd.scss";
footer {
	border-top: 1px solid rgba(0,0,0,.1);
	.footerCenter{
	width: 95%;
	min-height: 385px;
	max-width: 1600px;
	display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: space-between;
	position: relative;
	padding: 50px 0;
		margin: 0 auto;
		.logo {
			width: 300px;
			@include footer-width(){
				width: 250px;
			}
    }
	.soical {
		position: absolute;
		text-align: left;
		bottom: 130px;
		@include footer-width(){
			display: flex;
			width: 100%;
			flex-direction: column-reverse;
			position: relative;
			bottom: -40px;
		}
        p {
			padding-top: 10px;
			line-height: 18px;
			@include footer-width(){
				text-align: center;
			}
        }
	}
	.contact {
		display: flex;
		padding: 15px 0;
			@include footer-width(){
			justify-content: center;
		}
		li {
			margin-right: 5px;
            width: 50px;
            cursor: pointer;
			img {
                width: 40px;
			}
        }
	}
	
	.certificate{
		width: 50%;
		@include footer-width(){
			width:90%;
		}
		ul{ 
			display: flex;
			flex-wrap: wrap;
			width:100%;
			padding:0px;
			
			li{
				margin:2px;
				
			}
		}
		.title{
			flex:1;
		}
	}
	
    .site_map{
		display:flex;
		justify-content: flex-end;
		width: 60%;
		z-index: 2;
		@include footer-width(){
				width: 100%;
				display:block;
				margin-top: 50px;
			}
        ul{
			padding:0 10px;
			width: 30%;
			min-width:200px;
			@include footer-width(){
				padding:0 10px 0 0;
				width: 100%;
			}
			@include footer-width(){
				padding: 0;
			}
			&:last-child{
				min-width: 230px;
				padding-left: 0;
				justify-content: space-between;
				@include footer-width(){
				width: 100%;
				}
			}
			&.licensing{
					@include phone-width(){
					width: 240px;
					margin: 0 auto;
				}
				li{
					padding: 1px ;
				}
			}
            li{
				padding:10px 0;
				@include footer-width(){
					padding: 15px 0;
				}
				cursor: pointer;
				color: #999;
                &.title{
                    font-size: 22px;
					color:#333;
					@include footer-width(){
					border-top: 1px solid rgba(0, 0, 0, 0.1) ;
					width: 100%;
					display: flex;
					justify-content: space-between;
					}
				}
				&:hover,
				&:active{
					color: #000;
				}
            }
		
        }
	}
	}
	
	
}
</style>

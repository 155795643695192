<template>
   <div class="gameItemBanner__img">
            <img class="aniTemBg" src="/images/gameItem/SL_A002/SL_A002_bg.png" />
              <img class="aniTem2" src="/images/gameItem/SL_A002/SL_A002_star.png" />
         
              <img class="aniTem1" src="/images/gameItem/SL_A002/SL_A002_UFO.png" />
         
              <img class="aniTem3" src="/images/gameItem/SL_A002/SL_A002_rock.png" />
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
       ...mapState(["langConfig"]),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;


@mixin keyFramWatermelon($name,$orgDeg,$deg){
  @keyframes #{$name} {

    0%{
      transform: rotate($orgDeg);
    }
    50%{
      transform: rotate($deg);
    }
  }

}
//animation動畫寫在這兒
.aniTemBg{
    width: 100%;
    @include bgAni;
}


.aniTem1{
      position:absolute;
      width: 41%;
      top: 25%;
      right: 26%;
      z-index: 1;
      opacity: 0;
      @include keyFramWatermelon(lagWater,-10deg,-20deg);
      $animations: (
      upToDown 0.5s 1.5s forwards,
      lagWater 0.5s 2.0s ease-in-out infinite alternate-reverse
    );
    @include multiple_animations($animations);
}


.aniTem2{
       position:absolute;
       left:20%;
       top: 15%;
       width:70%;
       @include bgAni(0.5s,0.5s);
}

.aniTem3{
       position:absolute;
       width: 50%;
       left: 22%;
       top: 15%;
       z-index: 1;
       opacity: 0;
       transform-origin: left bottom;
       $animations: (
        leftupToDown 0.5s 1s forwards,
        );
        @include multiple_animations($animations);
}

@keyframes upToDown {
	from {
		opacity: 0;
		transform: translate3d(500px, 500px, 500px);
	}
	to {
		opacity: 1;
		transform: translate3d(0px, -32px,0px);
	}
}
@keyframes leftupToDown {
	from {
		opacity: 0;
		transform: translate3d(-400px, -500px, -500px);
	}
	to {
		opacity: 1;
		transform: translate3d(0px, -32px,0px);
	}
}

    

</style>

import { render, staticRenderFns } from "./GameItemSLA001Animation.vue?vue&type=template&id=723faf39&scoped=true&"
import script from "./GameItemSLA001Animation.vue?vue&type=script&lang=js&"
export * from "./GameItemSLA001Animation.vue?vue&type=script&lang=js&"
import style0 from "./GameItemSLA001Animation.vue?vue&type=style&index=0&id=723faf39&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "723faf39",
  null
  
)

export default component.exports
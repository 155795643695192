<template>
  <div class="gameItemBanner__img">
    <img class="aniTemBg" src="/images/gameItem/SL_A007/bg.png" />
    <img class="aniTemSymbolDragon" src="/images/gameItem/SL_A007/symbol_dragon_H1.png" />
    <img class="aniTemSymbolSuzaku" src="/images/gameItem/SL_A007/symbol_suzaku_H3.png" />
    <img class="aniTemSymbolTiger" src="/images/gameItem/SL_A007/symbol_tiger_H2.png" />
    <img class="aniTemSymbolTortoise" src="/images/gameItem/SL_A007/symbol_tortoise_H4.png" />
    <div class="dragon">
      <img class="aniTemGlow" src="/images/gameItem/SL_A007/glow.png" />
      <img class="aniTemDragon" src="/images/gameItem/SL_A007/dragon.png" />
      <img class="aniTemLBeard" src="/images/gameItem/SL_A007/GD_L_beard.png" />
      <img class="aniTemRBeard" src="/images/gameItem/SL_A007/GD_R_beard.png" />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import { sleep } from '@/utils/common';

export default {
  methods: {
    async playAnimation() {
      await sleep(1400);
      await this.showSymbol('.aniTemSymbolDragon', { x: -50, y: -20 });
      await this.showSymbol('.aniTemSymbolTortoise', { x: -50, y: 20 });
      await this.showSymbol('.aniTemSymbolTiger', { x: 50, y: 20 });
      this.showSymbol('.aniTemSymbolSuzaku', { x: 50, y: -20 });
    },
    async showSymbol(target, position) {
      return new Promise(resolve => {
        const timeline = gsap.timeline();
        const { x, y } = position;

        sleep().then(resolve);
        timeline
          .fromTo(
            target,
            { xPercent: x, yPercent: y },
            { autoAlpha: 1, xPercent: 0, yPercent: 0 },
          )
          .to(target, {
            scale: 1.15,
            duration: 2,
            yoyo: true,
            repeat: -1,
            xPercent: x > 0 ? -10 : 10,
            yPercent: y > 0 ? -10 : 10,
          });
      });
    }
  },
  mounted() {
    this.playAnimation();
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;

.aniTemBg {
  width: 100%;
  @include bgAni;
}
.dragon {
  position: absolute;
  top: -5%;
  left: 15%;
  opacity: 0;
  width: 75%;
  animation: bgPop .6s .8s forwards;
}
.aniTemDragon {
  position: relative;
  width: 95%;
  
}
.aniTemGlow {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  animation: glow 3s 1.4s infinite;
}
.aniTemLBeard {
  position: absolute;
  left: 10%;
  top: 11%;
  width: 35%;
  animation: beardL 5s 1.4s infinite;
}
.aniTemRBeard {
  position: absolute;
  right: 15%;
  top: 11%;
  width: 35%;
  animation: beardR 5s 1.4s infinite;
}
.aniTemSymbolDragon {
  position: absolute;
  bottom: 0;
  right: 5%;
  opacity: 0;
  width: 20%;
}
.aniTemSymbolSuzaku {
  position: absolute;
  bottom: -10%;
  left: 5%;
  opacity: 0;
  width: 20%;
}
.aniTemSymbolTiger {
  position: absolute;
  top: 3%;
  left: 5%;
  opacity: 0;
  width: 20%;
}
.aniTemSymbolTortoise {
  position: absolute;
  top: -10%;
  right: 8%;
  opacity: 0;
  width: 20%;
}

@keyframes glow {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}
@keyframes beardR {
  0% { transform: rotate(0); }
  50% { transform: rotate(10deg) translateX(15px) translateY(20px); }
  100% { transform: rotate(0); }
}
@keyframes beardL {
  0% { transform: rotate(0); }
  50% { transform: rotate(-10deg) translateX(-15px) translateY(20px); }
  100% { transform: rotate(0); }
}
</style>
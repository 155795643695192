<template>
<div>
 <ul class="list">
    <li v-for="(item,i) in 6" :key="i" @click="openPopup(i)"><img :src="`/images/gameItem/${gameType}/${gameType}_item0${i}.png`" /></li>
 </ul>
 <GameItemPopUpShow :gameType="gameType" v-if="popupShow" @close="closePopup" :slecetPic='slecetPic'/>
</div>
</template>

<script>
import { mapState } from "vuex";
import GameItemPopUpShow from "@/components/GameItemPopUpShow.vue";
export default {
  components: { GameItemPopUpShow },
    props:['gameType'],
  computed: {
      ...mapState(['lang']),
  },
  data() {
    return {
        popupShow:false,
        slecetPic:0,
      screenWidth: document.body.clientWidth,
    }
  },
  methods:{
      openPopup(val){
        this.popupShow=true
        this.slecetPic=val
      },
      closePopup(){
        this.popupShow=false
        this.slecetPic= 0
      },
      
  },
 
  watch: {
    $route: {
      immediate: true,
      handler: function() {
        const that = this;
        window.onresize = () => {
        return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        })();
      };
      }
    }
  }
  
};
</script>



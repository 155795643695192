<template>
<div :class="['mobmenuShow',{'oenpmenubg':showMobListFlag},{'closemenubg':closeBG}]" > 
    <img src="/images/cherrylogo_2.png"/>
	<div class="close" @click="closepop">
		<icon name="popclose" />
	</div>
	<ul>
		<li v-for="(vo, i) in menu" @click="goPage(vo.path)" :key="i">
			{{ langConfig[vo.name] }}
		</li>
		<li @click="opneShowLangList">
            {{langConfig.Language}}
			<ul :class="['mobLang',{'closeshowlan':!showLangList}]"> 
				<li v-for="(item, k) in langList" :key="k" @click="changeLang(k)">
					<span>{{ item.name }}</span>
				</li>
			</ul>
		</li>
	</ul>
</div>
</template>

<script>
import { mapState } from "vuex";
import Icon from './Icon.vue';
export default {
props:['menu','langList','showMobListFlag'],
  components: { Icon },
	computed: {
		...mapState(["lang","langConfig"]),
      
    },
	data() {
		return {
			selectedIndex: 0,
			showLangList: false,
            scrollFlag: false,
			nowComp:'',
			closeBG:false
		};
	},
	methods: {
		changeLang(index) {
            this.$emit('changeLang',index)
        },
        opneShowLangList(){
            this.showLangList = !this.showLangList
        },
        closepop(){
			this.showLangList= false
			this.closeBG = true
		
			setTimeout(()=>{
				this.closeBG = false
				this.$emit('clsoeMobPop')
			},500)
        },
		goPage(path) {
            this.$router.push(path).catch(()=>{});
						const body = document.getElementsByTagName('body')[0];
						body.style = "overflow:auto"
            this.closepop()
        },
       
    },

   
};
</script>



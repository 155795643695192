<template>
    <div class="circle cherry_chart">
        <h1>4
            <p>{{langConfig.Customers}}<br>{{langConfig.Distribution}}</p>
        </h1>
        <div v-if="now==5" class="bigShow">
            <img :src="`images/idx/chart/c.png`" class="chart">
            <img :src="`images/idx/chart/europe.png`" class="circle_word2">
            <img :src="`images/idx/chart/south.png`" class="circle_word4">
            <img :src="`images/idx/chart/north.png`" class="circle_word3">
            <img :src="`images/idx/chart/asian.png`" class="circle_word1">
            <p></p>
            <ol>
                <li class="c9">
                    5%
                </li>
                <li class="c15">
                    24%
                </li>
                <li class="c36">
                    41%
                </li>
                <li class="c25">
                    30%
                </li>
            </ol>
        </div>
        <div v-else class="chartImg">
            <img :src="`images/idx/chart/c.png`" class="Img1">
            <img :src="`images/idx/chart/circle_word3.png`" class="Img2">
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props:['now'],
    data(){
        return{
        }
    },
    computed: {
        ...mapState(['langConfig']),
    }
}
</script>
<style lang="scss">
.cherry_chart{
    height: calc(100% - 80px);
    h1{
        justify-content: center;
        font-size: 40px;
        display: flex;
        align-items:center;
        line-height:18px;
        p{
            font-size: 16px;
            padding-left: 5px;
        }
    }
    .chartImg{
        width: 85%;
        height: 85%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            position: absolute;
            opacity: 0;
        }
        .Img1{
            animation: fadeInChart 6s infinite ease forwards;
        }
        .Img2{
            animation: fadeInChart2 6s infinite ease forwards;
        }
    }
}
.big5{
    .circle.cherry_chart{
        height: calc(100% - 48px);
        width: calc(100% - 90px);
        h1{
            padding: 5px 0;
        }
        p{
            font-size: 14px;
            line-height: 15px;
        }
    }
    .bigShow{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            &.chart{
                width: 95%;
                animation: chartImg 1s ease-in-out;
            }
            &.circle_word1{
                position: absolute;
                z-index: 2;
                top: 29%;
                width: 43%;
                margin: auto;
                    opacity: 0;
                right: 0;
                animation:circle_word .5s ease-in forwards;
                left: 0;
            }
            &.circle_word2{
                position: absolute;
                z-index: 2;
                top: 40%;
                width: 43%;
                    opacity: 0;
                margin: auto;
                right: 0;
                animation:circle_word .5s .5s ease-in forwards;
                left: 0;
            }
            &.circle_word3{
                position: absolute;
                z-index: 2;
                top: 52%;
                width: 43%;
                    opacity: 0;
                margin: auto;
                right: 0;
                animation:circle_word .5s 1s ease-in forwards;
                left: 0;
            }
            &.circle_word4{
                position: absolute;
                z-index: 2;
                top: 64%;
                width: 43%;
                margin: auto;
                    opacity: 0;
                right: 0;
                left: 0;
                animation:circle_word .5s 1.5s ease-in forwards;
            }
            @keyframes circle_word {
                0% {
                    transform: translate3d(58px,0,0);
                    opacity: 0;
                }
                100% {
                    transform: translate3d(0,0,0);
                    opacity: 1;
                }
            }
        }
        ol{
            position: absolute;
            width: 100%;
            height: 100%;
            li{
                width: 23px;
                position: absolute;
                &::before{
                    content: '';
                    display: block;
                    width: 10px;
                    height: 1px;
                    position: absolute;
                    background: #000;
                    opacity: 0;
                }
                &::after{
                    content: '';
                    display: block;
                    width:130%;
                    height: 100%;
                    position: absolute;
                    margin: auto;
                    z-index: 2;
                    top: 0;
                    background: #fff;
                    transform:translate(0,0);
                }
                &.c9{
                    left: 6px;
                    bottom: 3px;
                    &::after{
                        width:90%;
                        animation: chartFonts .5s .5s ease-in-out forwards;
                    }
                    &::before{
                        right: -8px;
                        top: 14%;
                        transform: rotate(-33deg);
                        animation: chartBefore .5s .5s ease-in forwards;
                    }
                }
                &.c15{
                    right: 35px;
                    bottom: -16px;
                    &::before{
                        left: 2px;
                        transform: rotate(65deg);
                        top: -42%;
                        animation: chartBefore .5s 1s ease-in forwards;
                    }
                    &::after{
                        animation: chartFonts2 .5s 1s ease-in-out forwards;
                    }
                }
                &.c36{
                    right: -17px;
                    top: 33px;
                    &::after{
                        animation: chartFonts2 .5s 1.5s ease-in-out forwards;
                    }
                    &::before{
                        left: -10px;
                        transform: rotate(-29deg);
                        top: 66%;
                        animation: chartBefore2 .5s 1.5s ease-in forwards;
                    }
                }
                &.c25{
                    left:-25px;
                    top: 32px;
                    &::after{
                        animation: chartFonts .5s ease-in-out forwards;
                    }
                    &::before{
                        right: -20px;
                        top: 66%;
                        transform: rotate(29deg);
                        animation: chartBefore .5s ease-in forwards;
                    }
                }
            }
        }
        p{
            position: absolute;
            background: #fff;
            width:105px;
            height:105px;
            border-radius: 50%;
            box-shadow:  0px 0px 8px rgba(0,0,0,0.4) inset;
            animation: chartP 1.5s ease-in-out;
        }
    }
}
@keyframes chartBefore {
    0% {
        opacity: 1;
        background: linear-gradient(to right,transparent 100%,#000);
    }
    80% {
        background: linear-gradient(to right,transparent 0%,#000);
    }
    100% {
        background: #000;opacity: 1;
    }
}
@keyframes chartBefore2 {
    0% {
        opacity: 1;
        background: linear-gradient(to left,transparent 100%,#000);
    }
    80% {
        background: linear-gradient(to left,transparent 0%,#000);
    }
    100% {
        background: #000;opacity: 1;
    }
}
@keyframes chartFonts {
    0% {
        transform:translate( 0,0 );
    }
    80% {
        transform:translate( 0,0 );
    }
    100% {
        transform:translate(-30px,0 );
    }
}
@keyframes chartFonts2 {
    0% {
        transform:translate( 0,0 );
    }
    80% {
        transform:translate( 0,0 );
    }
    100% {
        transform:translate(30px,0 );
    }
}
@keyframes chartP {
    0% {
        transform: scale(1.3)
    }
    50% {
        transform: scale(1.3)
    }
    100% {
        transform: scale(1)
    }
}
@keyframes chartImg {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes fadeInChart2 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeInChart {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>
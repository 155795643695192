<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/XD/XD_bannerbg.png" />
        <img class="aniTem1" src="/images/gameItem/XD/XD_banneritem1.png" />
        <img class="aniTem2" src="/images/gameItem/XD/XD_banneritem2.png" />
        <img class="aniTem3" src="/images/gameItem/XD/XD_banneritem3.png" />
     
       
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
         ...mapState(["langConfig"]),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesUpDown(5%);
//animation動畫寫在這兒
.aniTemBg{
    width: 100%;
    @include bgAni
}

.aniTem2{
    position:absolute;
    left: 14%;
    top: 40%;
    width: 15%;
    opacity: 0;
    animation: bgPop .5s 0.8s forwards;
}
.aniTem1{
    position:absolute;
    right: 13%;
    top: 10%;
    width: 18%;
    opacity: 0;
    animation: bgPop .5s 0.8s forwards;
}

.aniTem3{
    position:absolute;
    left: 58%;
    bottom: 20%;
    width: 10%;
    opacity: 0;
    animation: bgPop .5s 0.8s forwards;
}
</style>

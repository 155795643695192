<template>
    <div class="homeBannerWap">
        <carousel
        :per-page="1"
        :autoplay="true"
        :loop="true"
        :autoplayHoverPause="false"
        :autoplayTimeout='4000'
        :paginationEnabled = "true"
        :navigationEnabled = "true"
        paginationColor= "rgba(255,255,255,.5)"
        paginationActiveColor = '#fff'
        >
            <slide v-for="(item, i) in bannerList" :key="i">
                <div :style="divStyle(item.id)" class="img">
                    <h1>{{item.name}}
                        <router-link :to="`/gameitem${item.id.toLowerCase()}`">{{langConfig.Explore}}</router-link>
                    </h1>
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script>

import { Carousel, Slide } from "vue-carousel";
import { mapState } from "vuex";
export default {
    components: {
        Carousel,
        Slide
    },
    data(){
        return{
            options: {
                type:'loop',
                interval:2000,
                autoplay: true,
                arrows: true,
                drag:true,
                fixedWidth:'100%',
                fixedHeight : '100vh',
                cover: true,
                focus: 'center',
                isNavigation: true,
            },
            bannerList:[],
        }
    },
    computed:{
        ...mapState(["gameList","langConfig"]),
    },
    methods: {
        divStyle(id) {
            return {'background-image':`url(images/idx/banner/${id}.jpg)`}
        },
    },
    mounted() {
        this.bannerList=this.gameList.filter(vo => {return vo.type!=='lot'})
    },
}
</script>

<style lang="scss">
@import "@/scss/rwd.scss";
.homeBannerWap{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .VueCarousel-slide{
        // position: relative;
        width: 100%;
        height: 100vh;
    }
    .img{
        // position: absolute; 
        width:100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    h1{
        padding: 35px 0;
        font-family:'DINCondensed';
        color: #fff;
        font-size: 6em;
        line-height: 90px;
        // position: absolute;
        // bottom: 25%;
        // left: 10%;
        // height: 120px;
        width: 80%;
        text-align: center;
        // margin: auto;
        text-shadow:0 0 20px rgba($color: #000000, $alpha: .8);
        z-index: 9;
        text-transform: uppercase;
        a{
            text-shadow:none;
            box-shadow:0 0 15px rgba($color: #000000, $alpha: .5);
            display: block;
            background: #fff;
            color: #000;
            line-height: 40px;
            font-size: 15px;
            border: 1px solid #fff;
            width: 95px;
            margin: auto;
            text-align: center;
            transition: all .3s linear;
            margin-top: 20px;
            &:hover{
                background: #000;
                transition: all .3s linear;
                color: #fff;
            }
        }
    }
}
</style>
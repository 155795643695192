<template>
    <div class="circle cherry_coin">
        <h1><p>{{langConfig.OurSpecial}}<br>{{langConfig.Coin}}</p>CHERRY<br>COIN</h1>
        <img class="star" src="images/idx/coin/star.png"/>
        <img class="star2" src="images/idx/coin/star2.png"/>
        <img class="coin" src="images/idx/coin/cfcoin.png"/>
        <div class='shadow'></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['langConfig']),
    }
}
</script>
<style lang="scss">
.cherry_coin{
    h1{
        font-size:30px;
        line-height:30px;
        p{
            font-size: 12px;
            line-height: 15px;
            padding-bottom: 3px;
        }
    }
    .shadow{
        position: absolute;
        width: 52%;
        right: 5px;
        overflow: hidden;
        height: 100px;
        &::after {
            content: "";
            position: absolute;
            height: 241px;
            width: 40px;
            top: -40px;
            right: 123px;
            box-shadow: 50px -23px 0 -10px rgba(255, 255, 255, 0.3), 85px -10px 0 -16px rgba(255, 255, 255, 0.25);
            animation: shine 1.5s infinite ease;
        }
        @keyframes shine {
            0% {
                transform: translate3d(82px, 43px, 0px) rotate(314deg);
            }
            50% {
                transform: translate3d(82px, 20px, 0px) rotate(314deg);
            }
            100% {
                transform: translate3d(82px, 43px, 0px) rotate(314deg);
            }
        }
    }
    img{
        position: absolute;
        &.coin{
            width: 52%;
            right: 5px;
            top: 60%;
            animation:flip 1.5s infinite ease;
        }
        @keyframes flip {
            0% {
                transform: translate3d(0, 0, 0px)
            }
            50% {
                transform: translate3d(0, -10px, 0px)
            }
            100% {
                transform: translate3d(0, 0, 0px)
            }
        }
        &.star{
            width: 20%;
            right: 0;
            top: 18%;
            animation:scale 2s infinite;
        }
        &.star2{
            width: 30%;
            left: 0;
            bottom: 0;
            animation:scale 3s infinite;
            animation-delay: .5s;

        }
        @keyframes scale {
            0% {
                transform:scale(1);
                animation-timing-function: ease-out
            }
            50% {
                transform:scale(0.7);
                animation-timing-function: ease-out
            }
            100% {
                transform: scale(1);
                animation-timing-function: ease-in
            }
        }
    }
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './action'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('cherryFun_lang') || 'en',
    gameList:[],
    langConfig:{},
  },
  mutations: {
    updatedLang(state, value) {
      state.lang = value
    },
    upLangConfig(state, value) {
      state.langConfig = value
    },
    updateGameList(state, arr){
      state.gameList = arr
    }
  },
  actions,
  modules: {
  }
})

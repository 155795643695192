<template>
    <div class="circle cherry_game">
        <h1>
            {{ gameSize }}
            <p>{{langConfig.CherryFun}}<br>{{langConfig.Games}}</p>
        </h1>
        <ul v-if="now==1" >
            <li v-for="(item, i) in 10" :key="i">
                <template v-for="(xx,j) in 2">
                    <img 
                        :key="j" :src="`images/gameIcon/${gameList[(i * random + j) % gameSize].id}.png`"
                        :class="[{'first':j==0},{'second':j==1}]" 
                        :style="`animation-delay:${0.1*i}s;`"
                    >
                </template>
            </li>
        </ul>
        <splide :options="gameOptions" v-else>
            <splide-slide v-for="(item, i) in gameList" :key="i">
                <img :src="`images/gameIcon/${item.id}.png`" style="width: 55px;">
            </splide-slide>
        </splide>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ['options', 'now'],
    data() {
        return {
            random: 0,
        }
    },
    computed: {
        ...mapState(["gameList",'langConfig']),
        gameOptions(){
            let list={
                gap: 10,
                interval:3000,
                perPage: 4,
                ...this.options
            }
            return list
        },
        gameSize({ gameList }) {
            return gameList.length;
        } 
    },
    watch: {
        now(now) {
            if (now !== 1) return;
            this.random = (Math.random() * 10 | 0) % 4 + 1;
        }
    }
}
</script>
<style lang="scss">
.big1{
    .circle{
        height: calc(100% - 80px);
    }
    ul{
        display: flex;
        overflow-y: hidden;
        justify-content: left;
        width: 100%;
        height:95px;
        flex-wrap: wrap;
        li{
            position: relative;
            margin: 0 1%;
            width:18%;
            img{
                position: absolute;
                &.first{
                    animation: translateF 8s infinite forwards;
                    z-index: 2;
                }
                &.second{
                    animation: translateS 8s infinite forwards;
                    z-index:1;
                }

            }
        }
    }
}
@keyframes translateF {
    0% {
        transform: scale(1);
        z-index: 2;
    }
    35% {
        z-index: 1;
        transform: scale(.1);
    }
    90% {
        transform: scale(1);
        z-index: 2;
    }
}
@keyframes translateS {
    0% {
        z-index: 1;
        transform: scale(.1);
    }
    40% {
        z-index: 2;
        transform: scale(1);
    }
    80% {
        transform: scale(.3);
        z-index: 1;
    }
    100% {
        transform: scale(.1);
        z-index: 1;
    }
}
</style>
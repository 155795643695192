<template>
  <div class="gameItemBanner__img">
    <img class="aniTemBg" src="/images/gameItem/SL_A014/bg.png" />
    <div class="posi dragon">
        <img class="tail"  src="/images/gameItem/SL_A014/dragon_tail.png" />
        <div class="fire"></div>  
        <img class="shadow"  src="/images/gameItem/SL_A014/dragon_shadow.png" />
        <img class="body"  src="/images/gameItem/SL_A014/dragon_body.png" />
        <img class="head" src="/images/gameItem/SL_A014/dragonhead1.png" /> 
        <img class="wing" src="/images/gameItem/SL_A014/dragon_wing.png" /> 
    </div>
    <img class="role1" src="/images/gameItem/SL_A014/warrior.png" />
    <div class="role" >
      <img class="star" src="/images/gameItem/SL_A014/star.png" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["langConfig"]),
    selectedItem() {
      return this.langList[this.selectedIndex]
    }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;
@keyframes fireRE{
  0%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A014/dragon_fire1.png');
  }
  25%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A014/dragon_fire2.png');
  }
  50%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A014/dragon_fire3.png');
  }
  75%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A014/dragon_fire4.png');
  }
  100%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A014/dragon_fire1.png') ;
  }
}

@mixin keyFramWing($name,$orgDeg,$deg){
  @keyframes #{$name} {
    0%{
      transform: rotate($orgDeg);
    }
    50%{
      transform: rotate($deg);
    }
  }
}
@keyframes moveRole {
    50%{
      transform: translate(10px,10px);
    }
}
@keyframes shake {
    10%,90%{
      transform: translate3d(-1px,-1px,-5px);
    }
    20%,80%{
      transform: translate3d(2px,2px,2px);
    }
    30%,70%{
      transform: translate3d(-4px,-4px,-4px);
    }
    40%,60%{
      transform: translate3d(4px,4px,4px);
    }
    50%{
      transform: translate3d(-4px,-4px,-4px);
    }
    
}



@keyframes role1 {
    0%{
      opacity: 0;
      transform: translate(100px,100px);
    }
    100%{
      opacity: 1;
    }
}
@keyframes star {
    0%{
      transform: rotate(0deg);
    }
    99%{
      transform: rotate(360deg);
    }
}


@keyframes upToDown {
	0% {
		opacity: 0;
		transform: translateY(-200px);
	}
  10% {
		opacity: 1;
	}
 
  90% {
		opacity: 1;
		transform: translateY(-120px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

.aniTemBg {
  width: 100%;
  opacity: 0;
  $animations:(
    bgPop 0.5s 0.5s forwards,
    shake 0.5s 2s forwards,
  );
  @include multiple_animations($animations);
}

.role{
  position: absolute;
  right: 10%;
  bottom: 5%;
  width: 25%;
  background: url('/images/gameItem/SL_A014/magician.png') no-repeat;
  height: 47%;
  background-size: 100%;
  opacity: 0;
  $animations:(
    role1 1.5s 0.5s forwards,
    shake 0.5s 2s forwards,
    moveRole 2.5s 3.5s infinite,
  );
  @include multiple_animations($animations);
  
}
.role1{
  position: absolute;
  right: 35%;
  bottom: 0;
  width: 22%;
  opacity: 0;
  $animations:(
    role1 1.5s 0.3s forwards,
    shake 0.5s 2s forwards,
    moveRole 2.5s 3s infinite,
  );
  @include multiple_animations($animations);
  
}
.star{
  position: absolute;
  left: 6%;
  top: 19%;
  width: 25%;
  animation: star 1.5s 0.5s infinite;
}

.posi{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dragon{
  img{
    position: absolute;
  }
  opacity: 0;
  animation: upToDown 1.5s 0.5s forwards;
  
  .fire{
    position: absolute;
    right: 8%;
    top: 5%;
    width: 25%;
    height: 25%;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    animation: fireRE 0.3s 1.7s infinite;
  }
  .tail{
    right: 10%;
    top: 26%;
    width: 25%;
  }
  .shadow{
    width: 50%;
    left: 20%;
    bottom: 20%;
  }

  .body{
    width: 55%;
    left: 25%;
    top: -5%;
  }
  .head{
    width: 33%;
    left: 13%;
    @include keyFramWing(head,-20deg,20deg);
    animation: head 3s 1.7s ease-in-out infinite alternate-reverse;
  }
  .wing{
    width: 30%;
    right: 12%;
    transform-origin: bottom left;
    @include keyFramWing(wing,-10deg,30deg);
    @include keyFramWing(wingfast,0deg,10deg);
    $animations: (
      wingfast 0.2s 0.7s forwards,
      wingfast 0.2s 0.9s forwards,
      wingfast 0.2s 1.1s forwards,
      wingfast 0.2s 1.3s forwards,
      wingfast 0.2s 1.5s forwards,
      wing 2s 2.0s ease-in-out infinite alternate-reverse
    );
    @include multiple_animations($animations);
  }

}
</style>

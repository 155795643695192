<template>
    <div class="comAbout">
        <section class="company">
            <div class="banner"></div>
            <div class="main">
                <h2 class="title">{{langConfig.AboutCompany}}</h2>
                <div class="content">
                    <p>{{langConfig.AboutCompanyContent_1}}</p>
                    <p>{{langConfig.AboutCompanyContent_2}}</p>
                    <p>{{langConfig.AboutCompanyContent_3}}</p>
                </div>
            </div>
        </section>
        <section class="teamPower">
            <div class="main">
                <h2 class="title">{{langConfig.TeamPower}}</h2>
                <div class="content">
                    <p>{{langConfig.TeamPowerContent_1}}</p>
                    <p>{{langConfig.TeamPowerContent_2}}</p>
                    <p>{{langConfig.TeamPowerContent_3}}</p>
                    <ul >
                        <li>{{langConfig.TeamPowerContent_3_itemList_1}}</li>
                        <li>{{langConfig.TeamPowerContent_3_itemList_2}}</li>
                        <li>{{langConfig.TeamPowerContent_3_itemList_3}}</li>
                        <li>{{langConfig.TeamPowerContent_3_itemList_4}}</li>
                        <li>{{langConfig.TeamPowerContent_3_itemList_5}}</li>
                    </ul>
                </div>
            </div>
        </section>
        <ComAboutEnthus :clientWidth="clientWidth"/>
        <section class="tech">
            <div class="main">
                <h2 class="title">{{langConfig.AboutTechnology}}</h2>
                <div class="content">
                    <p>{{langConfig.AboutTechnologyContent_1}}</p>
                    <p>{{langConfig.AboutTechnologyContent_2}}</p>
                </div>

            </div>
        </section>
        <HomeMainBiz class="biz" :clientWidth="clientWidth"/>
        <section class="vision">
            <div class="main">
                <h2 class="title">{{langConfig.Vision}}</h2>
                <div class="content">
                    <p>{{langConfig.VisionContent}}</p>
                    <ol>
                        <li>
                            <span>{{langConfig.Positioning}}</span>
                            <p>{{langConfig.PositioningContent}}</p>
                        </li>
                        <li>
                            <span>{{langConfig.Service}}</span>
                            <p>{{langConfig.ServiceContent}}</p>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import HomeMainBiz from "@/components/HomeMainBiz.vue";
import ComAboutEnthus from '@/components/ComAboutEnthus'
import { mapState } from "vuex";
export default {
    props:['clientWidth'],
    components:{
        ComAboutEnthus,
        HomeMainBiz
    },
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang="scss" >
@import "@/scss/rwd.scss";
.comAbout{
    padding-bottom: 50px;
    position: relative;
    overflow-x: hidden;
    &:before{
        position: absolute;
        content: '';
        right: -830px;
        bottom: 14%;
        width: 1022px;
        height: 1022px;
        border-radius: 1902px;
        background:rgb(247, 246, 246);
        z-index: -9999;
    }
    &:after{
        position: absolute;
        content: '';
        left: -830px;
        bottom: 0;
        width: 1022px;
        height: 1022px;
        border-radius: 1902px;
        background:rgb(247, 246, 246);
        z-index: -9999;
    }
    .banner{
        height: 465px;
        background-size: cover;
    }
    .main{
        width: 70%;
        margin: 0 auto;
        padding: 15px 0;
        max-width: 1400px;
        @include pads-width{
            width: 85%;
        }
        .title{
            font-size: 34px;
            color: #333333;
            margin-bottom: 15px;
            margin-top: 5%;
        }
        .content{
            display: flex;
            flex-direction: column;
            .paragraph{
                padding: 50px 0;
            }
        }
        p{
            color: #666;
            line-height: 40px;
            font-size: 18px;
            @include pcs-width(){
                font-size: 28px;
                line-height: 50px;
                margin: 15px 0;
            }
            @include phone-width(){
                font-size: 18px;
                margin: 10px 0;
                line-height: 30px;
            }
        }

        ul{
            li{
                font-size: 18px;
                line-height: 40px;
                list-style: disc;
                margin-left: 20px;
                @include pcs-width(){
                    font-size: 28px;
                    line-height: 50px;
                    margin-left: 26px;
                }
                @include phone-width(){
                    font-size: 18px;
                    width: 90%;
                    margin: 10px 0 10px 20px;
                    line-height: 30px;
                }
            }
        }
        ol{
            li{
                list-style: decimal;
                font-size: 18px;
                line-height: 40px;
                list-style-position: inside;
                margin-left: 5px;
                padding: 15px 0;
                @include pcs-width(){
                    font-size: 28px;
                    line-height: 50px;
                }
                @include phone-width(){
                    font-size: 18px;
                    margin: 10px auto;
                    line-height: 30px;
                }
            }
        }
    }
    .company{
        .banner{
            background-position: center center;
            background-image: url("/images/aboutus.png");
        }
        .main{
            margin-top: 30px;
        }
    }
    .teamPower{
        background-image: url('/images/comAbout/earth.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
    }
    .biz{

        padding: 30px 0;
        h2{
            width: 70%;
            margin: 0 auto;
            font-size: 34px;
            margin-bottom: 5%;
            @include pads-width{
                width: 85%;
            }
        }
    }
}
</style>
<template>
   <div class="gameItemBanner__img">
              <img class="aniTemBg" src="/images/gameItem/FLO/FLO_bannerbg.png" />
              <img class="aniTem2" src="/images/gameItem/FLO/FLO_banneritem2.png" />
              <img class="aniTem1" src="/images/gameItem/FLO/FLO_banneritem1.png" />
              <img class="aniTem3" src="/images/gameItem/FLO/FLO_banneritem3.png" />
               <img class="aniTem4" src="/images/gameItem/FLO/FLO_banneritem3.png" />
              <img class="aniTem5" src="/images/gameItem/FLO/FLO_banneritem5.png" />
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
       ...mapState(["langConfig"]),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/anicss.scss";
@include keyframesBgAni;


    @keyframes bring {
      0%{
        transform:rotate(0deg)scale(1);
      }
      20%{
         opacity: 1;
      }
      
       40%{
       transform:rotate(-180deg)scale(0.8);
       opacity: 0;
      }
      100%{
        opacity: 0;
      }
  }
     @keyframes bring2 {
      0%{
        transform:rotate(0deg)scale(1);
         opacity: 1;
      }
      30%{
         opacity: 1;
      }
      80%{
       transform:rotate(-360deg)scale(0.8);
       opacity: 0;
      }
     
     
  }
  @keyframes bringSclce {
      0%{
        transform:rotate(0deg)scale(1);
        opacity: 1;
      }
      50%{
        transform:rotate(-10deg)scale(0.7);
        opacity: 0.5;
      }
  }


//animation動畫寫在這兒
.aniTemBg{
       width: 100%;
       
   @include bgAni
}


//人物
.aniTem1{
       position:absolute;
       top: 15%;
       left:20%;
       width: 50%;
       z-index: 1;
       @include bgAni(0.5s,1s)
}
//椅子
.aniTem2{
       position:absolute;
       left:20%;
       top: 15%;
       width: 60%;
 @include bgAni(0.5s,0.6s)
}

//閃亮星
.aniTem3{
       position:absolute;
       right:5%;
       top: 33%;
       width: 20%;
       z-index: 1;
       opacity: 0;
       $animations: (
        bgPop 0.5s 3.8s linear forwards,
        bring 5s 4.2s infinite 
      );
      @include multiple_animations($animations);
}
.aniTem4{
       position:absolute;
       left:27%;
       top: 26%;
       width: 8%;
       z-index: 1;
        opacity: 0;
       $animations: (
        bgPop 0.5s 2s linear forwards,
        bring2 5s 2.5s infinite 
      );
      @include multiple_animations($animations);
}

.aniTem5{
       position:absolute;
       left:12%;
       bottom: 23%;
       width: 15%;
       z-index: 1;
       opacity: 0;
       $animations: (
        bgPop 0.5s 4.5s linear forwards,
        bring 5s 5s infinite 
      );
      @include multiple_animations($animations);
}

</style>

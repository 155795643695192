<template>
 <div class="gamePopUp" @click="closePopup">
  
     <div class="gamePopUpCenter">
     <splide :options="options"   >
      <splide-slide v-for="(i, index) in 6" :key="index" :index="index">
        <img :src="`/images/gameItem/${gameType}/${gameType}_item0${index}.png`" />
      </splide-slide>
    </splide>
     <div class="close">
       <icon name="popclose" class="popclose" />
     </div>
     </div>
 </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from './Icon.vue';
export default {
  components: { Icon },
    props:['gameType','slecetPic'],
  computed: {
      ...mapState(['lang']),

      options(){
        let p = {};

       if(this.screenWidth<1025){
             p={
            focus:'center',
            perPage: 1,
            start:this.slecetPic||0,
            autoplay:true,
            type: 'loop',
          }
           return p
        }else{
          p={
            focus:'center',
            perPage: 2,
            start:this.slecetPic||0,
            autoplay:true,
            type: 'loop',
          }
           return p
        }
      }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
    }
  },
  methods:{
      closePopup(e){
        if(e.target.nodeName =="DIV"||e.target.nodeName =="I"){
          this.$emit('close')
        }
      },
      fixedBody(){
            const body = document.getElementsByTagName('body')[0];
            body.style = "overflow:hidden"
     },
    autoBody(){
            const body = document.getElementsByTagName('body')[0];
            body.style = "overflow:auto"
    },
  },
  mounted(){
   this.fixedBody()
   
  },
  destroyed(){
    this.autoBody();
  },
  watch: {
    $route: {
      immediate: true,
      handler: function() {
        const that = this;
        window.onresize = () => {
        return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        })();
      };
      }
    }
  }
  
};
</script>

<style lang="scss" >
@import "@/scss/rwd.scss";

.gamePopUp{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    z-index: 99999;
     display: flex;
     align-items: center;
    
     .popclose{
                    display: block;
                    background: rgba($color: #fff, $alpha: 1);
                    color: #000;
                    font-size: 25px;
                    width: 50px;
                    line-height: 48px;
                    text-align: center;
                    border-radius: 50px;
                    top:14%;
                    z-index: 9;
                    cursor: pointer;
                    transform:scaleX(1);
                    transition:opacity .75s cubic-bezier(.19,1,.22,1),transform .75s cubic-bezier(.19,1,.22,1);
                    &:hover, &:active{
                      background: rgba($color: #000, $alpha: 1);
                      color: #fff;
                      transform: scale3d(1.5, 1.5, 1.5);
                      text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
                    }
                }
    .gamePopUpCenter{
        width: 100%;
        height: auto;
        position: relative;
         .close{
            position: absolute;
            top: 0px;
            right: 20px;
            width: 50px;
            height: 50px;
            color:#ccc;
            border-radius: 50px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
        }
    }

.splide{
    @include phone-width(){
        padding:10px 0;
    }
}
.splide__list{
  display: flex;
  align-items: center;
  pointer-events: none;
 
}
.splide__slide{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 5px;
  img{
    opacity:0.05;
    max-width: 845px;
    max-height: 650px;
    @include phone-width(){
     max-width: 300px;
     max-height: 400px;
     margin: auto;
    }
  }

  &.is-active{
    z-index: 999;
    img{
    opacity:1
  }
  }
}
}




</style>

<template>
	<div class="slider">
		<ul class="slider_list">
			<li
				:class="[{ playing: nowIndex === i }, 'slider_item']"
				v-for="(vo, i) in newGameList"
				:key="i"
				@click="clickTarget(i)"
			>
				<img :src="`/images/gameIcon/${vo.id}.png`" alt="" />
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["gameList"]),
		bannerGameList(){ // lot類別遊戲不用放banner
			return this.gameList.filter(vo=> {
				return vo.type !== 'lot'
			})
		},
		newGameList() {
			const arr1 = this.bannerGameList.slice(0, 5);
			const arr2 = this.bannerGameList.slice(-5);
			return [...arr2, ...this.bannerGameList, ...arr1];
		},
		calcIndex() {
			let count = this.newGameList.length;
			let arr = [];
			for (let i = count; i > count - 5; i--) {
				arr.push(i);
			}
			return arr;
		},
	},
	data() {
		return {
			nowIndex: 5,
			moveX: 210,
			timer: null,
			beginIndex: [0, 1, 2, 3, 4],
			slider: null,
			totalWidth: 0,
		};
	},
	watch: {
		nowIndex: {
			handler() {
				this.changeNowIndex();
			},
			immediate: true,
		},
	},
	methods: {
		changeNowIndex() {
			this.$emit("changeNowIndex", this.nowIndex);
		},
		move() {
			this.timer = setInterval(() => {
				this.moveX += 70;
				this.nowIndex++;
				this.slider.scrollTo({
					left: this.moveX,
					behavior: "smooth",
				});
			}, 3000);
		},
		clickTarget(i) {
			clearInterval(this.timer);
			this.nowIndex = i;
			const target = document.querySelectorAll(".slider_item")[this.nowIndex];
			this.moveX = target.offsetLeft - 150;
			this.slider.scrollTo({
				left: this.moveX,
				behavior: "smooth",
			});
			this.move();
		},
		scrollCallback() {
			if (this.slider.scrollLeft === this.moveX) {
				// console.log("scrollCallback");
				this.resetScroller();
			}
		},
		resetScroller() {
			if (this.calcIndex.includes(this.nowIndex)) {
				this.nowIndex = this.nowIndex - this.bannerGameList.length;
			}
			if (this.beginIndex.includes(this.nowIndex)) {
				this.nowIndex = this.nowIndex + this.bannerGameList.length;
			}
			const target = document.querySelectorAll(".slider_item")[this.nowIndex];
			this.moveX = target.offsetLeft - 150;
			this.slider.scrollLeft = this.moveX;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.slider = document.querySelector(".slider");
			this.slider.scrollLeft = this.moveX;
			this.slider.addEventListener("scroll", this.scrollCallback, false);
			this.move();
		});
		window.onblur = () => {
			clearInterval(this.timer);
			this.slider.removeEventListener("scroll", this.scrollCallback);
		};
		window.onfocus = () => {
			this.slider.addEventListener("scroll", this.scrollCallback, false);
			this.move();
		};
	},
	beforeDestroy() {
		clearInterval(this.timer);
		this.slider.removeEventListener("scroll", this.scrollCallback);
	},
};
</script>

<style lang="scss" scoped>
.slider {
	width: 350px;
	overflow-x: scroll;
	padding: 10px 15px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	position: absolute;
	right: 10px;
	bottom: 10px;
	&::-webkit-scrollbar {
		display: none;
	}
	ul {
		width: 3000px;
		display: flex;
		// overflow: scroll;
		li {
			width: 50px;
			margin: 0 10px;
			opacity: 0.5;
			cursor: pointer;
			&.playing {
				opacity: 1;
			}
			img {
				width: 100%;
			}
		}
	}
}
</style>

<template>
	<div class="gameItemBanner__img">
    <!-- 背景 -->
		<img class="aniTemBg" src="/images/gameItem/LON/LON_bannerbg.png" />
		<img class="aniBgLight" src="/images/gameItem/LON/LON_banneritem13.png" />
    <!-- 球 -->
		<img class="aniBall1" src="/images/gameItem/LON/LON_banneritem1.png" />
		<img class="aniBall2" src="/images/gameItem/LON/LON_banneritem2.png" />
		<img class="aniBall3" src="/images/gameItem/LON/LON_banneritem3.png" />
		<img class="aniBall4" src="/images/gameItem/LON/LON_banneritem4.png" />
		<img class="aniBall5" src="/images/gameItem/LON/LON_banneritem5.png" />
		<img class="aniBall6" src="/images/gameItem/LON/LON_banneritem6.png" />
		<img class="aniBall7" src="/images/gameItem/LON/LON_banneritem7.png" />
		<img class="aniBall8" src="/images/gameItem/LON/LON_banneritem8.png" />
    <!-- 硬幣 -->
		<img class="aniCoin1" src="/images/gameItem/LON/LON_banneritem9.png" />
		<img class="aniCoin2" src="/images/gameItem/LON/LON_banneritem10.png" />
		<img class="aniCoin3" src="/images/gameItem/LON/LON_banneritem11.png" />
		<img class="aniCoin4" src="/images/gameItem/LON/LON_banneritem12.png" />
		<!-- 光芒 -->
		<img class="aniLight1" src="/images/gameItem/LON/LON_banneritem14.png" />
		<img class="aniLight2" src="/images/gameItem/LON/LON_banneritem15.png" />
		<img class="aniLight3" src="/images/gameItem/LON/LON_banneritem16.png" />
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["langConfig"]),
		selectedItem(){
			return this.langList[this.selectedIndex]
		}
	},
	data() {
		return {}
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesRotate360(360deg);
@include keyframesUpDown(10px);
@include keyframesHeartbeat(1.1);

@mixin keyframesFallDown($position:0%){
  @keyframes fallDown{
    0%{
      opacity: 1;
      top: 0%;
    }
    100%{
      opacity: 1;
      top: $position;
    }
  }
}
//animation動畫寫在這兒
.aniTemBg{
	width: 100%;
	@include bgAni;
}
.aniBgLight{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  $animations: (
    rotate360 16s .8s infinite linear,
  );
  @include multiple_animations($animations);
}
// 紅球
.aniBall1{
  position: absolute;
  top: 39%;
  left: 37%;
  width: 25%;
  opacity: 0;
  z-index: 2;
  $animations: (
    bgPop .5s .3s forwards,
  );
  @include multiple_animations($animations);
}
// 紫球
.aniBall2{
  position: absolute;
  top: 30%;
  left: 10%;
  width: 18%;
  opacity: 0;
  $animations: (
    fallDown1 .5s .6s forwards,
    upDown 2s 1.4s infinite ,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown1{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 30%;
    opacity: 1;
  }
}
// 黑球
.aniBall3{
  position: absolute;
  top: 25%;
  left: 43%;
  width: 10%;
  opacity: 0;
  $animations: (
    fallDown2 .5s .6s forwards,
    upDown 2s 1.5s infinite ,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown2{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 25%;
    opacity: 1;
  }
}
// 黃球
.aniBall4{
  position: absolute;
  top: 65%;
  left: 23%;
  width: 18%;
  opacity: 0;
  $animations: (
    fallDown3 .5s .9s forwards,
    upDown 2.1s 1.6s infinite ,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown3{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 65%;
    opacity: 1;
  }
}
// 棕球
.aniBall5{
  position: absolute;
  top: 71%;
  left: 53%;
  width: 8%;
  opacity: 0;
  $animations: (
    fallDown4 .5s .6s forwards,
    upDown 2.1s 1.5s infinite ,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown4{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 71%;
    opacity: 1;
  }
}
// 綠球
.aniBall6{
  position: absolute;
  top: 60%;
  left: 67%;
  width: 14%;
  opacity: 0;
  $animations: (
    fallDown5 .5s .8s forwards,
    upDown 2.1s 1.6s infinite ,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown5{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 60%;
    opacity: 1;
  }
}
// 桃紅球
.aniBall7{
  position: absolute;
  top: 52%;
  left: 85%;
  width: 10%;
  opacity: 0;
  $animations: (
    fallDown6 .5s .7s forwards,
    upDown 2.1s 1.5s infinite ,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown6{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 52%;
    opacity: 1;
  }
}
// 藍球
.aniBall8{
  position: absolute;
  top: 26%;
  left: 70%;
  width: 20%;
  opacity: 0;
  $animations: (
    fallDown7 .5s .5s forwards,
    upDown 2s 1.6s infinite ,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown7{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 26%;
    opacity: 1;
  }
}
// 右上硬幣
.aniCoin1{
  position: absolute;
  width: 8%;
  left: 72%;
  top: 47%;
  opacity: 0;
  $animations: (
    fallDown8 .5s .5s forwards,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown8{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 47%;
    opacity: 1;
  }
}
// 左上硬幣
.aniCoin2{
  position: absolute;
  width: 8%;
  left: 25%;
  top: 15%;
  opacity: 0;
  $animations: (
    fallDown9 .5s .6s forwards,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown9{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 15%;
    opacity: 1;
  }
}
// 左下硬幣
.aniCoin3{
  position: absolute;
  width: 10%;
  left: 12%;
  top: 59%;
  opacity: 0;
  $animations: (
    fallDown10 .5s .4s forwards,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown10{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 59%;
    opacity: 1;
  }
}
// 右下硬幣
.aniCoin4{
  position: absolute;
  width: 10%;
  left: 77%;
  top: 77%;
  opacity: 0;
  $animations: (
    fallDown11 .5s .3s forwards,
  );
  @include multiple_animations($animations);
}
@keyframes fallDown11{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 77%;
    opacity: 1;
  }
}
// 左下光芒
.aniLight1{
  position: absolute;
  width: 10%;
  top: 54%;
  left: 14%;
  opacity: 0;
  $animations:(
    coinLight 2s 1.2s infinite linear,
  );
  @include multiple_animations($animations);
}
// 右上光芒
.aniLight2{
  position: absolute;
  width: 16%;
  top: 29%;
  left: 49%;
  z-index: 3;
  opacity: 0;
  transform-origin: -20% 100%;
  $animations: (
    ballLight 4s 1.6s infinite linear,
  );
  @include multiple_animations($animations);
}
// 右下光芒
.aniLight3{
  position: absolute;
  width: 8%;
  top: 73%;
  left: 80%;
  opacity: 0;
  $animations:(
    coinLight 2s 1.5s infinite linear,
  );
  @include multiple_animations($animations);
}
// 球反光
@keyframes ballLight{
  0%{
    opacity: 1;
    transform: rotate(0deg);
  }
  30%{
    opacity: 0;
    transform: rotate(30deg);
  }
}
// 錢幣反光
@keyframes coinLight {
  0%{
    opacity: 0;
    transform: scale(0.5);
  }
  20%{
    opacity: 1;
    transform: scale(1.1);
  }
  50%{
    opacity: 0;
    transform: scale(0.5);
  }
}
</style>
<template>
	<div class="gameItemBanner__img">
		<img class="aniTemBg" src="/images/gameItem/SSC/SSC_bannerbg.png" />
		<img class="aniTem1" src="/images/gameItem/SSC/SSC_banneritem1.png" />
		<img class="aniTemEffect1" src="/images/gameItem/SSC/SSC_banneritem2.png" />
		<img class="aniTemEffect2" src="/images/gameItem/SSC/SSC_banneritem3.png" />
		<!-- <img class="aniTemEffect3" src="/images/gameItem/SSC/SSC_banneritem3.png" /> -->
		<img class="aniTemEffect4" src="/images/gameItem/SSC/SSC_banneritem4.png" />
		<img class="aniBall1" src="/images/gameItem/SSC/SSC_banneritem5.png" />
		<img class="aniBall2" src="/images/gameItem/SSC/SSC_banneritem6.png" />
		<img class="aniCoin1" src="/images/gameItem/SSC/SSC_banneritem7.png" />
		<img class="aniCoin2" src="/images/gameItem/SSC/SSC_banneritem8.png" />
		<img class="aniCoin3" src="/images/gameItem/SSC/SSC_banneritem9.png" />
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["langConfig"]),
		selectedItem(){
			return this.langList[this.selectedIndex]
		}
	},
	data() {
		return {}
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesUpDown(10px);
@include keyframesHeartbeat(1.1);
@include keyframesRotate360;
//animation動畫寫在這兒
.aniTemBg{
	width: 100%;
	@include bgAni;
}
// 光芒1
.aniTemEffect1{
  position: absolute;
  top: 8%;
	left: 55%;
	width: 5%;
	opacity: 0;
	$animations: (
		lightEffect 2.2s 1.3s infinite linear,
	);
	@include multiple_animations($animations);
}
// 光芒2
.aniTemEffect2{
	position:absolute;
	top: 42%;
	left: 64%;
	opacity: 0;
	width: 4%;
	$animations: (
		lightEffect 3s 1.8s infinite linear,
	);
	@include multiple_animations($animations);
}
// 光芒3
// .aniTemEffect3{
// 	position:absolute;
// 	top: 37%;
// 	left: 38%;
//   width: 4%;
// 	opacity: 0;
// 	$animations: (
// 		lightEffect 2.5s 2.2s infinite linear,
// 	);
// 	@include multiple_animations($animations);
// }
// 光芒4
.aniTemEffect4{
	position:absolute;
	top: 50%;
	left: 32%;
  width: 6%;
	opacity: 0;
	$animations: (
		lightEffect 2.5s 3s infinite linear,
	);
	@include multiple_animations($animations);
}
// 大撞球
.aniTem1{
	position:absolute;
	top: 10%;
	left:35%;
	width: 33%;
	opacity: 0;
	$animations: (
		bgPop .5s .3s forwards,
		upDown 3s .8s infinite linear
	);
	@include multiple_animations($animations);
}
// 右上硬幣
.aniCoin1{
	position:absolute;
	right: 20%;
	width: 10%;
	top:15%;
	opacity: 0;
	transform-origin: -200% 400%;
	$animations: (
		bgPop .5s .3s forwards,
    upDownHeartBeat 3s .8s infinite linear
	);
	@include multiple_animations($animations);
}
// 左下硬幣
.aniCoin2{
	position:absolute;
	left:10%;
	width: 14%;
	bottom:25%;
	opacity: 0;
	transform-origin: 350% -50%;
	$animations: (
		bgPop .5s .3s forwards,
    upDownHeartBeat 2.2s 1s infinite linear
	);
	@include multiple_animations($animations);
}
// 右下硬幣
.aniCoin3{
  position: absolute;
  right:16%;
	width: 10%;
	bottom: 25%;
	opacity: 0;
	transform-origin: -300% -120%;
	$animations: (
		bgPop .5s .3s forwards,
    upDownHeartBeat 2.5s 1.2s infinite linear
	);
	@include multiple_animations($animations);
}
// 黃球
.aniBall1{
  position: absolute;
  left: 15%;
	width: 15%;
	top: 25%;
	opacity: 0;
	transform-origin: 300% 150%;
	$animations: (
		bgPop .5s .3s forwards,
    upDownHeartBeat 2.8s .8s infinite linear
	);
	@include multiple_animations($animations);
}
// 綠球
.aniBall2{
  position: absolute;
  right:8%;
	width: 12%;
	top: 32%;
	opacity: 0;
	transform-origin: -400% 150%;
	$animations: (
		bgPop .5s .3s forwards,
    upDownHeartBeat 3s 1.2s infinite linear
	);
	@include multiple_animations($animations);
}
@keyframes upDownHeartBeat {
	0% {
		opacity: 1;
		transform-origin: center;
		transform: translateY(0) scale(1)
	} 
	50% {
		opacity: 1;
		transform-origin: center;
		transform: translateY(12px) scale(1.1)
	}
	100%{
		opacity: 1;
		transform-origin: center;
		transform: translateY(0) scale(1)
	}
}
// 旋轉縮放
@keyframes lightEffect {
  0%{
    opacity: 0;
    transform: scale(0.5) rotate(0deg);
  }
  30%{
    opacity: 1;
    transform: scale(1.1) rotate(-45deg);
  }
  60%{
    opacity: 0;
    transform: scale(0.5) rotate(-90deg);
  }
}
</style>

<template>
       <div class="gameItemBanner__img">
              <img class="aniTemBg" src="/images/gameItem/A015/A015_bannerbg.png" />
              <img class="aniTem1" src="/images/gameItem/A015/A015_banneritem1.png" />
              <img class="aniTem2" src="/images/gameItem/A015/A015_banneritem2.png" />
              <img class="aniTem3" src="/images/gameItem/A015/A015_banneritem3.png" />
       </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
       ...mapState(['lang']),
       selectedItem() {
          return this.langList[this.selectedIndex]
       }
  },
  data() {
    return {}
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";

.aniTemBg {
       width: 100%;
       @include bgAni;
}

.aniTem1 {
       position: absolute;
       top: -5%;
       left: 40%;
       width: 25%;
       opacity: 0;
       $animations: (aniTem1 1s 1.2s forwards, prepare 0.5s 2.2s cubic-bezier(0, 0, 0, 4), jump 1.4s 2.7s cubic-bezier(0.2, 0, 0, 2) infinite);
       @include multiple_animations($animations);
}

@keyframes aniTem1 {
       0% {
              top: -25%;
       }
       100% {
              opacity: 1;
              top: 20%;
              transform: rotate(18deg);
       }
}

@keyframes prepare {
       to { transform: translateY(5%) scaleY(0.95) rotate(18deg); } 
}

@keyframes jump {
       0% { transform: translateY(0) scaleY(0.9) rotate(18deg); }
       50% { transform: translateY(-35%) rotate(26deg); }
       100% { transform: translateY(0) scaleY(0.9) rotate(18deg); }
}

.aniTem2 {
       position: absolute;
       top: 82%;
       left: 41%;
       width: 8%;
       opacity: 0;
       z-index: 1;
       @include keyframesUpDown(-60%);
       $animations: (aniTem2 ease-out 0.9s 0.8s forwards, upDown 0.8s 1.7s infinite);
       @include multiple_animations($animations);
}

@keyframes aniTem2 {
       0% {
              opacity: 1;
              top: 90%;
              left: 32%;
       }
       38% {
              opacity: 1;
              top: 90%;
              left: 32%;
       }
       50% {
              opacity: 1;
              top: 79%;
              left: 36%;
       }
       75% {
              opacity: 1;
              top: 75%;
              left: 38%;
       }
       100% {
              opacity: 1;
              top: 82%;
              left: 41%;
       }
}

.aniTem3 {
       position: absolute;
       top: 15%;
       left: 68%;
       width: 5%;
       opacity: 0;
       @include keyframesRotate360(360deg);
       $animations: (aniTem3 1s 2s forwards, rotate360 2s 3s infinite linear);
       @include multiple_animations($animations);
}

@keyframes aniTem3 {
       0% {
              opacity: 0;
              top: -50%;
       }
       100% {
              opacity: 1;
              top: 15%; 
       }
}
</style>
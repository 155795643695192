<template>
  <div class="gameItemBanner__img">
    <img class="aniTemBg" src="/images/gameItem/SL_A010/06_LottoX_bg.png" />
    <img class="aniTemBase" src="/images/gameItem/SL_A010/06_LottoX_03.png" />
    <img class="aniTemMain" src="/images/gameItem/SL_A010/06_LottoX_01.png" />
    <div class="fulmination">
      <img src="" ref="fulmination" class="show" />
      <img src="/images/gameItem/SL_A010/fulmination/01.png" />
      <img src="/images/gameItem/SL_A010/fulmination/02.png" />
      <img src="/images/gameItem/SL_A010/fulmination/03.png" />
      <img src="/images/gameItem/SL_A010/fulmination/04.png" />
      <img src="/images/gameItem/SL_A010/fulmination/05.png" />
    </div>
  </div>
</template>

<script>
import { sleep } from '@/utils/common';

const MAX_SIZE = 5;
let current = 0;
let time = 0;
let duration = 200;
let requestAnimation = null;

export default {
  methods: {
    async fulmination(timestamp) {
      if (timestamp - time <= duration) {
        return window.requestAnimationFrame(this.fulmination);
      }
      if (current >= MAX_SIZE) current = 0;
      current += 1;
      this.$refs.fulmination.src = `/images/gameItem/SL_A010/fulmination/0${current}.png`;
      time = timestamp;
      if (current === 5) {
        await sleep(100);
        this.$refs.fulmination.src = '';
        await sleep(1500);
      }
      window.requestAnimationFrame(this.fulmination);
    }
  },
  async mounted() {
    await sleep(2000);
    requestAnimation = window.requestAnimationFrame(this.fulmination);
  },
  beforeDestroy() {
    window.cancelAnimationFrame(requestAnimation);
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;
@include keyframesUpDown(-5px);

.aniTemBg {
  width: 100%;
  @include bgAni;
}
.aniTemBase {
  position: absolute;
  bottom: 15%;
  left: 50%;
  width: 38%;
  transform: translateX(-50%);
  transform-origin: bottom left;
  opacity: 0;
  animation: base .6s .8s forwards;
}
.aniTemMain {
  position: absolute;
  top: 25%;
  left: 32%;
  width: 38%;
  opacity: 0;
  $animations: (bgPop .6s 1.4s forwards, upDown 2s 2s infinite linear);
  @include multiple_animations($animations);
}

.fulmination {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  img {
    position: absolute;
    opacity: 0;
    width: 80%;
    transform: translateY(-50%) translateX(-50%);
  }
  .show {
    opacity: 1;
  }
}

@keyframes base {
  0% { opacity: 0; transform: scale(0) translateX(-50%);}
  100% { opacity: 1; transform: scale(1) translateX(-50%); }
}
</style>
<template>
	<div class="gameItemBanner__img">
		<img class="aniTemBg" src="/images/gameItem/FSC/FSC_bannerbg.png" />
		<img class="aniTemMain" src="/images/gameItem/FSC/FSC_banneritem1.png" />
		<img class="aniTemCoin1" src="/images/gameItem/FSC/FSC_banneritem2.png" />
		<img class="aniTemCoin2" src="/images/gameItem/FSC/FSC_banneritem3.png" />
		<img class="aniTemCoin3" src="/images/gameItem/FSC/FSC_banneritem4.png" />
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["langConfig"]),
		selectedItem() {
			return this.langList[this.selectedIndex];
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
@import "@/scss/anicss.scss";
@include keyframesUpDown;
@include keyframesBgAni(1);
@include keyframesHeartbeat(1.03);
.aniTemBg {
  width: 100%;
  animation: upToDown 1s;
}
.aniTemMain {
	position: absolute;
	top: 5%;
	left: 0;
	right: 0;
	margin: auto;
  width: 80%;
  opacity: 0;
  $animations: (
    bgPop 0.5s 0.7s forwards,
    heartbeat 3s 1.3s infinite linear
  );
  @include multiple_animations($animations);
}

.aniTemCoin1 {
	position: absolute;
	top: 50%;
  left: 32%;
  opacity: 0; 
  width: 9%;
  $animations: (
    downToUp 0.5s 1s forwards,
    upDown 2.6s 2s ease-in infinite
  );
  @include multiple_animations($animations);

}

.aniTemCoin2 {
	position: absolute;
	top: 47%;
  right: 34%;
  opacity: 0;
  width: 9%;
  $animations: (
    downToUp 0.5s 1s forwards,
    upDown 3s 1.5s ease-in infinite
  );
  @include multiple_animations($animations);
}

.aniTemCoin3 {
	position: absolute;
	top: 55%;
  right: 24%;
  opacity: 0;
  width: 9%;
  $animations: (
    downToUp 0.5s 1s forwards,
    upDown 2.8s 1.5s ease-in infinite
  );
  @include multiple_animations($animations);
}

@keyframes upToDown{
  from{
    opacity: 0;
    transform: translateY(-50px);
  }

  to{ 
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes downToUp{
  from{
    opacity: 1;
    transform:  translateY(80px) scale(0),;
  }

  to{ 
    opacity: 1;
    transform:  translateY(0px) scale(1),;
  }
}

</style>

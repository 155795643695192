<template>
    <div class="circle cherry_game">
        <h1>10
            <p>{{langConfig.Language}}<br>{{langConfig.Supported}}</p>
        </h1>
        <ul v-if="now==4">
            <li v-for="(item, i) in lanList3" :key="i">
                <img :src="`images/flag/${item}.png`"
                :style="`animation-delay:${0.1*i}s;`"
                >
            </li>
        </ul>
        <template v-else>
            <splide :options="lanOptions2">
                <splide-slide v-for="(item, i) in lanList1" :key="i">
                    <img :src="`images/idx/flag/${item}.png`" style="width: 122.5px;height: 39.19px;">
                </splide-slide>
            </splide>
            <splide :options="lanOptions">
                <splide-slide v-for="(item, i) in lanList2" :key="i">
                    <img :src="`images/idx/flag/${item}.png`" style="width: 122.5px;height: 39.19px;">
                </splide-slide>
            </splide>
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props:['options','now'],
    data(){
        return{
            lanList1:['flag_1','flag_2','flag_3'],
            lanList2:['flag_4','flag_5','flag_6'],
            lanList3:['China','France','Germany','India','Itanly','Japan','Korea','Thai','UK','Vietnam'],
        }
    },
    computed: {
        ...mapState(["gameList",'langConfig']),
        lanOptions(){
            let list={
                perPage:2,
                gap: 5,
                height:'50px',
                interval:3000,
                ...this.options
            }
            return list
        },
        lanOptions2(){
            let list={
                perPage:2,
                gap: 5,
                height:'50px',
                interval:4000,
                direction:'rtl',
                ...this.options
            }
            return list
        },
    }
}
</script>
<style lang="scss">
@keyframes translateFlag {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(.5);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}
.big4{
    ul{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        li{
            width: 45px;
            margin: 0 1%;
            img{
                animation: translateFlag 3s infinite;
            }
        }
    }
}
</style>
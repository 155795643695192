<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/LM/LM_bannerbg.png" />
        <img class="aniTem1" src="/images/gameItem/LM/LM_banneritem1.png" />
        <img class="aniTem2" src="/images/gameItem/LM/LM_banneritem2.png" />
        <img class="aniTem3" src="/images/gameItem/LM/LM_banneritem3.png" />
        <img class="aniTem4" src="/images/gameItem/LM/LM_banneritem4.png" />
        <img class="aniTem5" src="/images/gameItem/LM/LM_banneritem5.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['lang']),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
//animation動畫寫在這兒
.aniTemBg{
    width: 100%;
    @include bgAni
}
//飛入
@mixin fadein($name,$top:10%,$left:0%){
    @keyframes #{$name}{
        0%{
            opacity: 0;
           top:-100%;
            left: -100%;
            transform:scale(0)
        }
       
        100%{
           top:$top;
           left: $left;
           opacity: 1;
           transform:scale(1)
        }
    }
}

//球抖動
@keyframes ballLoop {
    0%{
        top:10%;
        left: 0;
    }

    30%{
        top:10.5%;
    }
    
    60%{
        top:10%;
        left: 0.5%;
    }
    
}

//球
.aniTem1{
    position:absolute;
    top:10%;
    left: 0;
    width: 78%;
    margin: 0 auto;
    opacity: 0;
    transform-origin: right bottom;

    @include fadein(ball1,10%,0%);
    $animations:( 
        ball1  0.2s 1s forwards,
        ballLoop 0.2s 1.3s  linear infinite  alternate-reverse
    );
    @include multiple_animations($animations);
}

//綠葉Loop
@keyframes yell{
    0%{
     opacity: 0;
     transform: scale(0);
    }
    100%{
         opacity: 1;
        transform: scale(1);
    }
}
//綠葉Loop
@keyframes leafLoop {
    0%{
       transform: scale(1);
    }
    50%{
        transform: scale(1.3);
    }
}

//綠葉
.aniTem2{
    position:absolute;
    top:5%;
    left:20%;
    width: 60%;
    opacity: 0;
    margin: 0 auto;
    $animations:( 
        yell  0.4s 1.7s forwards,
        leafLoop 3s 2.2s linear infinite alternate-reverse
    );

    @include multiple_animations($animations);
    
}

@keyframes mlLoop {
    0%{
        transform: rotate(0deg);
    }
    50%{
        top:60%;
        left:8%;
    }
    100%{
        transform: rotate(-360deg);
    }
}

//左下金幣
.aniTem3{
    position:absolute;
    top:60%;
    left:13%;
    width: 12%;
    opacity: 0;
    margin: 0 auto;
    @include fadein(m1,60%,13%);
    $animations:( 
        m1  0.2s 1.2s forwards,
        mlLoop 3s 1.4s linear infinite 
    );
     @include multiple_animations($animations);
}


@keyframes m2Loop {
    0%{
        transform: rotate(0deg);
    }
    50%{
        top:15%;
        left:38%;
    }
    100%{
        transform: rotate(360deg);
    }
}

//火裡金必
.aniTem4{
    position:absolute;
    top:18%;
    left:35%;
    width: 5%;
    margin: 0 auto;
    opacity: 0;
    @include fadein(m2,18%,35%);
    $animations:( 
        m2  0.2s 1.3s forwards,
        m2Loop 2s 1.5s linear infinite 
    );
     @include multiple_animations($animations);
}


@keyframes m3Loop {
    0%{
        transform: rotate(0deg);
    }
    50%{
        top:8%;
        left:60%;
    }
    100%{
        transform: rotate(360deg);
    }
}
//上方金幣
.aniTem5{
    position:absolute;
    top:8%;
    left:65%;
    width: 10%;
    margin: 0 auto;
    opacity: 0;
    @include fadein(m3,8%,65%);
    $animations:( 
        m3  0.2s 1.2s forwards,
        m3Loop 2.5s 1.4s linear infinite 
    );
     @include multiple_animations($animations);
}



</style>

<template>
  <div class="gameItemBanner__img">
    <img class="aniTemBg" src="/images/gameItem/SL_A006/BG.png" />
    <img class="aniTemHand" src="/images/gameItem/SL_A006/H2_L_hand.png" />
    <img class="aniTemF5" src="/images/gameItem/SL_A006/H3_L_F5.png" />
    <img class="aniTemCrown" src="/images/gameItem/SL_A006/H1_crown.png" />
    <img class="aniTemTail" src="/images/gameItem/SL_A006/H4_tail.png" />
    <div class="logoBox">
      <img class="aniTemLogo" src="/images/gameItem/SL_A006/SL_A006_LOGO.png" />
      <img class="aniTemLight1" src="/images/gameItem/SL_A006/spotlight1.png" />
      <img class="aniTemLight2" src="/images/gameItem/SL_A006/spotlight1.png" />
    </div>
    <img class="aniTemLight3" src="/images/gameItem/SL_A006/spotlight1.png" />
  </div>
</template>

<script>
import gsap from 'gsap';
import { sleep } from '@/utils/common';

export default {
  methods: {
    async playAnimation() {
      await sleep(1300);
      await this.role('.aniTemCrown');
      await this.role('.aniTemHand, .aniTemF5');
      await this.role('.aniTemTail');
      await this.shine('.aniTemLight1');
      await this.shine('.aniTemLight2');
      this.shine('.aniTemLight3');
    },
    role(target) {
      return new Promise(resolve => {
        gsap.set(target, { scaleX: 1.3, scaleY: 0 });
        gsap.to(target, {
          scaleY: 1.3,
          ease: 'elastic',
          duration: 1.5,
          autoAlpha: 1,
        });
        sleep().then(resolve);
      });
    },
    shine(target) {
      return new Promise(resolve => {
        const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1.5 });

        timeline
          .to(target, { autoAlpha: 1, rotate: -360, duration: 2 })
          .to(target, { autoAlpha: 0 })
        sleep().then(resolve);
      });
    }
  },
  mounted() {
    this.playAnimation();
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;
@include keyframesUpDown(-5px);

.aniTemBg {
  width: 100%;
  @include bgAni;
}
.logoBox {
  position: absolute;
  bottom: 15%;
  left: 16%;
  width: 100%;
  animation: upDown 2s 1.3s infinite linear;
}
.aniTemLogo {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 70%;
  animation: logo 0.5s 0.8s ease-in forwards;
}
.aniTemCrown {
  position: absolute;
  bottom: 32%;
  left: 32%;
  opacity: 0;
  width: 38%;
  transform-origin: top;
}

.aniTemHand {
  position: absolute;
  bottom: 32%;
  left: 16%;
  width: 33%;
  transform-origin: top;
  opacity: 0;
}

.aniTemF5 {
  position: absolute;
  bottom: 35%;
  right: 12%;
  opacity: 0;
  width: 33%;
  transform-origin: top;
}

.aniTemTail {
  position: absolute;
  bottom: 25%;
  right: 13%;
  transform-origin: top;
  opacity: 0;
  width: 25%;
}

.aniTemLight1 {
  position: absolute;
  bottom: 70px;
  left: 31%;
  opacity: 0;
}

.aniTemLight2 {
  position: absolute;
  bottom: 25px;
  right: 39%;
  opacity: 0;
}

.aniTemLight3 {
  position: absolute;
  bottom: 42%;
  left: 34%;
  opacity: 0;
}

@keyframes logo {
  0% { 
    transform: scale(2) rotate(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.4) rotate(360deg);
    opacity: 1;
  }
}
</style>
<template>
    <div :class="['HomeMainBiz','mainBlock']">
        <h2>{{langConfig.OurBiz}}</h2>
        <ul :class='[{"show":flag||clientWidth<1024}]' v-show="clientWidth>1024">
            <li 
                v-for="(item,i) in 7" :key="i" :style="gatStyle(item)"
                :class='{[`big${item}`]:now==item}'
                @mouseover="getBig(item,gatStyle(item),`transform: translate3d(${gatStyleBig(item)}) scale(1.4)`)"
                @mouseout="leaveBig"
            >
                <component :is="`HomeMainBizItem${item}`" :now="now" :options="options" :active='active'/>
            </li>
        </ul>
        <div>

        </div>
        <VueSlickCarousel v-bind="settings"
            v-show="clientWidth<1024"
            class="ul"
            >
                <div v-for="(item, i) in 7" :key="i" class="div">
                    <component :is="`HomeMainBizItem${item}`" :options="options"/>
                </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import { mapState } from "vuex";
import HomeMainBizItem1 from '@/components/HomeMainBizItem1.vue'
import HomeMainBizItem2 from '@/components/HomeMainBizItem2.vue'
import HomeMainBizItem3 from '@/components/HomeMainBizItem3.vue'
import HomeMainBizItem4 from '@/components/HomeMainBizItem4.vue'
import HomeMainBizItem5 from '@/components/HomeMainBizItem5.vue'
import HomeMainBizItem6 from '@/components/HomeMainBizItem6.vue'
import HomeMainBizItem7 from '@/components/HomeMainBizItem7.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    props:['clientWidth'],
    components: {
        VueSlickCarousel,
        HomeMainBizItem1,
        HomeMainBizItem2,
        HomeMainBizItem3,
        HomeMainBizItem4,
        HomeMainBizItem5,
        HomeMainBizItem6,
        HomeMainBizItem7,
    },
   data(){
        return{
            settings:{
                dots: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            active:false,
            style1:'transform: translate3d(-280px, -250px, 0)',
            style2:'transform: translate3d(-480px, 0, 0)',
            style3:'transform: translate3d(-280px, 250px, 0)',
            style6:'transform: translate3d(280px, -250px, 0)',
            style5:'transform: translate3d(480px, 0, 0)',
            style4:'transform: translate3d(280px, 250px, 0)',
            style7:{position: 'relative',transform:`scale(1.4)`},
            now:'',
            big:false,
            flag:false,
            options: {
                type:'loop',
                focus: 'center',
                arrows: false,
                pagination: false,
                autoplay: true,
                perMove:1,
                drag:false,
                swipeDistanceThreshold:'swipe'
            },
            interval:undefined
        }
    },
    computed: {
        ...mapState(["gameList",'langConfig']),
        optionsRwd(){
            return {
                focus:'center',
                type: 'slide',
                padding:{
                    top:'1rem',
                    bottom:'1rem',
                    left:'5rem',
                    right:'5rem'
                },
                gap:'3rem',
                perPage:1,
                fixedWidth: '250px',
                arrows:false,
                classes:{
                    page: 'splide__pagination__page page',
                },
                breakpoints:{
                    375:{
                        padding:{
                            left:'110px'
                        }
                    },
                    320: {
                        padding:{
                            left:'135px'
                        }
                    }
                }
            }
        }
    }, 
    watch:{
        clientWidth(v){
            if(v<1024){
                this.style1=''
                this.style2=''
                this.style3=''
                this.style6=''
                this.style5=''
                this.style4=''
                this.style7=''
            }else{
                // this.leaveBig()
            }
        }
    },
    methods:{
        gatStyle(i){
            return this[`style${i}`]
        },
        gatStyleBig(i){
            let style=[
                '-480px, 0, 0',
                '-280px, 250px, 0',
                '280px, 250px, 0',
                '480px, 0, 0',
                '280px, -250px, 0',
                '',
                '-280px, -250px, 0',
            ]
            return style[i-1]
        },
        getGameList(){
            clearInterval(this.interval) 
            let i=0
            this.interval=setInterval(() => {
                i++
                if(i%8<=5){
                    this.active=true
                }else{
                    this.active=false
                }
            }, 500);
        },
        leaveBig(){
            clearInterval(this.interval) 
            if(this.clientWidth<1024){
                return
            }
            this.now=6
            this.style6={position: 'relative',transform:`scale(1.4)`}
            this.style1='transform: translate3d(-480px, 0, 0)'
            this.style2='transform: translate3d(-280px, 250px, 0)'
            this.style3='transform: translate3d(280px, 250px, 0)'
            this.style4='transform: translate3d(480px, 0, 0)'
            this.style5='transform: translate3d(280px, -250px, 0)'
            this.style7='transform: translate3d(-280px, -250px, 0)'
        },
        getBig(i,style,big){
            if(this.clientWidth<1024||!this.big||i==6){
                return
            }
            if(style&&style.indexOf('scale')>-1){
                return
            }
            this.now=i
            this[`style${i}`]=big
            switch (i) {
                case 1:
                    this.style2='transform: translate3d(-120px, 250px, 0px)'
                    this.style3='transform: translate3d(220px, 250px, 0px)'
                    this.style4='transform: translate3d(395px, 10px, 0px)'
                    this.style5='transform: translate3d(220px, -250px, 0px)'
                    this.style6={position: 'relative',transform:`translate3d(45px, 0px, 0px)`}
                    this.style7='transform: translate3d(-120px, -250px, 0px)'
                    break;
                case 4:
                    this.style1='transform: translate3d(-395px, 10px, 0)'
                    this.style2='transform: translate3d(-220px, 250px, 0px)'
                    this.style3='transform: translate3d(120px, 250px, 0px)'
                    this.style5='transform: translate3d(120px, -250px, 0px)'
                    this.style6={position: 'relative',transform:`translate3d(-45px, 0px, 0px)`}
                    this.style7='transform: translate3d(-220px, -250px, 0px)'
                    break;    
                case 2:
                    this.style1='transform: translate3d(-355px, -100px, 0px)'
                    this.style3='transform: translate3d(220px, 250px, 0px)'
                    this.style4='transform: translate3d(395px, 10px, 0px)'
                    this.style5='transform: translate3d(220px, -250px, 0px)'
                    this.style6={position: 'relative',transform:`translate3d(45px, 0px, 0px)`}
                    this.style7='transform: translate3d(-100px, -250px, 0px)'
                    break;
                case 3:
                    this.style1='transform: translate3d(-395px, 0px, 0px)'
                    this.style2='transform: translate3d(-220px, 250px, 0px)'
                    this.style4='transform: translate3d(355px, -100px, 0px)'
                    this.style5='transform: translate3d(100px, -250px, 0px)'
                    this.style6={position: 'relative',transform:` translate3d(-65px, 0px, 0px)`}
                    this.style7='transform: translate3d(-220px, -250px, 0px)' 
                    this.getGameList()
                    break;
                 case 7:
                    this.style1='transform: translate3d(-355px, 100px, 0px)'
                    this.style2='transform: translate3d(-100px, 250px, 0px)'
                    this.style3='transform: translate3d(220px, 250px, 0px)'
                    this.style4='transform: translate3d(395px, 10px, 0px)'
                    this.style5='transform: translate3d(220px, -250px, 0px)'
                    this.style6={position: 'relative',transform:`translate3d(45px, 0px, 0px)`}
                    break;    
                default:
                    this.style1='transform: translate3d(-395px, 0px, 0px)'
                    this.style2='transform: translate3d(-220px, 250px, 0px)'
                    this.style3='transform: translate3d(100px, 250px, 0px)'   
                    this.style4='transform: translate3d(355px, 100px, 0px)'
                    this.style6={position: 'relative',transform:`translate3d(-65px, 0px, 0px)`}
                    this.style7='transform: translate3d(-220px, -250px, 0px)' 
                    break;
            }
        },
        goRound(){
            this.flag=true
            let fist=''
            clearTimeout(this.interval)
            for (let i = 0; i < 7; i++) {
                this.interval=setTimeout(() => {
                    if(i==6){
                        this.style7=fist
                        this.big=true
                        this.now=6
                    }else{
                        if(i==0){
                            fist=this.style1
                        }
                        this[`style${i+1}`]=this[`style${i+2}`]
                    }
                }, 500);
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.interval) 
    },
    mounted() {
        var self = this
        window.addEventListener('scroll', function(){
            if(document.scrollingElement.scrollTop>1800&&!self.flag&&self.clientWidth>1024){
                self.goRound()
            }
        })
    },
}
</script>

<style lang="scss">
@import "@/scss/rwd.scss";
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HomeMainBiz{
    .slick-track{
        margin: 0 auto;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 295px;
        .div{
            display: flex!important;
            flex-basis: auto;
            min-width:250px;
            width:250px !important;
            height:250px;
            margin: auto;
            border-radius: 50%;
            box-shadow: 1px 1px 30px rgba($color: #000000, $alpha:.2);
            align-items: center;
            overflow: hidden;
            justify-content: center;
            background: #fff;
            position: relative;
        }
    }
    .slick-dots{
        position: relative;
        li{
            margin:0;
            vertical-align: middle;
            button{
                border-radius: 50px;
                background: #ccc;
                height: 10px;
                width: 10px;
                position: relative;
                &:before{
                    height: 12px;
                    width: 12px;
                    line-height: 14px;
                    content: '';
                    border-radius: 50px;
                    top: -2px;
                    left: -2px;
                }
            }
            &.slick-active{
                button{
                    background: #D00D1E;
                    &:before{
                        border: 1px solid #D00D1E; 
                    }
                }
            } 
        }
    }
    img{
        width: 100%;
    }
    > ul{
        position: relative;
        min-height: 750px;
        margin:0 auto;
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        &.show{
            opacity: 1;
            animation: show 1.5s ease-out;
        }
        >li:not(.splide__slide){
            transition: all .4s linear;
            transition-timing-function: ease-out;
            min-width:250px;
            width:250px;
            height:250px;
            margin: auto;
            border-radius: 50%;
            box-shadow: 1px 1px 30px rgba($color: #000000, $alpha:.2);
            align-items: center;
            overflow: hidden;
            display: flex;
            justify-content: center;
            position: absolute;
            background: #fff;
            @include gameItemPic-width(){
                zoom: .8;
            }
        }
    }
}
.circle{
    user-select:none;
    pointer-events: none;
    position: relative;
    width: calc(100% - 98px);
    height: calc(100% - 95px);
    &.cherry_game{
        width: 100%;
        h1{
            padding: 20px 0 20px 35px;
            font-size: 50px;
            display: flex;
            align-items: center;
            p{
                font-size: 16px;
                line-height: 20px;
                padding-left: 10px;
            }
        }
        .splide{
            padding: 0;   
        }
        .splide__slide {
            img {
                transition: all .6s linear;
                opacity: 0.2;
                width: 100%;
            }
        }
        .is-active.is-visible{
            img {
                opacity:1;
                transform: translate3d(0,0,0)
            }
        }
    }
}
</style>
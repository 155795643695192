<template>
	<div class="gameItemBanner__img">
		<div id="preloadedImages">
			<img src="/images/gameItem/GT/fire/GT_01.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_02.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_03.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_04.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_05.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_06.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_07.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_08.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_09.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_10.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_11.png" alt="">
			<img src="/images/gameItem/GT/fire/GT_12.png" alt="">
		</div>
		<img class="aniTemBg" src="/images/gameItem/GT/GT_bannerbg.png" />
		<img class="aniTemGod" src="/images/gameItem/GT/GT_banneritem1.png" />
		<img class="aniTemGhost" src="/images/gameItem/GT/GT_banneritem2.png" />
		<img class="aniTemFire" src="/images/gameItem/GT/fire/GT_01.png" alt="">
		<img class="aniTemMoney" src="/images/gameItem/GT/GT_banneritem4.png" />
	</div>
</template>

<script>
import { mapState } from "vuex";
let count = 0
let requestId = null
let fire = null
export default {
	computed: {
		...mapState(["langConfig"]),
		selectedItem() {
			return this.langList[this.selectedIndex];
		},
	},
	data() {
		return {};
  },
  methods:{
    runAni(){
      if(count === 12){
        count = 0
      }
      count++
      this.changeFire()
    },
    changeFire(){
      fire.src =`/images/gameItem/GT/fire/GT_${this.addZero(count)}.png`
    },
    addZero(num){
      let str = num.toString()
      if(str.length<2){
        return `0${str}`
      }
      return str
    }
  },
  mounted() {
    requestId = setInterval(()=>{
      this.runAni()
    },100)
    fire = document.getElementsByClassName('aniTemFire')[0]
  },
  beforeDestroy(){
    clearTimeout(requestId);
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/anicss.scss";
@include keyframesUpDown;
@include keyframesBgAni;
.aniTemBg {
	width: 100%;
	opacity: 0;
	@include bgAni;
}

.aniTemGod {
	opacity: 0;
	position: absolute;
	left: 0%;
	top: 0%;
	width: 75%;
	$animations: (
		rush 1s 1s ease-out forwards,
		goAhead 6s 2s infinite ease-out
	);
	@include multiple_animations($animations);
}
.aniTemGhost {
	opacity: 0;
	position: absolute;
	top: 0%;
	right: 10%;
	width: 30%;
	z-index: 1;
  $animations: (
    upToDown 0.5s 1.5s forwards, 
    upDown 2.6s 1.8s infinite
  );
	@include multiple_animations($animations);
}

.aniTemFire {
	opacity: 0;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 1;
	animation: rush 0.3s 2.2s ease-out forwards;
}

.aniTemMoney {
	opacity: 0;
	position: absolute;
	right: 5%;
	top: 15%;
	width: 40%;
	z-index: 1;
	$animations: (appear 0.5s 3s ease-in forwards, upDown 2.6s 3s infinite);
	@include multiple_animations($animations);
}

@keyframes appear{
  from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes rush {
	from {
		opacity: 0;
		transform: translateX(-100px);
	}

	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes upToDown {
	from {
		opacity: 0;
		transform: translateY(-50px);
	}

	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes goAhead {
	0% {
		transform: translateX(0px);
	}

	50% {
		transform: translateX(15px);
	}

	100% {
		transform: translateX(0px);
	}
}
</style>

<template>
    <div>
        <section class="priv_banner"></section>
        <section class="priv_main">
            <div class="paragraph">
                <h1 class="title">{{langConfig.ResponsibleGame}}</h1>
                <p>Last updated: 14 November 2023</p>
                <div class="content">
                    <p>{{langConfig.ResponsibleGamegContent_1_listItem_1}}</p>
                    <p>
                        <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        {{langConfig.ResponsibleGamegContent_1_listItem_2}}
                    </p>
                    <p>{{langConfig.ResponsibleGamegContent_1_listItem_3}}</p>
                    <p class="zoneTitle">{{langConfig.ResponsibleGamegContent_2}}</p>
                    <p>{{langConfig.ResponsibleGamegContent_2_listItem_1}}</p>
                    <p>{{langConfig.ResponsibleGamegContent_2_listItem_2}}</p>
                    <p class="zoneTitle">{{langConfig.ResponsibleGamegContent_3}}</p>
                    <p>{{langConfig.ResponsibleGamegContent_3_listItem_1}}</p>
                    <ul>
                        <li>
                            <span>{{langConfig.ResponsibleGamegContent_3_listItem_1_1_1}}</span>
                            {{langConfig.ResponsibleGamegContent_3_listItem_1_1_2}}
                        </li>
                        <li>
                            <span>{{langConfig.ResponsibleGamegContent_3_listItem_1_2_1}}</span>
                            {{langConfig.ResponsibleGamegContent_3_listItem_1_2_2}}
                        </li>
                        <li>
                            <span>{{langConfig.ResponsibleGamegContent_3_listItem_1_3_1}}</span>
                            {{langConfig.ResponsibleGamegContent_3_listItem_1_3_2}}
                        </li>
                        <li>
                            <span>{{langConfig.ResponsibleGamegContent_3_listItem_1_4_1}}</span>
                            {{langConfig.ResponsibleGamegContent_3_listItem_1_4_2}}
                            <a alt="" href="http://www.ubetoday.com/" target="_blank">www.ubetoday.com</a>
                        </li>
                        <li>
                            <span>{{langConfig.ResponsibleGamegContent_3_listItem_1_5_1}}</span>
                            {{langConfig.ResponsibleGamegContent_3_listItem_1_5_2}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h1 class="title">{{langConfig.ResponsibleGamblingandSelfExclusion}}</h1>
                <div class="content">
                    <p>{{langConfig.ResponsibleGamblingandSelfExclusion_1}}</p>
                    <p>{{langConfig.ResponsibleGamblingandSelfExclusion_2}}</p>
                    <p>{{langConfig.ResponsibleGamblingandSelfExclusion_3}}</p>
                </div>
            </div>
            <div class="paragraph">
                <h1 class="title">{{langConfig.InformationAndContact}}</h1>
                <div class="content">
                    <p>{{langConfig.InformationAndContact_1}}</p>
                    <ul>
                        <li>
                            {{langConfig.InformationAndContact_1_1}}
                            <a alt="" href="mailto:cherryfun@cherry.fun" target="_blank">cherryfun@cherry.fun</a>
                        </li>
                    </ul>
                    <p>{{langConfig.InformationAndContact_2}}</p>
                    <p>{{langConfig.InformationAndContact_2_1}}</p>
                    <a alt="" href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/" target="_blank">https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/</a>
                    <p>{{langConfig.InformationAndContact_2_2}}</p>
                    <a alt="" href="https://www.begambleaware.org/safer-gambling/" target="_blank">https://www.begambleaware.org/safer-gambling/</a>
                </div>
            </div>
            <div class="paragraph">
                <h1 class="title">
                    {{langConfig.Helpfulhintsforresponsiblegambling}}
                    <a alt="" href="http://www.ubetoday.com/" target="_blank">http://www.ubetoday.com</a>
                </h1>
                <div class="content">
                    <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1}}</p>
                    <ul>
                        <li>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_1_1}}</p>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_1_2}}</p>
                        </li>
                        <li>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_2_1}}</p>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_2_2}}</p>
                        </li>
                        <li>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_3_1}}</p>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_3_2}}</p>
                        </li>
                        <li>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_4_1}}</p>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_4_2}}</p>
                        </li>
                        <li>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_5_1}}</p>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_5_2}}</p>
                        </li>
                        <li>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_6_1}}</p>
                            <p>{{langConfig.HelpfulhintsforresponsiblegamblingContent_1_6_2}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h1 class="title">{{langConfig.MinorProtection}}</h1>
                <div class="content">
                    <p>{{langConfig.MinorProtectionContent_1}}</p>
                    <p>{{langConfig.MinorProtectionContent_2}}</p>
                    <p>{{langConfig.MinorProtectionContent_3}}</p>
                    <a alt="" href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html" target="_blank">
                        https://famisafe.wondershare.com/internet-filter/best-internet-filters.html
                    </a>
                </div>
            </div>
            <div class="paragraph">
                <h1 class="title">{{langConfig.SelfExclusion}}</h1>
                <div class="content">
                    <p>{{langConfig.SelfExclusionContent_1}}</p>
                    <ul>
                        <li>
                            {{langConfig.SelfExclusionContent_1_1}}
                            <a alt="" href="mailto:cherryfun@cherry.fun" target="_blank">cherryfun@cherry.fun</a>
                        </li>
                    </ul>
                    <p>{{langConfig.SelfExclusionContent_2}}</p>
                    <p>{{langConfig.SelfExclusionContent_3}}</p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.priv_banner{
	height: 465px;
	background-image: url("/images/privacy.png");
    background-size: cover;
    background-position: center center;
}
.priv_main{
    width: 70%;
    margin: 30px auto;
    max-width: 1400px;
    @include pads-width{
        width: 85%;
    }
    .title{
        font-size: 34px;
        color: #0b0ee0;
        margin-bottom: 15px;
        margin-top: 5%;

        a {
          color: #0b0ee0;
          text-decoration: none;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
    }
    a {
        color: #0b0ee0;
        text-decoration: underline;
    }
    p {
        margin: 20px 10px 0 0;
        color: #031029;
        font-size: 18px;
        font-weight: 1000;
        line-height: 40px;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin: 20px 15px 0 0;
        }
        @include phone-width(){
            font-size: 18px;
            margin: 20px 10px 0 0;
            line-height: 30px;
        }
        a {
            color: #031029;
            text-decoration: none;   
        }
    }
    .zoneTitle{
       font-size: 24px;
       color: #0b0ee0;

       a {
        color: #0b0ee0;
       }
    }
    li {
        font-size: 20px;
        line-height: 40px;
        list-style: disc;
        margin-left: 20px;
        color: black;
        font-weight: 500;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin-left: 26px;
        }
        @include phone-width(){
            font-size: 18px;
            width: 90%;
            margin: 10px 0 10px 20px;
            line-height: 30px;
        }
        p {
            margin: 0;
        }

        a {
            color: black;
            font-family: auto;
            text-decoration: none;
        }

        span {
            font-size: 19px;
            font-weight: 1000;
        }
    }
}
</style>
<template>
    <header :class="clazz">
	<div  class="headercenter" >
    <img
        class="logo"
        :src="`/images/cherrylogo_${logo}.png`"
        alt=""
        @click="goPage('/')"
    />
    <ul class="menu">
        <li v-for="(vo, i) in menu" @click="goPage(vo.path)" :key="i">
            {{ langConfig[vo.name] }}
        </li>
    </ul>
    <div class="selector">
        <icon name="global" class="global" />
        <div class="selected" @click="openSelect">
            <span @click="openSelect">{{ selectedItem.name }}</span>
            <icon :name="`${showSelectList ? 'up' : 'down'}`" />
        </div>
        <ul class="select_list" v-if="showSelectList">
            <li v-for="(item, k) in langList" :key="k" @click="changeLang(k)">
                <span>{{ item.name }}</span>
            </li>
        </ul>
    </div>
    <div class="mobmenu" @click="showMobList">
        <div class="mobmenuIcon" >
            <span />
            <span />
            <span />
            <span />
            <span />
        </div>
    </div>
    <HeaderMobMenu v-show="clientWidth<1024&&showMobListFlag" :menu="menu" :langList='langList' :showMobListFlag='showMobListFlag' @changeLang='changeLang' @clsoeMobPop='colseMob'/>
	</div>
    </header>
</template>

<script>
import { mapState } from "vuex";
import Icon from './Icon.vue';
import HeaderMobMenu from "@/components/HeaderMobMenu.vue";
export default {
props:{
    clazz:String,
	logo:Number,
	selectedIndex:Number,
	langList:Array,
	
},
  components: { Icon,HeaderMobMenu },
	computed: {
		...mapState(["lang","langConfig"]),
		selectedItem() {
			return this.langList[this.selectedIndex];
		},

	

    },
	data() {
		return {
			menu: [
				{ name: "Home", path: "/" },
				{ name: "Game", path: "/game" },
				{ name: "Company", path: "/com" },
				{ name: "PrivacyPolicy", path: "/priv" },
			],
			
			showSelectList: false,
			showMobListFlag:false,
			clientWidth:0,
		};
	},
	methods: {
		openSelect() {
			this.showSelectList = !this.showSelectList;
		},
		showMobList(){
			if(this.showMobListFlag){
				const body = document.getElementsByTagName('body')[0];
				body.style = "overflow:auto"
				this.showMobListFlag =false
			}else{
				const body = document.getElementsByTagName('body')[0];
				body.style = "overflow:hidden"
				this.showMobListFlag =true

			}
		},
		colseMob(){
			const body = document.getElementsByTagName('body')[0];
            body.style = "overflow:auto"
			this.showMobListFlag =false
		},
		changeLang(index) {
			this.$emit('chgSelectedIndex',index)
			localStorage.setItem('cherryFun_lang',this.langList[index].key) // 存入localStorage
			this.$store.commit("updatedLang", localStorage.getItem('cherryFun_lang'));
			this.$store.dispatch("getLanguage");
			this.showSelectList = false;
			this.changeMetaTitle();
			this.colseMob()
		},
		changeMetaTitle(){
			if(localStorage.getItem('cherryFun_lang')=="en"){
				document.title = 'Cherry Fun - Have Cherry Have Fun'
			}else{
				document.title = '伽立方 - Have Cherry Have Fun'
				}
		},
		goPage(path) {
			this.$router.push(path).catch(()=>{});
		},
	},
	mounted() {
		let index = this.langList.findIndex((item) => {
            return item.key === this.lang;
        });
			this.$emit('chgSelectedIndex',index)
			this.clientWidth=document.body.clientWidth
	},
	
};
</script>
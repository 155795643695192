<template>
    <div  class="gameItemBg">
      <div class="gameItemBanner">
          <h1>{{gameName}}</h1>
          <GameItemTemAnimation />
      </div>
    <div class="gameItemTitle">
       <img  :src="`/images/gameIcon/${gameType}.png`" /><h2>{{gameName}}<label class="MU">MU</label></h2>
    </div>
    <div class="gameItemContent">Lorem ipsum dolor dit amet,consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore mamgna aliqua.Quis ipsum suspendisse ultrices gravida.Risus commodo viverra maeccenas accumsan kacus vel facilisis.Lorem ipsum dolor dit amet,consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore mamgna aliqua.Quis ipsum suspendisse ultrices gravida.Risus commodo viverra maeccenas accumsan kacus vel facilisis.</div>
    <div class="gameItemPic">
       <div class="gameItemPic__list">
         <GameItemPopUp :gameType="gameType"/>
         <div class="secTitle">{{langConfig.Compatible}}
           <ul class="icon"><li><img src="/images/icon_iOS.png" />iOS</li><li><img src="/images/icon_andriod.png" />Andriod</li><li><img src="/images/icon_h5.png" />HTML5</li></ul>
         </div>
          <div class="secTitle">{{langConfig.Language}}
            <ul>
              <li><img src="/images/flag/China.png" /></li>
              <li><img src="/images/flag/France.png" /></li>
              <li><img src="/images/flag/Germany.png" /></li>
              <li><img src="/images/flag/India.png" /></li>
              <li><img src="/images/flag/Itanly.png" /></li>
              <li><img src="/images/flag/Gray/Japan_gray.png" /></li>
              <li><img src="/images/flag/Gray/Korea_gray.png" /></li>
              <li><img src="/images/flag/Gray/Thai_gray.png" /></li>
              <li><img src="/images/flag/UK.png" /></li>
              <li><img src="/images/flag/Vietnam.png" /></li>
            </ul>
            <!-- for 沒有支援語系<ul>
              <li><img src="/images/flag/Gray/China_gray.png" /></li>
              <li><img src="/images/flag/Gray/France_gray.png" /></li>
              <li><img src="/images/flag/Gray/Germany_gray.png" /></li>
              <li><img src="/images/flag/Gray/India_gray.png" /></li>
              <li><img src="/images/flag/Gray/Itanly_gray.png" /></li>
              <li><img src="/images/flag/Gray/Japan_gray.png" /></li>
              <li><img src="/images/flag/Gray/Korea_gray.png" /></li>
              <li><img src="/images/flag/Gray/Thai_gray.png" /></li>
              <li><img src="/images/flag/Gray/UK_gray.png" /></li>
              <li><img src="/images/flag/Gray/Vietnam_gray.png" /></li>
            </ul> -->
         </div>
       </div>
       <div class="gameItemPic__phone">
          <img class="iphoneX" src="/images/iphoneX.png" />
          <img  class="phoneBg" :src="`/images/gameItem/${gameType}_phonebg.png`" />
          <img  class="phoneTitle" :src="`/images/gameItem/${gameType}_phonetitle.png`" />
        </div>
    </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GameItemTemAnimation from "@/components/GameItemTemAnimation.vue";
import GameItemPopUp from "@/components/GameItemPopUp.vue";
export default {
  components: {
      GameItemTemAnimation,
      GameItemPopUp
  },
  computed:{
    ...mapState(["langConfig"]),
    gameName(){
      return this.common.getGameTypeName(this.gameType)
    }
  },

  data(){
    return{
      gameType:'BW'
    }
  }
}
</script>

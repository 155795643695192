<template>
	<div class="game_main">
		<aside>
			<div class="side_bar search_bar">
				<input
					type="text"
					:placeholder="langConfig.Search"
					v-model="gameName"
					@keyup.enter="search"
				/>
				<icon name="search" @click="search" />
			</div>
			<gameFilter
				v-if="!isMobile"
				:selectedType="selectedType"
				:typeList="typeList"
				@filterType="filterType"
			/>
		</aside>
		<section>
			<div class="search_bar" >
				<div class="search_box">
					<icon name="search"  @click="search"/>
					<input
						type="text"
						:placeholder="langConfig.Search"
						v-model="gameName"
						@keyup.enter="search"
					/>
				</div>	
				<icon class="filter" name="btn_fliter" @click="openSearch()" />
			</div>
			<div class="title">
				<h1>{{ mainTitle }}</h1>
				<p>{{ langConfig.SortBy }} :</p>
				<div class="gameSelector">
					<div class="selected" @click="openSelect">
						<span>{{ langConfig[selectedItem.name] }}</span>
						<icon :name="`${showSelectList ? 'up' : 'down'}`" />
					</div>
					<ul class="select_list" v-if="showSelectList">
						<li v-for="(item, k) in sortList" :key="k" @click="orderBy(k)">
							<span>{{ langConfig[item.name] }}</span>
						</li>
					</ul>
				</div>
			</div>
			<ul class="list">
				<li v-for="(vo, i) in filterData" :key="i" @click="goPage(vo.id)">
					<img :src="`/images/gameLobby/${vo.id}.jpg`" alt="" />
					<p>{{ langConfig[vo.id] }}</p>
				</li>
			</ul>
		</section>
		<gameSearch
			:typeList="typeList"
			:selectedType="selectedType"
			:gameName="gameName"
			@search="searchGame"
			@filterType="filterType"
			@closeSearch="closeSearch"
			v-if="showSearch"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import gameSearch from "@/components/GameSearch";
import gameFilter from "@/components/GameFilter";
export default {
	props:['clientWidth'],
	components: {
		gameSearch,
		gameFilter,
	},
	watch: {
		gameName(val) {
			if (!val) {
				this.filterType(this.selectedType);
			}
		},
		clientWidth(val){
			if(val < 1024){
				this.isMobile = true
			}else{
				this.isMobile = false
			}
		}
	},
	computed: {
		...mapState(["gameList", "langConfig"]),
		selectedItem() {
			return this.sortList[this.selectedIndex];
		},
		nowGameType() {
			let name = "";
			this.typeList.forEach((item) => {
				if (item.key === this.selectedType) {
					name = this.langConfig[item.name];
				}
			});
			return name;
		},
		searchName() {
			return `${this.langConfig.Result} : ${this.gameName}`;
		},
		mainTitle() {
			return this.titleFlag ? this.searchName : this.nowGameType;
		},
	},
	data() {
		return {
			isMobile: false,
			gameName: "",
			filterData: [],
			selectedType: "all",
			showSelectList: false,
			sortList: [
				{ type: "release", name: "ReleasedDate" },
				{ type: "rank", name: "Hot" },
			],
			typeList: [
				{ key: "all", name: "AllGames" },
				// { key: "lot", name: "Lottery" },
				// { key: "ver", name: "VersusTableGames" },
				{ key: "slot", name: "SlotGames" },
				{ key: "mut", name: "MutiTableGames" },
				{ key: "cas", name: "CasualGames" },
			],
			selectedIndex: 0,
			showSearch: false,
			titleFlag: false,
		};
	},
	methods: {
		openSelect() {
			this.showSelectList = !this.showSelectList;
		},
		openSearch() {
			this.showSearch = !this.showSearch;
		},
		closeSearch() {
			this.showSearch = false;
		},
		search() {
			if(!this.gameName)return
			this.searchGame(this.gameName)
		},
		searchGame(name) {
			let gameName = name ? name : this.gameName;
	
			this.gameName = gameName.toLowerCase();
			this.titleFlag = true;
			this.filterData = this.gameList.filter((vo) => {
				let compareKey = this.langConfig[vo.id].toLowerCase();
				return compareKey.indexOf(gameName) > -1;
			});
		},
		filterType(type='all') {
			this.titleFlag = false;
			this.filterData = [];
			this.selectedType = type;
			if (this.selectedType == "all") {
				this.filterData = this.gameList;
				return;
			}
			this.filterData = this.gameList.filter((vo) => {
				return vo.type == this.selectedType;
			});
			this.orderBy(this.selectedIndex)
			if(this.gameName) this.searchGame()
			this.closeSearch()
		},
		goPage(value) {
			this.$router.push("/gameitem" + value.toLowerCase());
		},
		orderBy(index) {
			this.showSelectList = false;
			this.selectedIndex = index;
			const type = this.sortList[index].type;
			this.filterData.sort((a, b) => {
				if (type === "release") {
					if (this.transTime(b[type]) === this.transTime(a[type])) {
						return a.name.localeCompare(b.name);
					}
					return this.transTime(b[type]) - this.transTime(a[type]);
				} else {
					return b[type] - a[type];
				}
			});

			this.gameName = ""
		},
		transTime(str) {
			return new Date(str).getTime();
		},
		goTop(){
			window.scrollTo({top: 0, behavior: 'smooth'});
		}
	},
	mounted() {
		this.filterData = this.gameList;
		this.orderBy(this.selectedIndex);
		const id= this.$route.query.id
		if(id){
			this.filterType(id)
		}
	},
};
</script>

<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.game_main {
	display: flex;
	padding: 50px 0;
	width: 95%;
	max-width: 1300px;
	justify-content: center;
	margin: 0 auto;
	@include pcs-width {
		width: 100%;
		max-width: auto;
		padding-top: 0;
		display: block;
	}
	
}
aside {
	width: 15%;
	margin-right: 5%;
	@include pcs-width {
		display: none;
	}
	/deep/ .none{
		display: none;
	}
	/deep/ .side_bar {
		border-bottom: 1px solid #aaa;
		padding: 20px 0;
		position: relative;
		
		@include pcs-width {
			display: none;
		}
		input {
			border: none;
			font-size: 16px;
		}
		.v-icon {
			position: absolute;
			right: 5px;
			bottom: 20px;
			cursor: pointer;
		}
	}
	/deep/ h3 {
		padding: 15px 0 5px;
	}
	/deep/ .filter_list {
		padding: 10px 0;
		li {
			padding: 5px 0;
		}
	}
	.search_bar{
		input{
			display:block;
			width: 100%;
			background:#eee;
			padding:5px;
			border-radius: 5px;
		}
		.v-icon {
			// bottom: 25px;
			padding: 5px;
		}
	}
}
section {
	width: 80%;
	.search_bar {
		display: none;
		padding: 16px 0;
		// border-bottom: 1px solid #ccc;
		margin-bottom: 10px;
		font-size: 18px;
		color: #999;
		.search_box{
			border:1px solid #ccc;
			padding:10px;
			border-radius: 10px;
			display:flex;
			width: 83%;
			height: 40px;
			align-items: center;
			input{
				border:none;
				font-size: 16px;
				width: 100%;
			}
		}
		.v-icon {
			// display: inline-block;
			padding-right:10px;
			&.filter{
				font-size: 50px;
				width: 55px;
				padding-right: 0;
			}
		}
		@include pcs-width {
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 800px;
			margin: 0 auto;
		}
	}
	@include pcs-width {
		width: 98%;
		margin: 0 auto;
	}
	.title {
		display: flex;
		padding: 15px 0;
		justify-content: center;
		align-items: center;
		@include pcs-width {
			justify-content: space-between;
			max-width: 800px;
			margin: 0 auto;
		}
		h1 {
			font-size: 22px;
			padding-left: 1%;
		}
		p {
			flex: 1;
			text-align: right;
			@include pcs-width {
				display: none;
			}
		}
		@include pcs-width {
			padding: 5px;
			h1 {
				font-size: 18px;
			}
			p {
				display: none;
			}
		}
	}
	.list {
		display: flex;
		flex-wrap: wrap;
		padding-top: 10px;
		justify-content: space-between;
		margin: 0 1%;
		&::after{
			content: "";
			width: 26%;
			@include pads-width{
				width: 44%;
				margin: 0 auto;
			}
		}
		li {
			border-radius: 20px;
			margin-bottom: 5%;
			cursor: pointer;
			text-align: center;
			transition: 0.3s;
			width: 26%;
			@include pads-width() {
				width: 44%;
				margin: 20px auto;
				text-align: center;
			}
			img {
				max-width: 307px;
				width: 100%;
				display: inline-block;
				border-radius: 20px;
			}
			p {
				padding: 16px;
				font-size: 18px;
				font-weight: 900;
				line-height: 24px;
				@include pcs-width {
					padding-top:5px ;
				}
			}
			&:hover {
				transform: translateY(-15px);
				img {
					box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
}

/deep/ .squared {
	position: relative;
	margin: 10 0px;
	span {
		padding-left: 5px;
		cursor: pointer;
	}
	.fake {
		width: 16px;
		height: 16px;
		pointer-events: none;
		position: absolute;
		top: 2px;
		left: 0;
		// background: #45484d;
		border-radius: 50%;
		border: 1px solid #888;
		// box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4);
		&:after {
			content: "";
			width: 7px;
			height: 5px;
			position: absolute;
			top: 2px;
			left: 2px;
			border: 3px solid #fcfff4;
			border-top: none;
			border-right: none;
			background: transparent;
			opacity: 0;
			transform: rotate(-45deg);
		}
	}
	input[type="radio"] {
		visibility: hidden;
		&:checked + .fake:after {
			opacity: 1;
		}
		&:checked + .fake {
			background: #333;
		}
	}
}
.gameSelector {
	width: 180px;
	height: 30px;
	border: 1px solid #fff;
	border-radius: 5px;
	position: relative;
	padding:  5px 0 0;
	@include pcs-width {
		// display: none;
		// width: 150px;
	}
	cursor: pointer;
	.v-icon {
		position: absolute;
		right: 10px;
	}
	img {
		width: 20px;
		&.arrow {
			width: 16px;
		}
	}
	span {
		padding: 0 10px;
	}
	.select_list {
		position: absolute;
		border: 1px solid #fff;
		width: 180px;
		padding-bottom: 10px;
		left: -1px;
		top: 29px;
		border-radius: 0 0 5px 5px;
		border-top: none;
		background-color: #fff;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
		z-index: 2;
		@include pcs-width {
			// display: none;
			// width: 150px;
		}
		li {
			padding: 8px 10px;
			align-items: center;
			&:hover {
				background: #bbb;
			}
		}
	}
}
</style>

<template>
   <div class="gameItemBanner__img">
      <img class="aniTemBg" src="/images/gameItem/A017/A017_bg.png" />
      <img class="aniTemDealer" src="/images/gameItem/A017/A017_candy.png" />
      <img class="aniTemLogo" src="/images/gameItem/A017/A017_logo.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
      ...mapState(['lang']),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;
@include keyframesUpDown;
@include keyframesHeartbeat(1.05);
.aniTemBg{
    width: 100%;
    @include bgAni;
}
.aniTemLogo{
    position:absolute;
    bottom: 10%;
    left:0;
    right:0;
    margin:auto;
    width: 50%; 
    opacity:0; 
    $animations: (
      bgPop 0.5s 0.5s forwards,
      heartbeat 4s 1s ease-in infinite
    );
    @include multiple_animations($animations); 
}
.aniTemDealer{
    position:absolute;
    bottom: 30%;
    left:0;
    right:0;
    margin:auto;
    width: 50%;  
    opacity: 0; 
    $animations: (
      downToUp 0.5s 1s forwards,
      upDown 3s 2s ease-in infinite
    );
    @include multiple_animations($animations);   
}

@keyframes downToUp{
  0%{
    opacity: 1;
    transform:scale(0) translateY(0px);
  }
  40%{
    opacity:1;
    transform:scale(1.5) translateY(180px);
  }

  100%{ 
    opacity: 1;
    transform:scale(1) translateY(0px) ;
  }
}

@keyframes upToDown {
	from {
		opacity: 0;
		transform: translateY(-50px);
	}

	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

</style>

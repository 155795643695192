<template>
	<div class="game_banner">
		<Slider @changeNowIndex="changeNowIndex" />
		<!-- <splide ref="primary" @splide:moved="onArrowsMounted" :options="options">
			<splide-slide v-for="(item, i) in gameList" :key="i">
				<img :src="`images/gameIcon/${item.id}.png`" />
			</splide-slide>
		</splide> -->
	</div>
</template>

<script>
import { mapState } from "vuex";
import Slider from '@/components/Slider.vue'
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
	components: {
		// Splide,
		// SplideSlide,
		Slider
	},
	computed: {
		...mapState(["gameList"]),
	},
	watch: {
		nowIndex(){
			this.onArrowsMounted()
		}
	},
	data() {
		return {
			nowIndex:5,
			options: {
				type: "loop",
				perPage: 5,
				focus: "center",
				arrows: false,
				pagination: false,
				padding:'0.5rem',
				autoplay: true,
				gap: 10,
				perMove: 1,
                interval: 3000,
                start:0,
			},
		};
	},
	methods: {
		changeNowIndex(num){
			this.nowIndex = num
		},
		onArrowsMounted() {
			const dom = document.getElementsByClassName("game_banner")[0];
			setTimeout(() => {
				let target = document.querySelector(".playing img");
				let src = target.src.split("/");
				let banner = src[src.length - 1].replace("png", "jpg");
				dom.style.backgroundImage = `url(/images/gameBanner/${banner})`;
			}, 10);
        },
        // goToSlide(target){
		// 	let index = this.gameList.findIndex(item=>{
		// 		return item.id ===  target
		// 	})
		// 	console.log('index',index)
		// 	this.$refs.primary.go(index);
        // }
	},
	mounted() {
        this.onArrowsMounted()
        // this.$nextTick(()=>{
        //     const dom = document.getElementsByClassName('splide__slide')
        //     for(let i=0;i<dom.length;i++){
        //         dom[i].addEventListener('click',(event)=>{
        //             let arr = event.target.src.split('/')
        //             let target = arr[arr.length - 1].replace(".png", "")
        //             this.goToSlide(target)
        //             console.log('event',target);
                    
        //         })
        //     }
        // })
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/rwd.scss';
.game_banner {
	background: url("/images/gameBanner/04_junglewar.jpg") no-repeat;
	transition: background-image 0.2s ease-in-out ;
	background-size: cover;
	background-position: center top;
    position: relative;
	padding-top: 32%;

	@include pads-width(){
		padding-top: 33%;
	}
  
	.splide {
		width: 327px;
		position: absolute;
		bottom: 20px;
		right: 20px;
		padding: 0;
	}
	.splide__slide {
		opacity: 0.5;
		cursor: pointer;
		img {
			width: 100%;
		}
	}
	.is-active.is-visible {
		opacity: 1;
	}
	/deep/ .splide__track{
		background:rgba(#000,.5);
		padding:16px;
		padding-right: 20px;
		border-radius: 10px;
	}
}
</style>

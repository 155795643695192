<template>
 <div class="hotGame">
     <div class="secTitle">{{langConfig.HotGames}}<span class="more" @click="goGame">{{langConfig.More}}</span></div>
    <ul>
        <li v-for="(item,i) in getGameList" :key='i' @click="goPage(item.id)"><img  :src="`/images/gameIcon/${item.id}.png`" /><h2>{{langConfig[item.id]}}</h2></li>
    </ul>
 </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props:['gameType'],
  computed: {
      ...mapState(['langConfig','gameList']),
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      getGameList:[],

    }
  },
  methods:{
    goGame(){
      this.$router.push("/game");
    },
    goPage(value) {
			this.$router.push("/gameitem" + value.toLowerCase());
		},
     getHotGame(){
        let list=[];
        let type = this.gameList.filter((vo) => {
				return vo.id == this.gameType;
            });
        list =  this.gameList.filter((vo) => {
				return vo.id != this.gameType&&vo.type==type[0].type;
        });
        let otherList = this.gameList.filter(vo => { // 其他類別的遊戲
            return vo.type!==type[0].type
        })
        let result =[]
        let ranNum = list.length<6?list.length:6; // lot類別不足6個
        let lackNum = 6 - ranNum;
        for(let i =0; i<ranNum; i++){
          var ran = Math.floor(Math.random() * list.length);
          result.push(list.splice(ran, 1)[0])
        }
        // 不足6個從其他類別補
        if(lackNum > 0){
          for(let i =0; i<lackNum; i++){
            var item = Math.floor(Math.random() * otherList.length);
            result.push(otherList.splice(item, 1)[0])
          }
        }
        this.getGameList = result;
     }
   
  },

  mounted(){
      this.getHotGame();
  }
  
};
</script>

<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.hotGame{
     width: 1300px;
        margin: 3% auto;
         @include gameItemPic-width(){
      width: 90%;
     }
    ul{
       width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
         @include pads-width(){
            flex-wrap:wrap;
          }
          @include plus-phone-width(){
          justify-content: center;
        }
          li{
              margin: 3%;
              line-height: 30px;
              transition: 0.3s;
              text-align: center;
                 @include plus-phone-width(){
                    max-width: 27%;
                    justify-content: center;
                    line-height: 24px;
                }
             
              cursor: pointer;
              position: relative;
         
              img{
                width: 100%;
                border-radius:15%;
                
              }
              &:hover,
              &:active{
                transform: translateY(-15px);
                   img {
                    
                     box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.3);
                   }
                  }
                }
    }
        
 .secTitle{
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 28px;
    padding: 15px 0;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include plus-phone-width(){
        font-size: 24px;
      }
    .more{
      cursor: pointer;
      &:hover,
      &:active{
        color: #ccc;
      }
    }
 }
}
</style>

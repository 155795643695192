<template>
  <div class="enthusAni" :class="{'aniPlay':clientWidth<=768 || playAniItem==='Knowledge'}">
    <img src="images/comAbout/knowledge/knowledge_shadow.png" class="aniShadow" alt="">
    <div class="aniMain"></div>
    <div class="aniBulb"></div>
    <div id="preloadedImages">
        <img src="/images/comAbout/knowledge/knowledge_1.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_2.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_3.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_4.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_5.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_6.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_7.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_bulb_light.png" alt="">
        <img src="/images/comAbout/knowledge/knowledge_bulb.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  props:['playAniItem','clientWidth'],
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "../scss/anicss.scss";
@include keyframesUpDown(-10px);
// 基本定位
// 影子
.aniShadow{
  position: absolute;
  width: 120%;
  left: -8%;
  top: -3%;
  @include pads-width{
    top: 12%;
  }
}
// 主要人物
.aniMain{
  position: absolute;
  width: 120%;
  height: 120%;
  left: -8%;
  top: -15%;
  background-image: url('/images/comAbout/knowledge/knowledge_1.png');
  background-repeat: no-repeat;
  @include pads-width{
    top: 0%;
  }
}
// 燈泡
.aniBulb{
  position: absolute;
  width: 120%;
  height: 120%;
  left: -8%;
  top: -10%;
  opacity: 0;
  background-image: url('/images/comAbout/knowledge/knowledge_bulb_light.png');
  background-repeat: no-repeat;
  transform-origin: 30% 30%;
  @include pads-width{
    top: 2%;
  }
}
// 動畫樣式
.aniPlay{
  .aniMain{
    $animations:(
      flipBook .8s infinite linear,
      upDown 2s  infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniBulb{
    $animations:(
      bulbPop .8s forwards,
      bulbLight 1.2s .8s  infinite linear,
    );
    @include multiple_animations($animations);
  }
}
@keyframes flipBook{
  0%{
    background-image: url('/images/comAbout/knowledge/knowledge_1.png');
  }
  14%{
    background-image: url('/images/comAbout/knowledge/knowledge_2.png');
  }
  28%{
    background-image: url('/images/comAbout/knowledge/knowledge_3.png');
  }
  42%{
    background-image: url('/images/comAbout/knowledge/knowledge_4.png');
  }
  56%{
    background-image: url('/images/comAbout/knowledge/knowledge_5.png');
  }
  70%{
    background-image: url('/images/comAbout/knowledge/knowledge_6.png');
  }
  80%{
    background-image: url('/images/comAbout/knowledge/knowledge_7.png');
  }
}
@keyframes bulbPop{
  0%{
    opacity: 1;
    transform: scale(0);
  }
  40%{
    opacity: 1;
    transform: scale(1.1);
  }
  100%{
    transform:scale(1);
    opacity: 1;
  }
}
@keyframes bulbLight{
  0%{
    opacity: 1;
  }
  60%{
    opacity: 1;
    background-image: url('/images/comAbout/knowledge/knowledge_bulb_light.png');
  }
  70%{
    background-image: url('/images/comAbout/knowledge/knowledge_bulb.png');
    opacity: 1;
  }
  80%{
    opacity: 1;
    background-image: url('/images/comAbout/knowledge/knowledge_bulb_light.png');
  }
  90%{
    background-image: url('/images/comAbout/knowledge/knowledge_bulb.png');
    opacity: 1;
  }
  100%{
    opacity: 1;
    background-image: url('/images/comAbout/knowledge/knowledge_bulb_light.png');
  }
}
</style>
<template>
    <div class="circle cherry_game cherry_device">
        <h1>5
            <p>{{langConfig.Devices}}<br>{{langConfig.Supported}}</p>
        </h1>
        <ul v-if="now==3">
            <li v-for="(item, i) in deviceList" :key="i" 
                :class="{'activex':active}"
                :style="`animation-delay:${0.3*i}s;`">
                <img :src="`images/idx/devices/${item}.png`">
            </li>
        </ul>
        <splide :options="deviceOptions" v-else>
            <splide-slide v-for="(item, i) in deviceList" :key="i">
                <img :src="`images/idx/devices/${item}.png`" style="width: 75px;">
            </splide-slide>
        </splide>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props:['options','now','active'],
    data(){
        return{
            deviceList:['html','andriod','iOS','mac','windows'],
        }
    },
    computed: {
        ...mapState(["gameList",'langConfig']),
        deviceOptions(){
            let list={
                perPage: 2,
                gap: 30,
                fixedWidth:'30%',
                interval:3500,
                ...this.options
            }
            return list
        },
    }
}
</script>
<style lang="scss">
.cherry_device{
    .splide__slide {
        img {
            transition: all .3s linear;
            transform:scale(0.7,0.7)
        }
    }
    .is-active.is-visible{
        img {
            transform:scale(1,1)
        }
    }
}   
.big3{
    .circle.cherry_game{
        height: calc(100% - 80px);
        h1{
            padding-bottom:10px;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        li{
            width: 50px;
            margin: 0 3%;
            &.activex{
                animation: logos 1s ease-in-out;
            }
        }
    }
}
@keyframes logos {
    0% {
        transform:translate( 0,0 );
    }
    30% {
        transform:translate( 0,-10px );
    }
    100% {
        transform:translate( 0,0 );
    }
}
</style>
<template>
  <div class="enthusAni" :class="{'aniPlay':clientWidth<=768 || playAniItem==='Motivation'}">
    <img src="images/comAbout/motivation/motivation_leaf.png" class="aniLeaf" alt="">
    <img src="images/comAbout/motivation/motivation.png" class="aniMain" alt="">
    <div class="aniSpark"></div>
    <div class="aniSmoke"></div>
    <div class="aniWheel"></div>
    <div id="preloadedImages">
      <img src="/images/comAbout/motivation/motivation_smoke1.png" alt="">
      <img src="/images/comAbout/motivation/motivation_smoke2.png" alt="">
      <img src="/images/comAbout/motivation/motivation_smoke3.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  props:['playAniItem','clientWidth'],
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "../scss/anicss.scss";
@include keyframesUpDown(1px);
// 基本定位
.aniMain,.aniLeaf{
  position: absolute;
  width: 120%;
  left: -5%;
  @include pads-width{
    top: 10%;
  }
}
.aniLeaf{
  opacity: 0;
}
.aniSpark{
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5%;
  left: -7%;
  opacity: 0;
  @include pads-width{
    top: 5%;
  }
}
.aniSmoke{
  position: absolute;
  top: -2%;
  left: 5%;
  width: 130%;
  height: 100%;
  opacity: 0;
  @include pads-width{
    top: 15%;
  }
}
.aniWheel{
  position: absolute;
  top: -5%;
  width: 120%;
  height: 120%;
  left: -12%;
  opacity: 0;
  @include pads-width{
    top: 7%;
  }
}
// 動畫樣式
.aniPlay{
  .aniMain{ // 兔子
    $animations: (
      upDown 0.3s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniLeaf{ // 葉子
    $animations: (
      leaf 2s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniSpark{ // 火花
    $animations: (
      spark .1s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniSmoke{ // 煙
    $animations: (
      smoke 1s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniWheel{ // 輪胎
    $animations: (
      wheel .3s infinite linear,
    );
    @include multiple_animations($animations);
  }
}
@keyframes spark {
  0% {
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_effect1.png');
  }
  50% {
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_effect2.png');
  }
  100% {
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_effect3.png');
  }
}
@keyframes smoke {
  0%{
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_smoke1.png');
  }
  50%{
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_smoke2.png');
  }
  100%{
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_smoke3.png');
  }
}
@keyframes wheel {
  0%{
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_wheel1.png');
  }
  50%{
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_wheel2.png');
  }
  100%{
    opacity: 1;
    background-image: url('/images/comAbout/motivation/motivation_wheel3.png');
  }
}
@keyframes leaf {
  0%{
    opacity: 1;
    transform: translate(0,0);
  }
  80%{
    transform: translate(20%,15%);
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
</style>
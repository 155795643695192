<template>
	<div class="game_banner">
		<splide ref="primaryM" :options="options">
			<splide-slide v-for="(item, i) in bannerGameList" :key="i" >
				<img :src="`/images/gameBanner/M/${item.id}.jpg`" />
			</splide-slide>
		</splide>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
	components: {
		Splide,
		SplideSlide,
	},
	computed: {
		...mapState(["gameList"]),
		bannerGameList(){ // lot類別遊戲不用放banner
			return this.gameList.filter(vo=> {
				return vo.type !== 'lot'
			})
		},
	},
	data() {
		return {
			options: {
				type: "loop",
				focus: "center",
				arrows: false,
				pagination: false,
				autoplay: true,
				interval: 3000,
				// width:'100%',
				// fixedWidth:'100%',
				// autoHeight:true,
			},
		};
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.game_banner {
	width: 100%;
	max-height: 200px;

	@include small-phone-width(){
		max-height: 150px;
	}

	.splide {
		padding: 0;
		.splide__slide {
			max-height: 200px;
		}
		img {
            width: 100%;		
			// height: 100%;
			}
	}
	.is-active.is-visible {
		opacity: 1;
	}
}
</style>

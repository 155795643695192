<template>
  <div class="enthusAni" :class="{'aniPlay':clientWidth<=768 || playAniItem==='Team'}">
    <img src="images/comAbout/team/light.png" class="aniLight" alt="">
    <div class="aniMan2"></div>
    <div class="aniMan1"></div>
    <div class="aniEffect">
      <img :src="`images/comAbout/team/effect_light.png`" :class="`aniEffect${i}`" v-for="i in 8" :key="i" alt="">
    </div>
  </div>
</template>
<script>
export default {
  props:['playAniItem','clientWidth'],
  data(){
    return{
      index1:0,
      index2:0,
    }
  },
  methods:{
    getRandom(min,max){
      return Math.floor(Math.random()*(max-min+1))+min;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "../scss/anicss.scss";
@include keyframesRotate360(360deg);
@include keyframesBgAni(1.1);
@include keyframesUpDown(-5px);
// 基本定位

// 背景光
.aniLight{
  position: absolute;
  width: 120%;
  left: -8%;
  @include pads-width{
    top: 8%;
  }
}
// 主要人物
// 左
.aniMan1{
  position: absolute;
  background-image: url('/images/comAbout/team/man1-1.png');
  background-repeat: no-repeat;
  width: 120%;
  height: 120%;
  left: -14%;
  top: -3%;
  opacity: 1;
  @include pads-width{
    top: 8%;
  }
}
// 右
.aniMan2{
  position: absolute;
  background-image: url('/images/comAbout/team/man2-1.png');
  background-repeat: no-repeat;
  width: 120%;
  height: 120%;
  left: -12%;
  top: -5%;
  @include pads-width{
    top: 8%;
  }
}
// effect
.aniEffect{
  img{
    position: absolute;
    opacity: 0;
  }
  .aniEffect1{
    left: 60%;
  }
  .aniEffect2{
    left: -8%;
    top: 20%;

  }
  .aniEffect3{
    top: 60%;
    left: -7%;
  }
  .aniEffect4{
    top: 65%;
    right: 0%;
  }
  .aniEffect5{
    top: 0;
    left: 10%;
  }
  .aniEffect6{
    right: -2%;
    top: 20%;
  }
  .aniEffect7{
    top: 70%;
    left: 20%;
  }
  .aniEffect8{
    right: 2%;
    top: 0%;
  }
}

// 動畫樣式
.aniPlay{
  .aniLight{
    $animations: (
      rotate360 20s infinite linear ,
    );
    @include multiple_animations($animations);
  }
  .aniMan1{
    $animations: (
      fight1 .3s infinite linear alternate-reverse,
      upDown 3s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniMan2{
    $animations: (
      fight2 .3s infinite linear alternate-reverse,
      upDown2 3s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect1,.aniEffect2{
    $animations: (
      effect 1.8s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect3,.aniEffect4{
    $animations: (
      effect 1.8s .4s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect5,.aniEffect6{
    $animations: (
      effect 1.8s .8s infinite linear,
    );
    @include multiple_animations($animations);
  }
  .aniEffect7,.aniEffect8{
    $animations: (
      effect 1.8s 1.2s infinite linear,
    );
    @include multiple_animations($animations);
  }
}
@keyframes fight1 {
  0%{
    background-image: url('/images/comAbout/team/man1-1.png');
  }
  100%{
    background-image: url('/images/comAbout/team/man1-2.png');
  }
}
@keyframes fight2 {
  0%{
    background-image: url('/images/comAbout/team/man2-1.png');
  }
  100%{
    background-image: url('/images/comAbout/team/man2-2.png');
  }
}
@keyframes effect {
  0%{
    opacity: 1;
    transform: scale(0);
  }
  5%{
    transform: scale(1);
    opacity: 1;
  }
  20%{
    transform: scale(1);
  }
  30%{
    transform: scale(0);
  }
  100%{
    transform: scale(0);
  }
}
@keyframes upDown2 {
  0% {
    opacity: 1;
    transform: translateY(0)
  } 
  50% {
    opacity: 1;
    transform: translateY(5px)
  }
  100%{
    opacity: 1;
    transform: translateY(0)
  }
}
</style>
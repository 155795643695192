<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/SL_A013/BG.png" />
        <img class="ship1" src="/images/gameItem/SL_A013/Ship_01.png" />
        <img class="ship2" src="/images/gameItem/SL_A013/Ship_02.png" />
        <img class="ship3" src="/images/gameItem/SL_A013/Ship_01.png" />
        <img class="ship4" src="/images/gameItem/SL_A013/Ship_02.png" />
        <img class="brokenShip1" src="/images/gameItem/SL_A013/S_damage_02.png" />
        <img class="brokenShip2" src="/images/gameItem/SL_A013/S_damage_01.png" />
        <img class="bombCenter" src="/images/gameItem/SL_A013/bomb_01.png" />
        <img class="bombLeft" src="/images/gameItem/SL_A013/bomb_01.png" />
        <img class="bombRight" src="/images/gameItem/SL_A013/bomb_01.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["langConfig"]),
        selectedItem() {
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesUpDown(3px);
.aniTemBg {
    width: 100%;
    @include bgAni;
}
.ship1 {
    position: absolute;
    opacity: 0;
    width: 15%;
    $animation: (ship1 3s 1s forwards, upDown 1.5s 1.5s infinite);
    @include multiple_animations($animation);
}
@keyframes ship1 {
    0% {
        left: 0;
        top: 49%;
    }
    100% {
        left: 5%;
        top: 54%;
        opacity: 1;
    }
}
.ship2 {
    position: absolute;
    opacity: 0;
    right: 4%;
    width: 10%;
    $animation: (ship2 2.5s 1s forwards, upDown 1.5s 2.5s infinite);
    @include multiple_animations($animation);
}
@keyframes ship2 {
    0% {
        top: 40%;
    }
    100% {
        top: 35%;
        opacity: 1;
    }
}
.ship3 {
    position: absolute;
    opacity: 0;
    bottom: 2%;
    width: 15%;
    transform: rotate(35deg) scaleX(-1);
    $animation: (ship3 2.5s 1s forwards, disappear .6s 2.6s forwards);
    @include multiple_animations($animation);
}
@keyframes ship3 {
    0% {
        right: 17%;
    }
    100% {
        right: 23%;
        opacity: 1;
    }
}
.ship4 {
    position: absolute;
    opacity: 0;
    left: 9%;
    width: 10%;
    $animation: (ship4 1s 1s forwards, disappear .6s 2.3s forwards);
    @include multiple_animations($animation);
}

@keyframes ship4 {
    0% {
        top: 21%;
    }
    100% {
        top: 16%;
        opacity: 1;
    }
}
.bombCenter {
    position: absolute;
    opacity: 0;
    right: 36%;
    width: 10%;
    top: 35%;
    $animation: (bomb .6s 1.5s, bomb3 .6s 4s);
    @include multiple_animations($animation);
}
.bombLeft {
    position: absolute;
    opacity: 0;
    left: 8%;
    top: 10%;
    width: 10%;
    $animation: (bomb .6s 2.1s, bomb2 .6s 3.4s);
    @include multiple_animations($animation);
}
.bombRight {
    position: absolute;
    opacity: 0;
    right: 25%;
    bottom: 10%;
    width: 10%;
    $animation: (bomb .6s 2.4s, );
    @include multiple_animations($animation);
}
@keyframes bomb {
    0% {
        opacity: 1;
        transform: scale(0);
    }
    60% {
        transform: scale(2.2);
        opacity: .8;
    }
    100% {
        transform: scale(2.3);
        opacity: 1;
    }
}
@keyframes bomb2 {
    0% {
        opacity: 1;
        left: 12%;
        top: 17%;
        transform: scale(0);
    }
    60% {
        transform: scale(2.2);
        opacity: .8;
    }
    100% {
        transform: scale(2.3);
        left: 12%;
        top: 17%;
        opacity: 0;
    }
}
@keyframes bomb3 {
    0% {
        opacity: 1;
        right: 30%;
        top: 42%;
        transform: scale(0);
    }
    60% {
        transform: scale(2.2);
        opacity: .8;
    }
    100% {
        transform: scale(2.3);
        right: 30%;
        top: 42%;
        opacity: 0;
    }
}
.brokenShip1 {
    position: absolute;
    opacity: 0;
    bottom: 7%;
    right: 21%;
    width: 20%;
    $animation: (fadeIn .8s 2.9s forwards, upDown 1.5s 3.7s infinite);
    @include multiple_animations($animation);
}
.brokenShip2 {
    position: absolute;
    opacity: 0;
    top: 23%;
    left: 8%;
    width: 12%;
    $animation: (fadeIn .8s 2.6s forwards, upDown 1.5s 3.4s infinite);
    @include multiple_animations($animation);
}

@keyframes disappear {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
@keyframes fadeIn {
  0% { opacity: 0;}
  100% { opacity: 1;}
}
</style>

<template>
	<div class="gameItemBanner__img">
		<img class="aniTemBg" src="/images/gameItem/K3/K3_bannerbg.png" />
		<img class="aniTemLight" src="/images/gameItem/K3/K3_banneritem3.png" />
		<img class="aniTemEffect" src="/images/gameItem/K3/K3_banneritem2.png" />
		<img class="aniTem1" src="/images/gameItem/K3/K3_banneritem1.png" />
		<img class="aniCoin1" src="/images/gameItem/K3/K3_banneritem4.png" />
		<img class="aniCoin2" src="/images/gameItem/K3/K3_banneritem5.png" />
		<img class="aniCoin3" src="/images/gameItem/K3/K3_banneritem6.png" />
		<img class="aniCoin4" src="/images/gameItem/K3/K3_banneritem7.png" />
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["langConfig"]),
		selectedItem(){
			return this.langList[this.selectedIndex]
		}
	},
	data() {
		return {}
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni();
@include keyframesUpDown(10px);
@include keyframesHeartbeat(1.1);
@include keyframesRotate360(-360deg);

//animation動畫寫在這兒
.aniTemBg{
	width: 100%;
	@include bgAni;
}
// 光芒
.aniTemLight{
  position: absolute;
  top: 13%;
	left:22%;
	width: 60%;
  opacity: 0;
  $animations: (
    bgPop 0.5s 0.8s forwards,
    rotate360 16s 1.3s infinite linear
  );
  @include multiple_animations($animations);
}
// 光點
.aniTemEffect{
	position:absolute;
	top: 28%;
	left: 25%;
	min-width:100px;
  width: 50%;
  opacity: 0;
  $animations: (
    bgPop 0.5s 0.8s forwards,
    heartbeat 3s 1.3s infinite linear
  );
  @include multiple_animations($animations);
}
// 骰子
.aniTem1{
	position:absolute;
	top: 25%;
	left:30%;
	width: 42%;
	opacity: 0;
  $animations: (
    bgPop 0.5s 0.8s forwards,
    upDown 3s 2.8s infinite linear
  );
  @include multiple_animations($animations);
}
// 左上硬幣
.aniCoin1{
	position:absolute;
	left:16%;
	min-width:100px;
	width: 20%;
	top:12%;
	opacity: 0;
	$animations: (
		coinDown3 1s 1.2s forwards,
    upDown 2.5s 3.2s infinite linear
	);
  @include multiple_animations($animations);
}
// 左下硬幣
.aniCoin2{
	position:absolute;
	left:8%;
	min-width:100px;
	width: 20%;
	top:65%;
	opacity: 0;
	$animations: (
		coinDown1 1s 1s forwards,
    upDown 2.8s 2.8s infinite linear
	);
  @include multiple_animations($animations);
}
// 右上硬幣
.aniCoin3{
  position: absolute;
  left: 75%;
	width: 10%;
	top:30%;
  opacity: 0;
	$animations: (
		coinDown2 1s 1s forwards,
    upDown 3s 2.6s infinite linear
	);
  @include multiple_animations($animations);
}
// 右下硬幣
.aniCoin4{
  position: absolute;
  left: 75%;
	min-width:100px;
	width: 22%;
	top: 50%;
  opacity: 0;
	$animations: (
		coinDown4 1s 1.2s forwards,
    upDown 2.4s 3s infinite linear
	);
  @include multiple_animations($animations);
}
@keyframes coinDown1{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 65%;
    opacity: 1;
  }
}
@keyframes coinDown2{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 30%;
    opacity: 1;
  }
}
@keyframes coinDown3{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 12%;
    opacity: 1;
  }
}
@keyframes coinDown4{
  0%{
    top:0;
    opacity: 0;
  }
  100%{
    top: 50%;
    opacity: 1;
  }
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import Game from '../views/Game.vue';
import GameItemTem from '../views/GameItemTem.vue';
import GameItemBJL from '../views/GameItemBJL.vue';
import GameItemBW from '../views/GameItemBW.vue';
import GameItemBJA from '../views/GameItemBJA.vue';
import GameItemFLO from '../views/GameItemFLO.vue';
import GameItemFSC from '../views/GameItemFSC.vue';
import GameItemGH from '../views/GameItemGH.vue';
import GameItemGT from '../views/GameItemGT.vue';
import GameItemHR from '../views/GameItemHR.vue';
import GameItemKF from '../views/GameItemKF.vue';
import GameItemLH from '../views/GameItemLH.vue';
import GameItemLM from '../views/GameItemLM.vue';
import GameItemSB from '../views/GameItemSB.vue';
import GameItemXD from '../views/GameItemXD.vue';
import GameItemTEX from '../views/GameItemTEX.vue';
import GameItemYZ from '../views/GameItemYZ.vue';
import GameItemA004 from '../views/GameItemA004.vue';
import GameItemA006 from '../views/GameItemA006.vue';
import GameItemA012 from '../views/GameItemA012.vue';
import GameItemA015 from '../views/GameItemA015.vue';
import GameItemA014 from '../views/GameItemA014.vue';
import GameItemA013 from '../views/GameItemA013.vue';
import GameItemA016 from '../views/GameItemA016.vue';
import GameItemA017 from '../views/GameItemA017.vue';
import GameItemK3 from '../views/GameItemK3.vue';
import GameItemLON from '../views/GameItemLON.vue';
import GameItemP10 from '../views/GameItemP10.vue';
import GameItemSSC from '../views/GameItemSSC.vue';
import GameItemVN from '../views/GameItemVN.vue';
import GameItemSLA001 from '../views/GameItemSLA001.vue';
import GameItemSLA002 from '../views/GameItemSLA002.vue';
import GameItemSLA003 from '../views/GameItemSLA003.vue';
import GameItemSLA004 from '../views/GameItemSLA004.vue';
import GameItemSLA005 from '../views/GameItemSLA005.vue';
import GameItemSLA006 from '../views/GameItemSLA006.vue';
import GameItemSLA007 from '../views/GameItemSLA007.vue';
import GameItemSLA010 from '../views/GameItemSLA010.vue';
import GameItemSLA011 from '../views/GameItemSLA011.vue';
import GameItemSLA012 from '../views/GameItemSLA012.vue';
import GameItemSLA013 from '../views/GameItemSLA013.vue';
import GameItemSLA014 from '../views/GameItemSLA014.vue';
import GameItemSLA015 from '../views/GameItemSLA015.vue';
import Priv from '../views/Priv.vue';
import AMLPolicy from '../views/AMLPolicy.vue';
import KYCPolicy from '../views/KYCPolicy.vue';
import ResponsibleGaming from '../views/ResponsibleGaming.vue';
import SelfExclusionPolicy from '../views/SelfExclusionPolicy.vue';
import TermsConditions from '../views/TermsConditions.vue';
import UnderageGamingPolicy from '../views/UnderageGamingPolicy.vue';
import Com from '../views/Com.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
  },
  {
    path: '/gameitemtem',
    name: 'GameItemTem',
    component: GameItemTem,
  },
  {
    path: '/gameitembw',
    name: 'GameItemBW',
    component: GameItemBW,
  },
  {
    path: '/gameitema016',
    name: 'GameItemA016',
    component: GameItemA016,
  },
  {
    path: '/gameitema004',
    name: 'GameItemA004',
    component: GameItemA004,
  },
  {
    path: '/gameitema006',
    name: 'GameItemA006',
    component: GameItemA006,
  },
  {
    path: '/gameitema012',
    name: 'GameItemA012',
    component: GameItemA012,
  },
  {
    path: '/gameitema013',
    name: 'GameItemA013',
    component: GameItemA013,
  },
  {
    path: '/gameitema015',
    name: 'GameItemA015',
    component: GameItemA015,
  },
  {
    path: '/gameitema014',
    name: 'GameItemA014',
    component: GameItemA014,
  },
  {
    path: '/gameitema017',
    name: 'GameItemA017',
    component: GameItemA017,
  },
  
  {
    path: '/gameitembjl',
    name: 'GameItemBJL',
    component: GameItemBJL,
  },
  {
    path: '/gameitembja',
    name: 'GameItemBJA',
    component: GameItemBJA,
  },
  {
    path: '/gameitemfsc',
    name: 'GameItemFSC',
    component: GameItemFSC,
  },
  {
    path: '/gameitemflo',
    name: 'GameItemFLO',
    component: GameItemFLO,
  },
  {
    path: '/gameitemgh',
    name: 'GameItemGH',
    component: GameItemGH,
  },
  {
    path: '/gameitemgt',
    name: 'GameItemGT',
    component: GameItemGT,
  },
  {
    path: '/gameitemhr',
    name: 'GameItemHR',
    component: GameItemHR,
  },
  {
    path: '/gameitemkf',
    name: 'GameItemKF',
    component: GameItemKF,
  },
  {
    path: '/gameitemlh',
    name: 'GameItemLH',
    component: GameItemLH,
  },
  {
    path: '/gameitemlm',
    name: 'GameItemLM',
    component: GameItemLM,
  },
  {
    path: '/gameitemsb',
    name: 'GameItemSB',
    component: GameItemSB,
  },
  {
    path: '/gameitemxd',
    name: 'GameItemXD',
    component: GameItemXD,
  },
  {
    path: '/gameitemtex',
    name: 'GameItemTEX',
    component: GameItemTEX,
  },
  {
    path: '/gameitemyz',
    name: 'GameItemYZ',
    component: GameItemYZ,
  },
  {
    path: '/gameitemlon',
    name: 'GameItemLON',
    component: GameItemLON,
  },
  {
    path: '/gameitemp10',
    name: 'GameItemP10',
    component: GameItemP10,
  },
  {
    path: '/gameitemk3',
    name: 'GameItemK3',
    component: GameItemK3,
  },
  {
    path: '/gameitemssc',
    name: 'GameItemSSC',
    component: GameItemSSC,
  },
  {
    path: '/gameitemvn',
    name: 'GameItemVN',
    component: GameItemVN,
  },
  {
    path: '/gameitemsl_a001',
    name: 'GameItemSLA001',
    component: GameItemSLA001,
  },
  {
    path: '/gameitemsl_a002',
    name: 'GameItemSLA002',
    component: GameItemSLA002,
  },
  {
    path: '/gameitemsl_a003',
    name: 'GameItemSLA003',
    component: GameItemSLA003,
  },
  {
    path: '/gameitemsl_a004',
    name: 'GameItemSLA004',
    component: GameItemSLA004,
  },
  {
    path: '/gameitemsl_a005',
    name: 'GameItemSLA005',
    component: GameItemSLA005,
  },
  {
    path: '/gameitemsl_a006',
    name: 'GameItemSLA006',
    component: GameItemSLA006,
  },
  {
    path: '/gameitemsl_a007',
    name: 'GameItemSLA007',
    component: GameItemSLA007,
  },
  {
    path: '/gameitemsl_a010',
    name: 'GameItemSLA010',
    component: GameItemSLA010,
  },
  {
    path: '/gameitemsl_a011',
    name: 'GameItemSLA011',
    component: GameItemSLA011,
  },
  {
    path: '/gameitemsl_a012',
    name: 'GameItemSLA012',
    component: GameItemSLA012,
  },
  {
    path: '/gameitemsl_a013',
    name: 'GameItemSLA013',
    component: GameItemSLA013,
  },
  {
    path: '/gameitemsl_a014',
    name: 'GameItemSLA014',
    component: GameItemSLA014,
  },
  {
    path: '/gameitemsl_a015',
    name: 'GameItemSLA015',
    component: GameItemSLA015,
  },
  {
    path: '/priv',
    name: 'Priv',
    component: Priv,
  },
  {
    path: '/aml_policy',
    name: 'AMLPolicy',
    component: AMLPolicy,
  },
  {
    path: '/kyc_policy',
    name: 'KYCPolicy',
    component: KYCPolicy,
  },
  {
    path: '/responsible_gaming',
    name: 'ResponsibleGaming',
    component: ResponsibleGaming,
  },
  {
    path: '/self_exclusion_policy',
    name: 'SelfExclusionPolicy',
    component: SelfExclusionPolicy,
  },
  {
    path: '/terms_conditions',
    name: 'TermsConditions',
    component: TermsConditions,
  },
  {
    path: '/underage_gaming_policy',
    name: 'UnderageGamingPolicy',
    component: UnderageGamingPolicy,
  },{
    path: '/com',
    name: 'Com',
    component: Com,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes
})

export default router

<template>
      <div class="gameItemBanner__img">
            <img class="aniTemBg" src="/images/gameItem/GH/GH_bannerbg.png" />
            <img class="aniTem1" src="/images/gameItem/GH/GH_banneritem1.png" />
            <div class="aniTem2">
                  <img src="/images/gameItem/GH/magic/GH_01.png" />
                  <img src="/images/gameItem/GH/magic/GH_02.png" />
                  <img src="/images/gameItem/GH/magic/GH_03.png" />
                  <img src="/images/gameItem/GH/magic/GH_04.png" />
                  <img src="/images/gameItem/GH/magic/GH_05.png" />
                  <img src="/images/gameItem/GH/magic/GH_06.png" />
                  <img src="/images/gameItem/GH/magic/GH_07.png" />
                  <img src="/images/gameItem/GH/magic/GH_08.png" />
                  <img src="/images/gameItem/GH/magic/GH_09.png" />
                  <img src="/images/gameItem/GH/magic/GH_10.png" />
                  <img src="/images/gameItem/GH/magic/GH_11.png" />
                  <img src="/images/gameItem/GH/magic/GH_12.png" />
                  <img src="/images/gameItem/GH/magic/GH_13.png" />
                  <img src="/images/gameItem/GH/magic/GH_14.png" />
                  <img src="/images/gameItem/GH/magic/GH_15.png" />
                  <img src="/images/gameItem/GH/magic/GH_16.png" />
            </div>
            <img class="aniTem3" src="/images/gameItem/GH/GH_banneritem3.png" />
      </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
      ...mapState(['lang']),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/anicss.scss";

.aniTemBg {
    width: 100%;
    @include bgAni;
}

.aniTem1 {
      position: absolute;
      right: 27%;
      top: 25%; 
      width: 17%;
      z-index: 3;
      opacity: 0;
      @include keyframesBgAni;
      @include keyframesUpDown(10%);
      $animations: (bgPop 0.7s 2.4s forwards, upDown 2.6s 3.1s infinite);
      @include multiple_animations($animations);
}

.aniTem2 {
      position: absolute;
      width: 100%;
      left: 40%;
      top: 12%;
      z-index: 2;
      img {
            position: absolute;
            width: 55%;
            opacity: 0;
      }
}

@for $i from 1 to 17 {
      @if $i == 16 {
            .aniTem2 img:nth-child(#{$i}) { 
                  animation: aniTem2 0.05s 1.2s+$i*0.05s forwards linear;
                  z-index: $i + 2
            }
      } @else {
            .aniTem2 img:nth-child(#{$i}) { 
                  $animations: (aniTem2 0.05s 1.5s+$i*0.05s forwards linear, disappear 0.1s 1.56s+$i*0.05s forwards linear);
                  @include multiple_animations($animations);
                  z-index: $i + 2;
            }  
      }
}

@keyframes aniTem2 {
      0% { opacity: 0; }
      100% { opacity: 1; }
}

@keyframes disappear {
      0% { opacity: 1; }
      100% { opacity: 0; }
}

.aniTem3 {
      position: absolute;
      bottom: 0%;
      left: 15%;
      width: 46%;
      z-index: 1;
      opacity: 0;
      animation: bgPop 0.7s 0.8s forwards;
}
</style>

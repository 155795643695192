<template>
  <div class="gameItemBanner__img">
    <img class="aniTemBg" src="/images/gameItem/SL_A015/BG.png" />
    <img class="anTemThief" src="/images/gameItem/SL_A015/thief.png" />
    <img class="anTemThief lamp" src="/images/gameItem/SL_A015/lamp_light.png" />
    <img class="anBoxBody" src="/images/gameItem/SL_A015/box_body.png" />
    <img class="anBoxCover" src="/images/gameItem/SL_A015/cover.png" />
    <img class="anBoxShadow" src="/images/gameItem/SL_A015/box_shadow.png" />
    <img class="anBoxTopWave" src="/images/gameItem/SL_A015/box_top2.png" />
    <img class="anBoxTop" src="/images/gameItem/SL_A015/box_top1.png" />
    <img class="anMoney" src="/images/gameItem/SL_A015/box_money.png" />
    <img class="anMoneyLight" src="/images/gameItem/SL_A015/box_moneylight.png" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["langConfig"]),
    selectedItem() {
      return this.langList[this.selectedIndex]
    }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesUpDown(10px);

.aniTemBg {
  width: 100%;
  @include bgAni;
}

.anTemThief {
  position: absolute;
  top: 3%;
  left: 3%;
  width: 47%;
  opacity: 0;
  $animations: (bgPop .4s 1s forwards, upDown 3s 1.5s infinite);
  @include multiple_animations($animations);
}

.lamp {
  position: absolute;
  top: 27%;
  left: 28%;
  width: 30%;
}

.anBoxBody {
  position: absolute;
  opacity: 0;
  $animations: (bgPop .2s 1.4s forwards, upDown 2s 2.05s infinite);
  @include multiple_animations($animations);
  width: 32%;
  bottom: 12%;
  right: 15%;
}
.anBoxCover {
  position: absolute;
  opacity: 0;
  bottom: 22%;
  width: 35%;
  right: 14%;
  z-index: 2;
  $animations: (bgPop .2s 1.4s forwards, fadeOut 0.3s 1.7s forwards);
  @include multiple_animations($animations);
}
.anBoxShadow {
  width: 28%;
  bottom: 5%;
  right: 15%;
  position: absolute;
  opacity: 0;
  $animations: (bgPop .2s 1.4s forwards, );
  @include multiple_animations($animations);
}

.anBoxTopWave {
  position: absolute;
  bottom: 22%;
  width: 35%;
  right: 14%;
  opacity: 0;
  z-index: 2;
  $animation: (fadeOut 0.03s 1.9s forwards,);
  @include multiple_animations($animation);
}
.anBoxTop {
  position: absolute;
  bottom: 28%;
  width: 35%;
  right: 2%;
  opacity: 0;
  $animation: (fadeIn 0.1s 1.93s forwards, upDown 2s 2.05s infinite);
  @include multiple_animations($animation);
}
.anMoney {
  position: absolute;
  width: 17%;
  bottom: 27%;
  right: 24%;
  opacity: 0;
  $animation: (fadeIn 0.1s 1.93s forwards, upDown 2s 2.05s infinite);
  @include multiple_animations($animation);
}
.anMoneyLight {
  position: absolute;
  width: 25%;
  bottom: 21%;
  right: 20%;
  opacity: 0;
  $animation: (fadeIn 0.1s 1.93s forwards, upDown 2s 2.05s infinite);
  @include multiple_animations($animation);
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  60% {
    opacity: .8;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

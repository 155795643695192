<template>
	<div class="gameItemBanner__img">
		<img class="aniTemBg" src="/images/gameItem/A012/A012_bannerbg.png" />
		<img class="aniTemMain" src="/images/gameItem/A012/A012_banneritem.png" />
		<img class="aniTem1" src="/images/gameItem/A012/A012_banneritem1.png" />
		<img class="aniTem2" src="/images/gameItem/A012/A012_banneritem2.png" />
		<img class="aniTem3" src="/images/gameItem/A012/A012_banneritem3.png" />
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["langConfig"]),
		selectedItem(){
			return this.langList[this.selectedIndex]
		}
	},
	data() {
		return {}
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesHeartbeat(1.03);
@include keyframesUpDown(15px);
@keyframes bottlePop{
	0%{
		opacity: 0;
		transform:scale(0);
	}
	50%{
		opacity: 1;
		transform:scale(1.2);
	}
	100%{
		transform:scale(1);
		opacity: 1;
	}
}
//animation動畫寫在這兒
.aniTemBg{
	width: 100%;
	@include bgAni;
}
// 鑽石
.aniTemMain{
	position:absolute;
	top: 60%;
	left:10%;
	min-width:100px;
	width: 25%;
	z-index: 2;
	opacity: 0;
	$animations: (
		bgPop .5s 1s forwards,
		upDown 2.6s 2s ease-in infinite
	);
	@include multiple_animations($animations);
}
// 酒瓶
.aniTem1{
	position:absolute;
	top: 25%;
	left:25%;
	width: 45%;
	z-index: 1;
	opacity: 0;
	$animations: (
		bottlePop .8s 1s forwards,
		heartbeat 3s 1.8s linear infinite,
	);
    @include multiple_animations($animations);
}
// 金條
.aniTem2{
	position:absolute;
	left:25%;
	min-width:100px;
	width: 25%;
	top:10%;
	opacity: 0;
	$animations: (
		bgPop .5s 1s forwards,
		upDown 2.8s 1.8s ease-in infinite
	);
    @include multiple_animations($animations);
}
// switch
.aniTem3{
	position:absolute;
	right:20%;
	min-width:100px;
	width: 20%;
	top:35%;
	opacity: 0;
	$animations: (
		bgPop 0.5s 1s forwards,
		upDown 3s 1.5s ease-in infinite
	);
    @include multiple_animations($animations);
}


</style>

<template>
    <div class="gameItemBanner__img">
      <img class="aniTemBg" src="/images/gameItem/BJL/BJL_bannerbg.png" />
      <img class="aniTemMain" src="/images/gameItem/BJL/BJL_01.png" />
      <img class="aniTem1 aniTem1A" src="/images/gameItem/BJL/BJL_banneritem1.png" />
      <img class="aniTem1 aniTem1B" src="/images/gameItem/BJL/BJL_banneritem1.png" />
      <img class="aniTem2" src="/images/gameItem/BJL/BJL_banneritem2.png" />
      <img class="aniTem3" src="/images/gameItem/BJL/BJL_banneritem3.png" />
      <img class="aniTem4" src="/images/gameItem/BJL/BJL_banneritem4.png" />
      <img class="aniTem5" src="/images/gameItem/BJL/BJL_banneritem5.png" />
      <img class="aniTem6" src="/images/gameItem/BJL/BJL_banneritem6.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(['lang']),
    selectedItem(){
      return this.langList[this.selectedIndex]
    }
  },
  data() {
    return {
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/anicss.scss";
@include keyframesUpDown;

.aniTemBg {
  width: 100%;
  @include bgAni;
}

.aniTemMain {
  position: absolute;
  top: 2%;
  left: 25%;
  width: 50%;
  opacity: 0;
  animation: bgPop 0.7s 0.8s forwards;
}

.aniTem1 {
  position: absolute;
  width: 6%;
  opacity: 0;
  animation: glint 3.4s 1.6s forwards infinite;
}

.aniTem1A {
  top: 9%;
  left: 40.5%;
}

.aniTem1B {
  top: 22%;
  left: 49.5%;
}

.aniTem2 {
  opacity: 0;
  position: absolute;
  width: 10%;
  bottom: 27.4%;
  left: 54%;
  animation: glint 3.4s 1.6s forwards infinite;
}

@keyframes glint {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.aniTem3 {
  opacity: 0;
  position: absolute;
  left: 10%;
  width: 18%;
  top: 45%;
  $animations: (bgPop 0.7s 1.5s forwards, upDown 2.6s 2.2s infinite);
  @include multiple_animations($animations);
}

.aniTem4 {
  position: absolute;
  right: 13%;
  width: 18%;
  top: 20%;
  opacity: 0;
  $animations: (bgPop 1s 1.5s forwards, upDown 2.6s 2.5s infinite);
  @include multiple_animations($animations);
}

.aniTem5 {
  position: absolute;
  right: 13%;
  width: 18%;
  top: 45%;
  opacity: 0;
  $animations: (bgPop 1.6s 1.5s forwards, upDown 2.6s 3.1s infinite);
  @include multiple_animations($animations);
}

.aniTem6 {
  position: absolute;
  left: 20%;
  width: 15%;
  top: 20%;
  opacity: 0;
  $animations: (bgPop 1.3s 1.5s forwards, upDown 2.6s 2.8s infinite);
  @include multiple_animations($animations);
}
</style>

<template>
    <div class="circle cherry_game">
        <div v-if="now==7" class="bigShow">
            <img :src="`images/idx/${langConfig.Partnerimg}.png`">
            <div class="big" :style="`background: url('/images/idx/${langConfig.PartnerimgBig}.png') no-repeat;background-size: cover;`"></div>
        </div>
        <template v-else>
            <h1>8
                <p>{{langConfig.CherryFun}}<br>{{langConfig.Partner}}</p>
            </h1>
            <splide :options="partnerOptions">
                <splide-slide v-for="(item, i) in partnerList" :key="i">
                    <img :src="`images/idx/logo/${item}.png`" style="width: 120px;">
                </splide-slide>
            </splide>
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props:['options','now'],
    data(){
        return{
            partnerList:['logo1','logo2','logo3'],
        }
    },
    computed: {
        ...mapState(["gameList",'langConfig']),
        partnerOptions(){
            let list={
                perPage: 2,
                gap: 10,
                interval:6000,
                ...this.options
            }
            return list
        }
    }
}
</script>
<style lang="scss">
.big7{
    .circle.cherry_game{
        height: calc(100% - 0px);
        position: relative;
    }
    .bigShow{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 106%;
            height: 116%;
            margin-top: -5px;
        }
    }
    .big{
        width: 150%;
        position: absolute;
        margin: auto;
        height: 150%;
        animation: clip 2.5s infinite ease-in-out;
    }
}
@keyframes clip {
    0% {
        clip-path: polygon(83% 0, 100% 0, 94% 100%, 78% 100%);
    }
    50% {
        clip-path: polygon(11% 0, 20% 0, 13% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(11% 0, 20% 0, 13% 100%, 0 100%);
    }
}
</style>
<template>
    <div  class="gameItemBg">
        <div class="gameItemBanner">
            <h1>Vietnam Lottery</h1>
            <GameItemVNAnimation />
        </div>
    <div class="gameItemTitle">
        <img  src="/images/gameIcon/VN.png" /><h2>{{langConfig.VN}}<label class="LO">LO</label></h2>
    </div>
    <div class="gameItemContent">
        {{langConfig.VNcontent}}
    </div>
    <div class="gameItemPic">
        <div class="gameItemPic__list">
            <GameItemPopUp :gameType="gameType"/>
            <div class="secTitle">{{langConfig.Compatible}}
                <ul class="icon"><li><img src="/images/icon_iOS.png" />iOS</li><li><img src="/images/icon_andriod.png" />Andriod</li><li><img src="/images/icon_h5.png" />HTML5</li></ul>
            </div>
            <div class="secTitle">{{langConfig.Language}}
                <ul>
                    <li><img src="/images/flag/China.png" /></li>
                    <li><img src="/images/flag/France.png" /></li>
                    <li><img src="/images/flag/Germany.png" /></li>
                    <li><img src="/images/flag/India.png" /></li>
                    <li><img src="/images/flag/Itanly.png" /></li>
                    <li><img src="/images/flag/Gray/Japan_gray.png" /></li>
                    <li><img src="/images/flag/Gray/Korea_gray.png" /></li>
                    <li><img src="/images/flag/Gray/Thai_gray.png" /></li>
                    <li><img src="/images/flag/UK.png" /></li>
                    <li><img src="/images/flag/Vietnam.png" /></li>
                </ul>
                <!-- for 沒有支援語系<ul>
                <li><img src="/images/flag/Gray/China_gray.png" /></li>
                <li><img src="/images/flag/Gray/France_gray.png" /></li>
                <li><img src="/images/flag/Gray/Germany_gray.png" /></li>
                <li><img src="/images/flag/Gray/India_gray.png" /></li>
                <li><img src="/images/flag/Gray/Itanly_gray.png" /></li>
                <li><img src="/images/flag/Gray/Japan_gray.png" /></li>
                <li><img src="/images/flag/Gray/Korea_gray.png" /></li>
                <li><img src="/images/flag/Gray/Thai_gray.png" /></li>
                <li><img src="/images/flag/Gray/UK_gray.png" /></li>
                <li><img src="/images/flag/Gray/Vietnam_gray.png" /></li>
                </ul> -->
            </div>
        </div>
        <div class="gameItemPic__phone">
            <img class="iphoneX" src="/images/iphoneX.png" />
            <img  class="phoneBg" :src="`/images/gameItem/${gameType}/${gameType}_phonebg.png`" />
            <img  class="phoneTitle" :src="`/images/gameItem/${gameType}/${gameType}_phonetitle.png`" />
        </div>
    </div>
    <GameItemHotGame :gameType="gameType"/>
    </div>
</template>

<script>
import GameItemVNAnimation from "@/components/GameItemVNAnimation.vue";
import GameItemPopUp from "@/components/GameItemPopUp.vue";
import GameItemHotGame from "@/components/GameItemHotGame.vue";
import { mapState } from "vuex";
export default {
    components: {
        GameItemVNAnimation,
        GameItemPopUp,
        GameItemHotGame
    },
    data(){
        return{
            gameType:'VN'
        }
    },
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang='scss' scoped>
.gameItemPic__phone .phoneTitle{
    bottom: 33%;
}

</style>

<template>
   <div class="gameItemBanner__img">
            <img class="aniTemBg" src="/images/gameItem/A013/A013_bannerbg.png" />
              <img class="aniTem2" src="/images/gameItem/A013/A013_banneritem1.png" />
            <div class="watermelon">
              <img class="aniTem1" src="/images/gameItem/A013/A013_banneritem2.png" />
              <img class="aniTem4" src="/images/gameItem/A013/A013_banneritem4.png" />
              <img class="aniTem5" src="/images/gameItem/A013/A013_banneritem5.png" />
              <img class="aniTem6" src="/images/gameItem/A013/A013_banneritem6.png" />
              <img class="aniTem7" src="/images/gameItem/A013/A013_banneritem7.png" />
            </div>
              <img class="aniTem3" src="/images/gameItem/A013/A013_banneritem3.png" />
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
       ...mapState(["langConfig"]),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;


@mixin keyFramWatermelon($name,$orgDeg,$deg){
  @keyframes #{$name} {

    0%{
      transform: rotate($orgDeg);
    }
    50%{
      transform: rotate($deg);
    }
  }

}
//animation動畫寫在這兒
.aniTemBg{
    width: 100%;
    @include bgAni;
}


.aniTem1{
      position:absolute;
      width: 62%;
      top: 36%;
      right: 19%;
      z-index: 1;
      @include keyFramWatermelon(lagWater,0deg,1deg);
      animation: lagWater 0.3s 2.6s ease-in-out infinite alternate-reverse;
}
.aniTem4{
       position:absolute;
      width:22%;
      top: 37%;
      left: 22%;
       z-index: 1;
       @include keyFramWatermelon(lagWater2,0deg,-3deg);
      animation: lagWater2 0.3s 2.6s linear infinite alternate-reverse;
}
.aniTem5{
       position:absolute;
      width: 18%;
      top: 25%;
      left: 43%;
       z-index: 1;
      @include keyFramWatermelon(lagWater3,0deg,3deg);
      animation: lagWater3 0.3s 2.6s linear infinite alternate-reverse;
}
.aniTem6{
       position:absolute;
      width: 10%;
      top:35%;
      right: 30%;
       z-index: 1;
        @include keyFramWatermelon(lagWater3,0deg,3deg);
      animation: lagWater3 0.3s 2.6s linear infinite alternate-reverse;
}
.aniTem7{
      position:absolute;
      width: 12%;
      right: 10%;
      bottom: 27%;
      z-index: 1;
      @include keyFramWatermelon(lagWater3,0deg,3deg);
      animation: lagWater3 0.3s 2.6s linear infinite alternate-reverse;
}

.watermelon{
  position:absolute;
  width: 100%;
  top: 0%;
  right: 0%;
  height: 100%;
  @include bgAni(0.5s,1.5s);
}

//外框紅
.aniTem2{
       position:absolute;
       left:10%;
       top: 15%;
       width: 90%;
        @include bgAni(0.5s,1s);
}

//口紅
.aniTem3{
       position:absolute;
       right:10%;
       width: 24%;
       z-index: 1;
       opacity: 0;
       transform-origin: left bottom;
       animation: Lipstick  0.2s 2s ease-out forwards;
}


@keyframes Lipstick{
        0%{
            opacity: 0;
            right:-30%;
            top: 0%;
            transform:scale(2.5)
        }
        50%{
            right:10%;
            top: 27%;
            opacity: 1;
            transform:scale(0.8)
        }   
        70%{
            right:10%;
            top: 27%;
            opacity: 1;
            transform:scale(0.8)
        }
        100%{
            right:10%;
            top: 27%;
            opacity: 1;
            transform:scale(1)
        }
}
    

</style>

<template>
   <div class="gameItemBanner__img">
      <img class="aniTemBg" src="/images/gameItem/BW/BW_bannerbg.png" />
      <img class="aniTemSpotLight" src="/images/gameItem/BW/BW_banneritem4.png" />
      <img class="aniTemYellow" src="/images/gameItem/BW/BW_banneritem1.png" />
      <img class="smoke item1" src="/images/gameItem/BW/SMOKE/smoke_01.png">
      <img class="spark item1" src="/images/gameItem/BW/SPARK/spark_01.png">
      <img class="smoke item1_1" src="/images/gameItem/BW/SMOKE/smoke_01.png">
      <img class="spark item1_1" src="/images/gameItem/BW/SPARK/spark_01.png">
      <img class="light1" src="/images/gameItem/BW/BW_banneritem3.png" />
      <img class="light1_1" src="/images/gameItem/BW/BW_banneritem3.png" />
      <!-- ====== -->
      <img class="aniTemRed" src="/images/gameItem/BW/BW_banneritem2.png" />
      <img class="smoke item2" src="/images/gameItem/BW/SMOKE/smoke_01.png">
      <img class="spark item2" src="/images/gameItem/BW/SPARK/spark_01.png">
      <img class="smoke item2_1" src="/images/gameItem/BW/SMOKE/smoke_01.png">
      <img class="spark item2_1" src="/images/gameItem/BW/SPARK/spark_01.png">
      <img class="light2" src="/images/gameItem/BW/BW_banneritem3.png" />
      <img class="light2_1" src="/images/gameItem/BW/BW_banneritem3.png" />
      <div id="preloadedImages">
        <img src="/images/gameItem/BW/SMOKE/smoke_01.png" alt="">
        <img src="/images/gameItem/BW/SMOKE/smoke_02.png" alt="">
        <img src="/images/gameItem/BW/SMOKE/smoke_03.png" alt="">
        <img src="/images/gameItem/BW/SMOKE/smoke_04.png" alt="">
        <img src="/images/gameItem/BW/SMOKE/smoke_05.png" alt="">
        <img src="/images/gameItem/BW/SMOKE/smoke_06.png" alt="">
        <img src="/images/gameItem/BW/SMOKE/smoke_07.png" alt="">
        <img src="/images/gameItem/BW/SMOKE/smoke_08.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_01.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_02.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_03.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_04.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_05.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_06.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_07.png" alt="">
        <img src="/images/gameItem/BW/SPARK/spark_08.png" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
let count = 0
let count2 = 5
let requestId = null
let spark = null
let smoke = null
export default {
  computed: {
       ...mapState(["langConfig"]),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  methods:{
    runAni(){
      if(count === 9){
        count = 0
      }
      count++
      if(count2 === 9){
        count2 = 0
      }
      count2++
      this.changeSpark(count)
      this.changeSmoke(count2)
      requestId = window.requestAnimationFrame(this.runAni);
    },
    runAni2(){
      setInterval(() => {
        if(count === 9){
          count = 0
        }
        count++
        if(count2 === 9){
          count2 = 0
        }
        count2++
        this.changeSpark(count)
        this.changeSmoke(count2)
      },100)
    },
    changeSpark(num){
      let length = spark.length
      for(let i=0;i<length;i++){
          spark[i].src = `/images/gameItem/BW/SPARK/spark_0${num}.png`
      }
    },    
    changeSmoke(num){
      let length = smoke.length
      for(let i=0;i<length;i++){
          smoke[i].src = `/images/gameItem/BW/SMOKE/smoke_0${num}.png`
      }
    },
    hideSmoke(){
      let sl = spark.length
      for(let i=0;i<sl;i++){
          spark[i].classList.add("hide");
      }
      let ml = smoke.length
      for(let j=0;j<ml;j++){
          smoke[j].classList.add("hide");
      }
      setTimeout(() => {
        window.cancelAnimationFrame(requestId)
      },2000)
    },
  },
  mounted(){
    spark = document.getElementsByClassName('spark')
    smoke = document.getElementsByClassName('smoke')
    requestId = window.requestAnimationFrame(this.runAni);
    setTimeout(() => {
      this.hideSmoke()
    },2000)
    // this.runAni2()
  },
  beforeDestroy(){
    window.cancelAnimationFrame(requestId);
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;
@include keyframesUpDown(1px);
@include keyframesRotate360;
.aniTemBg{
  width: 100%;
  @include bgAni;
}
.aniTemYellow{
  position:absolute;
  bottom: 22%;
  left: 0%;
  width: 55%;
  opacity: 0;
  $animations:(
    rush 0.5s 1s forwards,
    upDown 0.6s 1.5s infinite linear
  );
  @include multiple_animations($animations);
}
.aniTemRed{
  position:absolute;
  bottom: 13%;
  right: 0%;
  width: 70%;
  opacity: 0;
    $animations:(
    rush 0.5s 1.5s forwards,
    upDown 0.3s 2s infinite linear
  );
  @include multiple_animations($animations);
}

.hide{
  animation: visible 2s !important;
}

.smoke{
  position:absolute;
  opacity: 0;
  width:60%;
}

.spark{
  position:absolute;
  opacity: 0;
  width:25%;
}

.smoke.item1{
  bottom: 14%;
  left: 28%;
    animation:rushSmoke 0.5s 1s forwards;
}

.spark.item1{
  bottom: 24%;
  left: 36%;
    animation:rush 0.5s 1s forwards;
}

.smoke.item1_1{
  bottom: 24%;
  left: 40%;
    animation:rushSmoke 0.5s 1s forwards;
}

.spark.item1_1{
  bottom: 30%;
  left: 48%;
    animation:rush 0.5s 1s forwards;
}


.smoke.item2{
  bottom: 4%;
  left:57%;
  animation:rushSmoke 0.5s 1.5s forwards;
}

.spark.item2{
  bottom: 13%;
  left:70%;
  animation:rush 0.5s 1.5s forwards;
}

.smoke.item2_1{
  bottom: 15%;
  left:85%;
  animation:rushSmoke 0.5s 1.5s forwards;
}

.spark.item2_1{
  bottom: 20%;
  left:88%;
  animation:rush 0.5s 1.5s forwards;
}


.light1{
  position: absolute;
  width: 5%;
  left: 5%;
  bottom: 33%;
  opacity: 0;
  $animations: (
    rush 0.5s 1s forwards,
    flashing 3s 1.5s infinite
  );
  @include multiple_animations($animations);
}

.light1_1{
  position: absolute;
  width: 7%;
  left: 23%;
  bottom: 33%;
  opacity: 0;
  $animations: (
    rush 0.5s 1s forwards,
    flashing 3s 1.5s infinite
  );
  @include multiple_animations($animations);
}

.light2{
  position: absolute;
  width: 7%;
  left: 31%;
  bottom: 26%;
  opacity: 0;
  $animations: (
    rush 0.5s 1.5s forwards,
    flashing 3s 2s infinite
  );
  @include multiple_animations($animations);
}

.light2_1{
  position: absolute;
  width: 8%;
  left: 56%;
  opacity: 0;
  bottom: 27%;
  $animations: (
    rush 0.5s 1.5s forwards,
    flashing 3s 2s infinite
  );
  @include multiple_animations($animations);
}
.aniTemLight{
  position:absolute;
  top: 250px;
  right: -20px;
  width: 95%;
}
.aniTemSpotLight{
  position:absolute;
  top: 10%;
  left: 0;
  right: 0px;
  margin: auto;
  width: 80%;
  opacity: 0;
  transform-origin: center bottom;
  transform: rotate(20deg);
  $animations: (
    downToUp 0.5s 2s forwards,
    rotateLight 5s 1s linear infinite alternate-reverse
  );
  @include multiple_animations($animations); 
}



@keyframes flashing{
  0% { 	opacity: 1; transform:rotate(0deg) }
  // 10% {	opacity: 0 }
  // 20% { opacity: 0 }
  // 30% { opacity: 1 }
  // 35% { opacity: 0 }
  78%  {opacity: 0.8}
  80% { opacity: 0}
  // 75% { opacity: 0 }
  93% { opacity: 0}
  94%{ opacity: 1 ;}
  99% { opacity: 0}
  100%{ opacity: 1 ; transform:rotate(-360deg) }
}

@keyframes downToUp{
  from{
    opacity: 0;
    transform: translateY(80px);
  }

  to{ 
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes rush {
	from {
		opacity: 0;
		transform: translateX(200px);
	}

	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes rushSmoke {
	from {
		opacity: 0;
		transform: translateX(200px);
	}

	to {
		opacity: 0.5;
		transform: translateX(0px);
	}
}

@keyframes rotateLight{
    0%{
      transform: rotate(20deg);
    }
    50%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(-20deg);
    }
}

@keyframes visible {
 from {
   opacity: 0.5;
 }
 to {
   opacity: 0;
 }
}

</style>

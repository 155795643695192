<template>
   <div class="gameItemBanner__img">
            <img class="aniTemBg" src="/images/gameItem/SL_A001/GP_BG_2.png" />
            <img class="aniTem1" src="/images/gameItem/SL_A001/bgligjht.png" />
            <img class="aniTem3" src="/images/gameItem/SL_A001/building.png" />
            <img class="aniTem2" src="/images/gameItem/SL_A001/LOGO.png" />
            <img class="fireH R" src="/images/gameItem/SL_A001/fire/F1.png" />
            <img class="fireH L" src="/images/gameItem/SL_A001/fire/F1.png" />
            <div class="fire R"></div>
            <div class="fire L"></div>
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
       ...mapState(["langConfig"]),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
  methods:{

    
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni;



@keyframes opShow {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fire{
  0%{
    height: auto;
  }
  50%{
    height: 150%;
  }
  100%{
    height: 10%;
  }
}

@keyframes fireRE{
  0%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A001/fire/F1.png');
  }
  25%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A001/fire/F2.png');
  }
  50%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A001/fire/F3.png');
  }
  75%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A001/fire/F4.png');
  }
  100%{
    opacity: 1;
    background-image: url('/images/gameItem/SL_A001/fire/F1.png') ;
  }
}

@keyframes fireShow{
  0%{
    bottom: 20%;
    height: 50%;
    scale: 1;
    opacity: 1;
  }
 
  50%{
    bottom: 20%;
    height: 100%;
    scale: 1;
  }
  90%{
    bottom: 80%;
    height: 10%;
    scale: 1;
    opacity: 1;
  }

  95%{
    height: 0%;
    scale: 0;
    opacity: 0;
  }
}
//animation動畫寫在這兒
.aniTemBg{
    width: 100%;
    @include bgAni;
}

.aniTem1{
      position:absolute;
      left: 21%;
      width: 59%;
      top: 6%;
      opacity: 0;
      animation: opShow 0.3s 1.2s forwards;
}


.aniTem2{
       position:absolute;
       left:34%;
       bottom: 6%;
       width:35%;
       opacity: 0;
       @include keyframesUpDown(10px);

       $animations: (
        opShow 0.3s 2s forwards,
        upDown 3s 2.5s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTem3{
       position:absolute;
       width: 45%;
       left: 28%;
       top: 51%;
       opacity: 0;
       animation: opShow 0.3s 2s forwards;
}
.fire{
    position: absolute;
    bottom: 30%;
    width: 20%;
    height: 25%;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    animation: fireRE 0.3s 1.7s infinite;
    &.L{
        left: 14%;
    }
    &.R{
        right: 14%;
    }
}
.fireH{
    position: absolute;
    width: 20%;
    bottom: 10%;
    height: 10%;
    opacity: 0;
    animation: fireShow 0.3s 1s forwards;
    &.L{
        left: 14%;
    }
    &.R{
        right: 14%;
    }
}





    

</style>

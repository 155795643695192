<template>
    <div class="popUp">
        <div class="mask" @click="close"></div>
        <div class="filter_container" >
            <div class="searchBar">
                <icon name="search" @click="search" />
                <input type="text"  v-model="searchName" :placeholder="langConfig.Search">
                <icon name="btn_cancle" @click="clear" />
            </div>
        <gameFilter :selectedType="selectedType" :typeList="typeList" @filterType="filterType" />
        <button class="send" @click="search">SUBMIT</button>
    </div>
</div>
</template>

<script>
import { mapState } from "vuex";
import gameFilter from '@/components/GameFilter'
export default {
    props:{
        searchType:Number,
        typeList:Array,
        selectedType:String,
        gameName:String
    },
    components: {
        gameFilter
    },
    computed: {
        ...mapState(["gameList", "langConfig"]),
    },
    data(){
        return{ 
            searchName:'',
        }
    },
    methods:{
        clear(){
            this.searchName = ''
        },
        search(){
            this.$emit('search',this.searchName)
            this.close()
        },
        filterType(type){
            this.$emit('filterType',type)
        },
        close(){
            document.body.style = 'overflow:auto'
            this.$emit('closeSearch')
        }
    },
    mounted() {
        document.body.style = 'overflow:hidden'
        if(this.gameName) this.searchName = this.gameName
    },
    beforeDestroy() {
        document.body.style = 'overflow:auto'
    }
}
</script>

<style lang="scss" scoped>
.popUp{
    position:fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    top: 0;
    left: 0;
    overflow: hidden;
    .mask{
        position:absolute;
        width:100%;
        height: 100%;
        top: 0;
        left: 0;
        background:#fff;
        opacity: .9;
        backdrop-filter: blur(10px);
        // box-shadow: inset 0px 0px 5px rgb(0,0,0);
    }
    .searchBar{
        position: relative;
        margin: 10px auto;
        text-align: left; 
        font-size: 24px;
        border-bottom: 1px solid #aaa;
        .cross{
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color:#fff;
            font-size: 12px;
            border-radius: 50%;
            background:#333;
            display: inline-block;
        }
    }
    input{ 
        border: none;
        display: inline-block;
        padding:0 5px;
        height: 40px;
        font-size: 20px;
        width: 75%;
        background:transparent;
    }
    .filter_container{
        position:absolute;
        top:10%;
        width:65%;
        left: 0;
        right: 0;
        margin: auto;
        /deep/.none{
            display: none;
        }

        /deep/ .side_bar{
            position: relative;
            font-size: 22px;
            padding:10px 30px;
            border-bottom: 2px solid #ccc;
            .v-icon{
                position: absolute;
                left: 0px;
            }
        }
        /deep/ h3{
            font-size: 20px;
            padding:14px 0;
        }
        /deep/ li{
            margin:20px auto;
            font-size: 20px;
        }
    }
    button{
        border-radius: 5px;
        border:1px solid #333;
        width: 120px;
        height: 40px;
        font-size: 16px;
        text-align: center;
        line-height: 40px;
        background:transparent;
        margin:30% auto;
        display:block;
    }
}
</style>
<template>
  <div>
    <HomeBannerWap v-if="clientWidth<=1024"/>
    <HomeBanner v-else :clientWidth='clientWidth'/>
    <HomeMain :clientWidth='clientWidth'/>
  </div>
</template>

<script>
import HomeBannerWap from "@/components/HomeBannerWap.vue";
import HomeBanner from "@/components/HomeBanner.vue";
import HomeMain from "@/components/HomeMain.vue";
export default {
  data() {
    return {
      clientWidth:''
    }
  },
  components: {
    HomeMain,HomeBanner,
    HomeBannerWap
  },
  mounted() {
    this.clientWidth=document.body.clientWidth
    window.onresize=()=>{
      this.clientWidth=document.body.clientWidth
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="circle cherry_hour">
        <h1>{{langConfig.HourT}}
            <p>{{langConfig.Hour}}</p>
        </h1>
        <div v-if="now==2" class="bigShow">
            <p>{{langConfig.HourB}}</p>
            <img :src="`images/idx/big2.gif`">
        </div>
        <p v-else>{{langConfig.HourS}}</p>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props:['now'],
    computed: {
        ...mapState(['langConfig']),
    }
}
</script>
<style lang="scss">
.cherry_hour{
    h1{
        font-size: 40px;
        display: flex;
        align-items:baseline;
        line-height:65px;
        p{
            font-size: 16px;
            padding-left: 10px;
        }
    }
    p{
        line-height: 19px;
        font-size:14px;
    }
    .splide{
        padding:0 0 40px;
    }
}
.big2{
    .circle.cherry_hour {
        h1{
            line-height: 57px;
        }
        .bigShow{
            position: relative;
            p{
                width: 78%;
                font-size: 12px;
                line-height: 15px;
            }
            img{
                position: absolute;
                width: 116px;
                right: -36%;
                top: -4%;
            }
        }
    }
}
</style>
<template>
    <div class="homeMain">
        <!-- <HomeMainBiz :clientWidth='clientWidth'/> -->
        <HomeMainGame/>
    </div>
</template>

<script>
// import HomeMainBiz from "@/components/HomeMainBiz.vue";
import HomeMainGame from "@/components/HomeMainGame.vue";

export default {
    props:['clientWidth'],
    components: {
        HomeMainGame
    },
}
</script>

<style lang="scss">
@import "@/scss/rwd.scss";
.homeMain{
    background: url('/images/idx/bg.png') no-repeat;
    width: 100%;
    background-size: cover;
    background-position: bottom;
    h2{
        font-size: 34px;
        width: 97%;
        margin: 0 auto;
        margin-bottom: 5%;
        @include gameItemPic-width(){
            font-size: 28px;
            text-align: center;
        }
    }
    .mainBlock{
        padding: 6% 0;
        max-width: 1300px;
        margin:0 auto;
        width: 95%;
    }
}
</style>
<template>
    <div>
        <section class="priv_banner"></section>
        <section class="priv_main">
            <h1 class="title">{{langConfig.UnderageGameTitle}}</h1>
            <div class="paragraph">
                <h2 class="title">{{langConfig.UnderageGambling}}</h2>
                <div class="content">
                    <p>{{langConfig.UnderageGamblingContent_1}}</p>
                    <p>{{langConfig.UnderageGamblingContent_2}}</p>
                    <p>{{langConfig.UnderageGamblingContent_3}}</p>
                    <ul>
                        <li>
                            <a alt="" href="https://www.netnanny.com/" target="_blank">{{langConfig.UnderageGamblingContent_3_listItem_1}}</a>
                        </li>
                        <li>
                            <a alt="" href="https://www.cyberpatrol.com/" target="_blank">{{langConfig.UnderageGamblingContent_3_listItem_2}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="paragraph">
                <h2 class="title">{{langConfig.GamblingCounsellingOrganizations}}</h2>
                <div class="content">
                    <p>{{langConfig.GamblingCounsellingOrganizationsContent_1}}</p>
                    <a alt="" href="https://www.begambleaware.org/" target="_blank">{{langConfig.GamblingCounsellingOrganizationsContent_1_listItem_1}}</a>
                    <p>{{langConfig.GamblingCounsellingOrganizationsContent_2}}</p>
                    <a alt="" href="https://www.gamblersanonymous.org/ga/" target="_blank">{{langConfig.GamblingCounsellingOrganizationsContent_2_listItem_1}}</a>
                    <p>{{langConfig.GamblingCounsellingOrganizationsContent_3}}</p>
                    <a alt="" href="https://www.gamblingtherapy.org/" target="_blank">{{langConfig.GamblingCounsellingOrganizationsContent_3_listItem_1}}</a>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(["langConfig"])
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
.priv_banner{
	height: 465px;
	background-image: url("/images/privacy.png");
    background-size: cover;
    background-position: center center;
}
.priv_main{
    width: 70%;
    margin: 30px auto;
    max-width: 1400px;
    @include pads-width{
        width: 85%;
    }
    .paragraph{
        padding: 15px 0;
    }
    .title {
        font-size: 34px;
        color: #333333;
        margin-bottom: 15px;
        margin-top: 5%;
    }

    h2 {
        text-decoration: underline;
    }

    h1 {
        text-align: center;
    }
    .content{
        display: flex;
        flex-direction: column;
    }
    p {
        margin: 20px 10px 0 0;
        color: #031029;
        font-size: 18px;
        font-weight: 1000;
        line-height: 40px;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
            margin: 20px 15px 0 0;
        }
        @include phone-width(){
            font-size: 18px;
            margin: 20px 10px 0 0;
            line-height: 30px;
        }
    }
    li {
        font-size: 18px;
        line-height: 40px;
        list-style: none;
        font-family: auto;
        font-family: auto;
        @include pcs-width(){
            font-size: 28px;
            line-height: 50px;
        }
        @include phone-width(){
            font-size: 18px;
            width: 90%;
            margin: 10px 0 10px 20px;
            line-height: 30px;
        }
    }

    a {
        color: blue;
        text-decoration: underline;
    }
}
</style>
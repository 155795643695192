<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/SB/SB_bannerbg.png" />
         <img class="aniTem6" src="/images/gameItem/SB/SB_banneritem6.png" />
        <img class="aniTem5" src="/images/gameItem/SB/SB_banneritem5.png" />
        <img class="aniTem4" src="/images/gameItem/SB/SB_banneritem4.png" />
        <img class="aniTem3" src="/images/gameItem/SB/SB_banneritem3.png" />
        <img class="aniTem2" src="/images/gameItem/SB/SB_banneritem2.png" />
        <img class="aniTem1" src="/images/gameItem/SB/SB_banneritem1.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
         ...mapState(["langConfig"]),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesUpDown(5%);
//animation動畫寫在這兒
.aniTemBg{
    width: 100%;
    @include bgAni
}
//骰
.aniTem1{
    position:absolute;
    left: 29%;
    width: 43%;
    opacity: 0;
    $animation: (
        diceDown 1s 1.8s forwards,
    );
    @include multiple_animations($animation);
}
@keyframes diceDown{
    0%{
       bottom: -10%;
    }
    100%{
        bottom:12%;
        opacity: 1;
    }
}
//光
.aniTem2{
    position:absolute;
    bottom: 5%;
    right: 14%;
    width: 76%;
    opacity: 0;
    @include keyframesHeartbeat(1.05);
    $animation: (
        light  2.5s 2s forwards,
        heartbeat 3.2s 3.2s linear infinite ,
    );
    @include multiple_animations($animation);
}
@keyframes light{
    0%{
        bottom:30%;
        opacity:0;
    }
    100%{
        opacity: 1;
    }
}
//人
.aniTem3{
    position:absolute;
    bottom: 5%;
    left: 25.5%;
    width: 45%;
    opacity: 0;
    $animation: (
        bgPop 0.5s .8s forwards,
        upDown 3.2s 1s linear infinite alternate-reverse
    );
    @include multiple_animations($animation);
}
//籌碼
.aniTem4{
    position:absolute;
    left: 16%;
    width: 68%;
    opacity: 0;
    $animation: (
        coinDown 1s 1.5s forwards,
        upDown 3.2s 2.5s linear infinite alternate-reverse
    );
    @include multiple_animations($animation);
}
@keyframes coinDown{
    0%{
       top:5%;
    }
    100%{
       top:32%;
       opacity: 1;
    }
}
//骰盅
.aniTem5{
    position:absolute;
    left: 24%;
    top: 50%;
    width: 13%;
    opacity: 0;
    animation: bgPop .5s 0.8s forwards;
}
.aniTem6{
    position:absolute;
    right: 26%;
    top: 35%;
    width: 13%;
    opacity: 0;
    animation: bgPop .5s 0.8s forwards;
}
</style>

import Vue from 'vue'
export function getLanguage (context) {
    let type = context.state.lang
    fetch("/lang/"+ type + ".json")
    .then(response => {
        return response.json();
    })
    .then(data => {
        // console.log('data',data);
        Vue.prototype.$langConfig = data
        context.commit('upLangConfig',data);
    });
}



<template>
	<div class="mask">
		<svg
			class="spinner"
			width="65px"
			height="65px"
			viewBox="0 0 66 66"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				class="path"
				fill="none"
				stroke-width="6"
				stroke-linecap="round"
				cx="33"
				cy="33"
				r="30"
			></circle>
		</svg>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$offset: 187;
$duration: 1.4s;

.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;right: 0;
    z-index: 999;
    background:rgba(0,0,0,.8);
}
.spinner {
    position: absolute;
    top: 0;right: 0;bottom: 0;left: 0;
    margin:auto;
	animation: rotator $duration linear infinite;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

.path {
	stroke-dasharray: $offset;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash $duration ease-in-out infinite,
		colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
	0% {
		stroke: #4285f4;
	}
	25% {
		stroke: #de3e35;
	}
	50% {
		stroke: #f7c223;
	}
	75% {
		stroke: #1b9a59;
	}
	100% {
		stroke: #4285f4;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
	}
	50% {
		stroke-dashoffset: $offset/4;
		transform: rotate(135deg);
	}
	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg);
	}
}
</style>

<template>
    <div class="gameItemBanner__img">
        <img class="aniTemBg" src="/images/gameItem/HR/HR_bannerbg.png" />
        <img class="aniTemitem9" src="/images/gameItem/HR/HR_banneritem9.png" />
        <img class="aniTemitem7" src="/images/gameItem/HR/HR_banneritem7.png" />
        <img class="aniTemitem5" src="/images/gameItem/HR/HR_banneritem5.png" />
        <img class="aniTemitem8" src="/images/gameItem/HR/HR_banneritem8.png" />
        <img class="aniTemitem6" src="/images/gameItem/HR/HR_banneritem6.png" />
        <img class="aniTemitem4" src="/images/gameItem/HR/HR_banneritem4.png" />
        <img class="aniTemitem3" src="/images/gameItem/HR/HR_banneritem3.png" />
        <img class="aniTemitem2" src="/images/gameItem/HR/HR_banneritem2.png" />
        <img class="aniTemitem1" src="/images/gameItem/HR/HR_banneritem1.png" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(['lang']),
        selectedItem(){
            return this.langList[this.selectedIndex]
        }
    },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
@include keyframesBgAni(1.1);
@include keyframesHeartbeat(1.03);
@include keyframesRotate360(-360deg);
.aniTemBg{
    width: 100%;
    @include bgAni;
}
.aniTemitem1{
    position:absolute;
    width: 20%;
    left: 20%;
    opacity: 0;
    @include keyframesUpDown(15px);
    $animations: (
        chipDown1 1s 0.8s forwards,
        upDown 3s 1.42s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem2{
    position:absolute;
    width: 16%;
    right: 19%;
    opacity: 0;
    @include keyframesUpDown(5px);
    $animations: (
        chipDown2 2s 0.8s forwards,
        upDown 5s 1.2s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem3{
    position:absolute;
    width: 25%;
    top: 15%;
    left: 15.5%;
    opacity: 0;
    $animations: (
        bgPop 0.5s 0.7s forwards,
        rotate360 4s 0.8s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem4{
    position:absolute;
    width: 6%;
    top: 25%;
    right: 35%;
    opacity: 0;
    @include keyframesUpDown(8px);
    $animations: (
        bgPop 0.8s 0.8s forwards,
        upDown 1.8s 1.2s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem5{
    width: 60%;
    position:absolute;
    top: 10%;
    right: 0;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    @include keyframesUpDown(10px);
    $animations: (
        roulette 1.8s 0.8s forwards,
        upDown 3s 1.2s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem6{
    position:absolute;
    width: 15%;
    bottom: 10%;
    right: 0;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    @include keyframesUpDown(15px);
    $animations: (
        bgPop 0.5s 0.8s forwards,
        upDown 2s 1s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem7{
    position:absolute;
    width: 7%;
    bottom: 25%;
    right: 23%;
    opacity: 0;
    @include keyframesUpDown(5px);
    $animations: (
        bgPop 0.3s 0.9s forwards,
        upDown 3s 1.5s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem8{
    position:absolute;
    width: 90%;
    bottom: 20%;
    right: 10%;
    // right: 0;
    // left: 0;
    // margin: 0 auto;
    opacity: 0;
    @include keyframesUpDown(10px);
    $animations: (
        bgPop 0.5s 0.3s forwards,
        upDown 4s 1s infinite linear
    );
    @include multiple_animations($animations);
}
.aniTemitem9{
    position:absolute;
    width: 60%;
    bottom:15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    @include keyframesHeartbeat(1.1);
    $animations: (
        bgPop 0.5s 0.3s forwards,
        heartbeat 3s 0.8s infinite linear
    );
    @include multiple_animations($animations);
}
@keyframes chipDown1{
    0%{
        top:0;
        opacity: 0;
    }
    100%{
        top: 50%;
        opacity: 1;
    }
}
@keyframes chipDown2{
    0%{
        top:0;
        opacity: 0;
    }
    100%{
        top: 28%;
        opacity: 1;
    }
}
@keyframes roulette{
    0%{
        opacity: 0;
        transform:scale(0);
    }
    30%{
        opacity: 1;
        transform:scale(1.1);
    }
    100%{
        transform:scale(1);
        opacity: 1;
    }
}
</style>

<template>
   <div class="gameItemBanner__img">
            <img class="aniTemBg" src="/images/gameItem/BW_bannerbg.png" />
            <img class="aniTemMain" src="/images/gameItem/BW_banneritem.png" />
             <img class="aniTemBg2" src="/images/gameItem/A014_banneritem2.png" />
          </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
       ...mapState(["langConfig"]),
      selectedItem(){
          return this.langList[this.selectedIndex]
      }
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/anicss.scss";
.aniTemBg{
       width: 100%;
         @include bgAni(1s)
    }
.aniTemMain{
       position:absolute;
       top: 250px;
       right: -20px;
       width: 95%;
       @include itemRotate(10s)
}
.aniTemBg2{
       position:absolute;
       bottom: -10%;
       right:5%;
       z-index: 1;
       @include heartbeat(5s)
}
</style>

<template>
    <div>
        <!-- <GameBanner /> -->
        <components :is="setComp" />
        <GameMain :clientWidth="clientWidth"/>
    </div>
</template>

<script>
import GameBanner from "@/components/GameBanner"
import GameBannerM from "@/components/GameBannerM"
import GameMain from "@/components/GameMain"
export default {
    components:{
        GameBanner,
        GameBannerM,
        GameMain
    },
    computed: {
        setComp(){
            return this.isMobile ?'GameBannerM':'GameBanner'
        }
    },
    data(){
        return{
            isMobile:true,
            clientWidth:''
        }
    },
    mounted(){
        if(window.innerWidth > 760){
			this.isMobile = false
        }
        this.clientWidth=document.body.clientWidth
        window.onresize=()=>{
            this.clientWidth=document.body.clientWidth
        }
    }
}
</script>

<style lang="scss" scoped>

</style>